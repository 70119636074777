
















































































































































































































































































































































































































































































































































































































































































































































































































































.text-confirm-1 {
    font-size: 1.4em;
    color: #fff;
    font-family: 'HHBI';
    text-align: center;
    margin-top: 10px;
}
.text-confirm-2 {
    font-size: 1.2em;
    color: darkorange;
    font-family: 'HHBI';
    text-align: center;
    margin-top: 10px;
}
.history-tr {
    min-height: 100px;
    background: linear-gradient(180deg, #00172a 0%, #00030f 100%);
    width: 100%;
    .table-responsive {
        @media (max-width: 767px) {
            max-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .history-tr-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        position: relative;
        &::after {
            content: '';
            width: calc(100% - 300px);
            height: 1px;
            background: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
        }
        &-item {
            color: #fff;
            height: 40px;
            display: flex;
            font-weight: 600;
            box-shadow: none;
            border-radius: 0;
            margin: 0;
            border: 0;
            border-bottom: 1px solid #fff;
            width: 150px;
            text-align: center;
            justify-content: center;
            font-size: 15px;
            padding: 0;
            letter-spacing: 1px;
            font-family: 'HHBI';
            align-items: center;
            &.active {
                color: #00ffff;
                background: rgba(255, 255, 255, 0.1);
                border: 1px solid #fff;
                border-bottom: 0;
            }
        }
    }
    .cancel-ordered {
        font-size: 11px;
    }
    table {
        color: #fff;
        tr {
            th {
                background-color: rgba(255, 255, 255, 0.08) !important;
                border: 1px solid rgba(255, 255, 255, 0.521);
                border-left: 0;
                border-right: 0;
            }
        }
    }
    .group-item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.19);
        padding: 5px;
    }
    .table-hover tbody tr:hover {
        color: #0ff;
        background-color: rgba(255, 255, 255, 0.08) !important;
    }
    .table-paginate {
        margin: 15px auto;
        .paginate {
            justify-content: center;
        }
    }
}
