








































































































.paginate-2 {
    .paginate {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        position: relative;
        z-index: 20;
        margin: 0 -10px 0 -10px;
        .paginate {
            border-radius: 5px;
            width: 44px;
            height: 44px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            background: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            font-family: 'Poppins';
            margin: 5px;
            &:disabled {
                opacity: 0.3;
            }

            &.next {
                color: #2a2e4a;
                width: unset;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                box-shadow: none;
                color: #fff;
                img {
                    margin-left: 10px;
                }
            }
            &.prev {
                color: #2a2e4a;
                width: unset;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                box-shadow: none;
                color: #fff;
                img {
                    margin-right: 10px;
                }
            }
            &.active {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                line-height: 20px;
                color: #00ffff;
                transition: 0.1s;
                background: linear-gradient(-175deg, #18d4e2 10%, #a506f9 100%);
                padding: 2px;
                .content-paginate {
                    background: #2a2e4a;
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            @media (max-width:575px){
              width: 25px;
              height: 25px;
              font-size: 14px;
              margin-right: 2px;
            }
        }
    }
    .info-paginate {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        color: #888888;
        span {
            color: #00ffff;
        }
    }
    @media (max-width: 991px) {
        .info-paginate {
            text-align: center;
            margin-bottom: 10px;
        }
        .paginate {
            justify-content: center;
        }
    }
}
