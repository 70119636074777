



























































































































































































































































































































































































































































































.page-trade {
    @media (max-width: 991px) {
        width: 100%;
        max-width: calc(100% - 20px);
        padding: 0;
        padding-bottom: 50px;
    }
}
.box-1 {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .stat-tr {
        padding: 5px;
        // height: 120px;
        flex: 1;
        margin: 5px;
        background: linear-gradient(180deg, #00172a75 0%, #00030f7a 100%);
        border: 1px solid #00ffff;
        border-radius: calc(50px * 2 / 3);
        &.select-1 {
            border-radius: 0;
            background: none;
            border: 0;
        }
        select {
            width: 100%;
            background: linear-gradient(180deg, #00172a75 0%, #00030f7a 100%);
            border-radius: calc(50px * 2 / 3);
            color: #fff;
            font-family: 'HHBI';
            font-size: 1.2em;
            max-height: 45px;
            text-align: center;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
                text-align: left;
                &:checked {
                    display: none;
                    text-align: center;
                }
            }
        }
        .value {
            text-align: center;
            color: #00ffff;
            font-family: 'HHBI';
            font-size: 1em;
            line-height: 120%;
            &.sell {
                color: #df0101 !important;
            }
            &.buy {
                color: #68f368 !important;
            }
        }
        .text {
            text-align: center;
            color: #fff;
            font-family: 'Helvetica';
            font-weight: 600;
            font-size: 0.62em;
            letter-spacing: 1px;
        }
        @media (max-width: 991px) {
            flex: 1 1 auto;
        }
        @media (max-width: 767px) {
            flex: 0 0 calc(50% - 20px);
        }
        @media (max-width: 767px) {
            flex: 0 0 calc(50% - 20px);
        }
        @media (max-width: 480px) {
            flex: 0 0 33%;
            margin: 0;
            justify-content: center;
            border: none;
            border-radius: 0;
            .value {
                text-align: center;
                color: #00ffff;
                font-family: 'HHBI';
                font-size: 13px;
                line-height: 120%;
            }
            .text {
                text-align: center;
                color: #fff;
                font-family: 'Helvetica';
                font-weight: 600;
                font-size: 8px;
                letter-spacing: 1px;
            }
            &.select-1 {
                flex: 0 0 100%;
                max-width: 300px;
            }
            select {
                font-size: 1em;
                max-height: 30px;
                padding: 0;
                margin-bottom: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.box-chart {
    min-height: 500px;
    display: flex;
    justify-content: center;
    .left-tr {
        flex: 1 1 auto;
        margin-right: 15px;

        .chart-cn {
            width: 100%;
            height: calc(370px - 15px);
            margin-bottom: 15px;
            background: linear-gradient(180deg, #00172a75 0%, #00030f2e 100%);
            display: flex;
            align-items: center;
            .chart {
                height: calc(370px - 15px - 50px);
            }
            @media (max-width: 991px) {
                height: calc(350px - 15px);
                .chart {
                    height: calc(350px - 15px - 50px);
                }
            }
            @media (max-width: 480px) {
                height: calc(40vh - 15px);
                .chart {
                    height: calc(40vh - 15px - 50px);
                }
            }
        }
    }
    .right-tr {
        flex: 0 0 325px;
        .list-header {
            // border: 1px solid #00ffff;
            width: 100%;
            // max-width: 300px;
            height: 35px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            &-item {
                padding: 0 10px;
                color: #fff;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                box-shadow: none;
                border: 1px solid #fff;
                margin: 0 5px;
                &.active {
                    color: #00ffff;
                    border: 1px solid #00ffff;
                }
            }
        }
        .list-buying,
        .list-selling {
            min-height: 325px;
            max-height: 325px;
            overflow: hidden;
            background: linear-gradient(180deg, #00172a75 0%, #00030f2e 100%);
            margin-bottom: 15px;
            table {
                tr,
                td,
                th {
                    border: 0 !important;
                }
                td {
                    padding: 5px 10px !important;
                }
                tr {
                    th {
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00030f 100%
                        );
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.22) !important;
                        cursor: pointer;
                    }
                }
            }
            .b-table-sticky-header {
                margin-bottom: 0;
                max-height: 325px;
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 10px;
                    // background-color: #f5f5f5;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: rgb(201, 199, 199);
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 1px;
                    background-color: rgba(0, 0, 0, 0.4);
                }
            }
        }
        .list-buying {
            table {
                tr,
                td,
                th {
                    color: #68f368 !important;
                }
            }
        }
        .list-selling {
            table {
                tr,
                td,
                th {
                    color: #df0101 !important;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .right-tr {
            flex: 0 0 250px;
        }
    }
    @media (max-width: 767px) {
        flex-wrap: wrap;
        .left-tr {
            flex: 0 0 100%;
        }
        .right-tr {
            flex: 0 0 100%;
            .list-buy-sel {
                display: flex;
                justify-content: space-between;
                .list-buying,
                .list-selling {
                    flex: 1;
                    margin: 10px;
                    max-height: 150px;
                    min-height: 150px;
                    .b-table-sticky-header {
                        max-height: 150px;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        min-height: unset;
        .right-tr {
            flex: 0 0 100%;
            display: flex;
            flex-direction: column-reverse;
            .list-buy-sel {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .list-buying,
                .list-selling {
                    max-height: 220px;
                    min-height: 220px;
                    flex: unset;
                    width: 100%;
                    margin: 10px auto;
                    .b-table-sticky-header {
                        max-height: 220px;
                    }
                }
            }
        }
    }
    @media (max-width: 480px) {
        .right-tr {
            flex: 0 0 100%;
            display: flex;
            flex-direction: column-reverse;
            .list-buy-sel {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .list-buying,
                .list-selling {
                    flex: 1;
                    margin: 10px;
                    max-height: 250px;
                    min-height: 250px;
                    .b-table-sticky-header {
                        max-height: 250px;
                    }
                }
            }
        }
    }
}
.gr-show {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 80px;
    width: 100%;
    .button-trade {
        margin: 10px auto;
        width: 110px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        outline: none !important;
        font-style: italic;
        letter-spacing: 1px;
        font-size: 1.3em;
        font-weight: 600;
        font-family: 'HHBI';
        box-shadow: none;
        filter: drop-shadow(2px 2px 1px rgba($color: #000000, $alpha: 0.7));
        transition: 500ms;
        &:focus,
        &:hover {
            transform: scale(0.9);
        }
        &.sell {
            background: url('~@/assets/images/mockup/account/bgBtn-red.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
        }
        &.buy {
            background: url('~@/assets/images/mockup/account/bgBtn-green.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}
.popup-trade {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 60px;
    width: 100%;
    height: auto;
    min-height: 420px;
    max-height: 450px;
    background: linear-gradient(180deg, #00172a 0%, #00030f 100%);
    z-index: 900;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px -10px 10px 15px rgba(0, 0, 0, 0.6);
    .popup-trade-header {
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            content: '';
            width: calc(100% - 200px);
            height: 1px;
            background: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .popup-header-menu {
            display: flex;
            justify-content: flex-start;

            &-item {
                border-bottom: 1px solid #fff;
                flex: 1;
                height: 45px;
                width: 100px;
                display: flex;
                color: #fff;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                &.active {
                    background: rgba(255, 255, 255, 0.1);

                    border: 1px solid #fff;
                    border-bottom: 0;
                }
            }
        }
        .popup-header-close {
            width: 45px;
            height: 45px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: 'HHBI';
            color: red;
            background: rgba(255, 255, 255, 0.1);
        }
    }
    .history-tr {
        background: transparent;
    }
}
