































































































































































































































































































































































































































#boxReceiverNew {
    .box {
        max-width: 300px;
        width: 100%;
        min-height: 350px;
        margin: 5px auto;
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 210px;
                object-fit: scale-down;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            color: #fff;
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
        .box-open {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 7px;
            width: 120px;
            height: 40px;
            padding: 5px;
            button {
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                border: 1.5px solid #fff;
                padding: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                box-shadow: 0px 0px 5px #00ffff;
                text-transform: uppercase;
            }
        }

        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 210px;
        }
    }
}
.summon-new-page {
    .summon__title {
        width: 100%;
        max-width: 475px;
        margin: 0 auto 30px;
        &-text {
            font-family: 'HHBI';
            font-size: 42px;
            color: #00ffff;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            height: fit-content;
        }
        img {
            width: 100%;
        }
        @media (max-width: 480px) {
            margin: 0 auto 20px;
            &-text {
                font-size: 30px;
            }
        }
    }
    .id-hero {
        color: #f8f8f8 !important;
        font-size: 1em;
        font-weight: 800;
        margin-bottom: 10px;
        font-family: 'Poppins';
        line-height: 1.2em;
        text-align: center;
    }
    .header-page {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(105% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }

    .btn-new-summon {
        background-image: url(~@/assets/images/mockup/summon/Summon.png);
        background-size: 100% 100%;
        padding: 5px 20px;

        font-family: 'helvetica-neue-regular';
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 0px 10px #00ff60;

        cursor: pointer;
        transition: 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }

    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        max-width: 600px;
        border-bottom: 1px solid #f8f8f8;
        .search-fr {
            background: transparent !important;
        }
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: none;
            background: transparent;
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
        }
        button {
            background: none;
            border-radius: 0;
            height: 40px;
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            border: none;
            justify-content: center;
            text-transform: capitalize;
            &.btn-danger {
                background: #ff0000;
                color: #fff;
            }
        }
    }

    .summon-navbar {
        background: #00ffff2c;
        align-items: center;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        transform: skewX(-15deg);
        &-item {
            align-items: center;
            padding: 5px 15px;
            height: 100%;
            min-height: 35px;
            display: flex;
            justify-content: center;
            min-width: 100px;
            font-family: 'HHBI';
            letter-spacing: 1px;
            font-size: 1.5em;
            font-weight: 900;
            color: #fff;
            line-height: 0;
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;
            cursor: pointer;
            &.active,
            &:hover {
                color: #00ffff;
                // background-color: rgba(0, 33, 51, 0.6);
            }
        }
        @media (max-width: 575px) {
            &-item {
                min-width: unset;
                font-size: 18px;
            }
        }
        .btn-toogle {
            width: 35px;
            height: 35px;
        }
    }
    .item-fr {
        position: relative;
        .arrow-left {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 68px;
            z-index: 1;
            @media (max-width: 991px) {
                width: 48px;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .arrow-right {
            z-index: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: 0;
            width: 68px;

            @media (max-width: 991px) {
                width: 48px;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .summon-item {
        background: linear-gradient(
            180deg,
            rgba(37, 63, 78, 0.5) 0%,
            rgba(39, 51, 58, 0.22) 100%
        );
        backdrop-filter: blur(2px);

        .img-hero {
            max-width: 115px;
            width: 100%;
            margin: 0 auto;
            img {
                width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
            }
        }

        .rare-and-box {
            line-height: 35px;
            font-family: 'Poppins';
            font-size: 12px;
            color: #00ffff;

            span {
                display: inline-block;
                margin-left: 3px;
                color: #f8f8f8;
                margin: 0;
                text-align: center;
                line-height: 20px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 9px;
            }
        }

        .frame-process {
            border: 2px solid #015160;
            background-color: #fffff788;
            .process {
                width: auto;
                height: 100%;
                background: #00ffff;

                left: 0;
                top: 0;
                bottom: 0;
                &.done {
                    width: 100%;
                }
            }
            p {
                position: relative;
                z-index: 1;
                font-weight: bold;
                color: #01171d;
            }
        }

        .btn-claim-box {
            background-size: 100% 100%;
            cursor: pointer;
            max-width: 163px;
            width: 100%;
            height: 45px;

            color: #00ffff;
            font-family: 'Poppins';
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: 1px solid #00ffff;
        }
    }

    .modify-row {
        // margin-left: -10px;
        // margin-right: -10px;
        .modify-row {
            @media (min-width: 1200px) and (max-width: 1400px) {
                flex-direction: column;
                .col {
                    width: 100%;
                    max-width: 200px;
                    flex: 0 0 100%;
                    margin: 0px auto;
                }
            }
        }
        .col {
            // padding-left: 10px;
            // padding-right: 10px;
            padding: 5px;
        }
    }
}
.summon-button {
    $bg-main: #00ffff;
    $color: #000000;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    margin: 0 0 0 auto;
    &.green {
        $bg-main: #00ffff;
        $color: #000;
        -webkit-animation-name: hvr-wobble-top;
        animation-name: hvr-wobble-top;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    &-content {
        position: relative;
        z-index: 10;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* or 144% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.75px;
        // color: #FE3F61;
        justify-content: center;
        img {
            filter: invert(35%) sepia(131%) saturate(624%) hue-rotate(147deg)
                brightness(100%) contrast(106%);
        }
    }
    border: 1px solid $bg-main;
    background: transparent;
    box-shadow: unset;
    position: relative;
    width: 200px;
    height: 47px;
    border-radius: 0;
    color: $bg-main;
    &:after,
    &::before {
        z-index: 0;
        content: '';
        // width: 5px;
        // height: 5px;
        background: $bg-main;
    }

    &:after {
        position: absolute;
        top: 1px;
        left: 1px;
    }
    &::before {
        position: absolute;
        bottom: 1px;
        right: 1px;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover,
    &:focus,
    &:active {
        color: $color;

        &::before {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transition-timing-function: cubic-bezier(
                0.52,
                1.64,
                0.37,
                0.66
            );
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        }

        img {
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(15deg)
                brightness(22%) contrast(102%);
        }
    }
    @media (max-width: 575px) {
        width: 275px;
        height: 50px;
        font-size: 25px;
    }
}
@-webkit-keyframes hvr-buzz {
    50% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    100% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
}
@keyframes hvr-wobble-top {
    16.65% {
        -webkit-transform: skew(-12deg);
        transform: skew(-12deg);
    }
    33.3% {
        -webkit-transform: skew(10deg);
        transform: skew(10deg);
    }
    49.95% {
        -webkit-transform: skew(-6deg);
        transform: skew(-6deg);
    }
    66.6% {
        -webkit-transform: skew(4deg);
        transform: skew(4deg);
    }
    83.25% {
        -webkit-transform: skew(-2deg);
        transform: skew(-2deg);
    }
    100% {
        -webkit-transform: skew(0);
        transform: skew(0);
    }
}
