.modal-content {
    border: 1px solid transparent;
    border-radius: 15px;

    .modal-header {
        background-color: transparent;
        padding: 0 10px;
        color: #000;
        font-family: 'HHBI';
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        border: 0;
        border-bottom: 1px solid #fcfcfc;

        .modal-title {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 1.7em;
        }

        .close {
            color: #000;
            opacity: 1;
            font-size: 1.8rem;
            font-weight: 900;
            font-family: fantasy;
            outline: none !important;
        }
    }

    .modal-body {
        background: #212930;
        border-radius: 10px;
    }

    .button-confirm {
        background: rgba(0, 255, 255, 0.2);
        border-radius: 8px;
        border: none;
        outline: 0;
        width: auto;
        padding: 5px 15px;
        height: 45px;
        color: #f8f8f8;
        font-weight: 600;
        text-transform: capitalize;
        min-width: 140px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px auto 10px;

        &:active,
        &:focus {
            background: rgba(0, 255, 255, 0.2);
            border: none;
            outline: 0;
        }
    }

    .button-cancel {
        background: linear-gradient(90deg, #762525 0%, #f92323 100%);
        border-radius: 0;
        width: auto;
        padding: 5px 15px;
        height: 45px;
        color: #fff;
        font-weight: 600;
        text-transform: capitalize;
        min-width: 140px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px auto 10px;
        border: none;
        border-radius: 8px;
    }

    input {
        color: #fff;
        font-weight: 600;
        letter-spacing: 1px;

        &::-webkit-input-placeholder {
            color: #fff;
            opacity: 0.6;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 1px;
        }

        height: 40px;
        border: none;
        // border-bottom: 1px solid #F0F0F0;
        background: transparent;
        border-radius: 0;
        margin: 15px auto;
        width: 75%;
        min-width: 200px;

        &:focus,
        &:focus-within {
            color: #fff !important;
            background: rgba(0, 255, 255, 0.15);
            outline: none;
            border: none;
            box-shadow: unset;
        }
    }

    .group-slip {
        .input-group-prepend {
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
        }

        button {
            .button-content {
                position: relative;
                z-index: 10;
                font-family: 'HHBI';
                font-weight: 400;
                font-size: 15px;
                line-height: 26px;
                /* or 144% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.75px;
                justify-content: center;

                img {
                    filter: invert(35%) sepia(131%) saturate(624%)
                        hue-rotate(147deg) brightness(100%) contrast(106%);
                }
            }

            border: 1px solid #00ffff;
            background: transparent;
            box-shadow: unset;
            position: relative;
            flex: 1 1 auto;
            width: auto;
            height: 40px;
            margin: 5px 5px 2px;
            border-radius: 0;
            color: #00ffff;

            &:after,
            &::before {
                z-index: 0;
                content: '';
                width: 5px;
                height: 5px;
                background: #00ffff;
            }

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
            }

            &::before {
                position: absolute;
                bottom: 1px;
                right: 1px;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }

            &:hover,
            &:focus,
            &:active,
            &.active {
                color: #000 !important;

                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                    transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                }
            }
        }
    }
}

.modal-dialog {
    top: 90px;
    background: none;
    @media (max-height: 800px) {
        top: 0;
    }

    .modal-content {
        background: none;
        .modal-header {
            background: #212930;
            color: #fff;
            border-radius: 15px 15px 0 0;
            border-bottom: 1px solid #666;
            padding: 10px 20px 12px;
            .close {
                color: #fff;
            }
        }
        .modal-body {
            border-radius: 0 0 15px 15px;
        }
    }
}

.modal-backdrop {
    background-color: rgba($color: #000000, $alpha: 0.7);
}

.text-confirm-ticket {
    text-align: left;
    font-size: 1.3em;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;

    span {
        color: #02ffff;
    }

    @media (max-width: 991px) {
        font-size: 1.1em !important;
    }
}

.modal-open .modal {
    @media (max-height: 800px) {
        max-height: 85vh;
    }
}

.market-modal {
    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        padding-bottom: 10px;
        border-bottom: 0.5px solid #fcfcfc80;

        &\:text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            text-align: left;
            color: #00ffff;
            flex: 1 1 auto;
        }

        &\:close {
            color: #fcfcfc;
            display: flex;
            justify-content: center;
            flex: 0 0 20px;
            align-items: center;
            height: 20px;
            width: 20px;
            cursor: pointer;
            border-radius: 50%;

            &:hover {
                color: #fe3f61;
                text-decoration: none;
            }
        }
    }

    &-body {
        margin: 15px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &\:image {
            flex: 0 0 175px;
            margin-right: 15px;

            img {
                max-width: 175px;
                object-fit: scale-down;
            }
        }

        &-content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            color: #fff;
            position: relative;
            padding-left: 15px;

            .detail-item-market {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .name {
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 10px;
                }

                .value {
                    font-size: 16px;
                    font-weight: 600;
                    color: #00ffff;
                }
            }
        }
    }

    &-footer {
        display: flex;
        justify-content: space-around;
        align-items: center;

        &\:cancel {
            box-shadow: none;
            outline: none;
            border: none;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            background: rgba(53, 63, 72, 0.2);
            border: 0.5px solid #fff7f7;
            width: 120px;
            height: 35px;
            margin: 10px;
            border-radius: 15px;

            img {
                margin: 0 10px;
            }

            .button-content {
                flex: 1 1 auto;
                background: transparent;
                color: #fff7f7;
                border-radius: 15px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.1s;
                letter-spacing: 1px;
                font-weight: 500;
                border-radius: 25px;
                font-size: 16px;
            }

            &:hover,
            &:active,
            &:focus {
                .button-content {
                    // background: #182e33;
                    color: #fe3f61;
                    font-weight: 600;
                }

                img {
                    filter: invert(36%) sepia(45%) saturate(4225%)
                        hue-rotate(329deg) brightness(106%) contrast(99%);
                }
            }
        }

        &\:confirm {
            box-shadow: none;
            outline: none;
            border: none;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            background: linear-gradient(-175deg, #18d4e2 10%, #a506f9 100%);
            width: 120px;
            height: 35px;
            margin: 10px;
            border-radius: 15px;

            img {
                margin: 0 10px;
            }

            .button-content {
                flex: 1 1 auto;
                background: #1d2025;
                border-radius: 15px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                line-height: 20px;
                color: #00ffff;
                transition: 1s;

                &.cancel {
                    border-radius: 25px;
                    font-size: 14px;
                }
            }

            &:hover,
            &:active,
            &:focus {
                box-shadow: 0px 0px 10px -5px #a506f9, 0px 0px 10px -5px #a506f9,
                    0px 0px 10px -5px #18d4e2, 0px 0px 10px -5px #18d4e2,
                    0px 0px 10px -5px #a506f9, 0px 0px 10px -5px #18d4e2;
            }
        }
    }
}
