










































































































































































































































































































































































































.form-trade {
    .trade {
        width: 100%;
        min-height: 300px;
        padding: 15px;
        &-header {
            // border: 1px solid #00ffff;
            width: 100%;
            // max-width: 300px;
            height: 40px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            &-item {
                color: #fff;
                height: 40px;
                display: flex;
                font-weight: 600;
                box-shadow: none;
                border: 1px solid #fff;
                margin: 0 5px;
                &.active {
                    color: #00ffff;
                    border: 1px solid #00ffff;
                }
            }
        }
        &-body {
            height: calc(100% - 55px);
            margin-top: 25px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            &-sell,
            &-buy {
                flex: 0 0 calc(50% - 10px);
                height: 100%;
                padding: 0 15px;
                .form-trade {
                    margin-bottom: 15px;
                    .label-balance {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #fff;
                        font-family: 'HHBI';
                        .label-balance-left {
                        }
                        .label-balance-right {
                        }
                    }
                    .input-group {
                        border: 1px solid #00ffff;
                        border-radius: 5px;
                    }
                    input {
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00000b 100%
                        );
                        color: #fff;
                        border: 0;
                        font-family: 'HHBI';
                        height: 40px;
                        font-size: 19px;
                        &::placeholder {
                            color: #fff;
                            opacity: 0.7;
                        }
                    }
                    input[type='number'] {
                        -moz-appearance: textfield;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        /* display: none; <- Crashes Chrome on hover */
                        -webkit-appearance: none;
                        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                    }
                    .input-group-text {
                        border: 0;
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00000b 100%
                        );
                        color: #fff;
                        font-family: 'HHBI';
                    }
                }
                .suggest-trade {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .suggest-trade-box {
                        flex: 1;
                        margin: 0 5px;
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00000b 100%
                        );
                        border: 1px solid #00ffff;
                        font-family: 'HHBI';
                        color: #fff;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .button-trade {
                    margin: 10px auto;
                    width: 175px;
                    height: 50px;
                    color: #fff;
                    outline: none !important;
                    font-style: italic;
                    letter-spacing: 1px;
                    font-size: 1.3em;
                    font-weight: 600;
                    font-family: 'HHBI';
                    box-shadow: none;
                    filter: drop-shadow(
                        2px 2px 1px rgba($color: #000000, $alpha: 0.7)
                    );
                    transition: 500ms;
                    &:focus,
                    &:hover {
                        transform: scale(0.9);
                    }
                    &.sell {
                        background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &.buy {
                        background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
            &-sell {
            }
            &-buy {
            }
            @media (max-width: 991px) {
                flex-wrap: wrap;
                &-sell,
                &-buy {
                    flex: unset;
                    width: 100%;
                }
            }
        }
    }
}
