





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































a.market-user-nav-item {
    text-decoration: none;
}
.market-user-result {
    &-type {
        margin: 40px 0 30px 75px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #fcfcfc;
        span {
            color: #888888;
        }
    }
    &-list {
        table {
            border-collapse: separate;
            border-spacing: 0 5px;
            thead {
                background: rgba(53, 63, 72, 0.2);
                border-radius: 2px;

                th {
                    border: 0;
                    color: #fff;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
            tbody {
                tr {
                    border: 0;
                    border-radius: 2px;
                    background: rgba(53, 63, 72, 0.2);
                    &:nth-child(odd) {
                        background: rgba(53, 63, 72, 0.4);
                    }
                    td {
                        border: 0;
                        color: #fff;
                        vertical-align: middle;
                        .hero-info {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            &.left {
                                justify-content: flex-start;
                            }
                            &-avatar {
                                width: 48px;
                                height: 48px;
                                background: linear-gradient(
                                        0deg,
                                        rgba(0, 0, 0, 0.2),
                                        rgba(0, 0, 0, 0.2)
                                    ),
                                    #353f48;
                                /* GrayScale/Label */

                                border: 0.5px solid #555555;
                                border-radius: 8px;
                                padding: 5px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: scale-down;
                                }
                                margin-right: 10px;
                            }
                            &-text {
                                text-align: left;
                                &-name {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-family: 'HHBI';
                                    color: #fcfcfc;
                                }
                                &-platform {
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #888888;
                                }
                            }
                            &-text2 {
                                text-align: right;
                                &-name {
                                    font-weight: 400;
                                    font-size: 17px;
                                    line-height: 20px;
                                    font-family: 'HHBI';
                                    color: #fcfcfc;
                                }

                                &-platform {
                                    font-weight: 500;
                                    font-size: 15px;
                                    line-height: 20px;
                                    font-family: 'HHBI';
                                    color: #fcfcfcb7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.market-private {
    .market-user {
        @media (max-width: 575px) {
            flex-wrap: wrap;
            .market-user-nav {
                width: 100%;
                order: 1;
                &-item {
                    max-width: unset;
                }
            }
            .sell-item-button {
                width: 100%;
                order: 0;
                margin-bottom: 10px;
            }
        }
    }
    .btn-open-detail {
        padding: 0;
        box-shadow: none !important;
        outline: none !important;
        border: 0 !important;
        height: 25px;
        width: 25px;
        background: #00172a;
        border-radius: 50%;
        margin: auto 5px !important;
        img {
            width: 15px;
            height: 15px;
        }
        &.open {
            width: 25px;
            height: 25px;
            img {
                width: 25px;
                height: 25px;
            }
        }
        @media (min-width: 576px) {
            display: none;
        }
    }
}
a.sell-item-button {
    text-decoration: none;
}
