










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.inventory-item-image {
    img {
        height: 123px;
        width: auto;
    }
}
