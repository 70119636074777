


























































































#inventoryAccount {
    // max-width: 1400px;
    font-family: 'Poppins';
    .inventory-header {
        .inventory-header-tabs-list {
            background-image: url('~@/assets/images/background/clone-bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            height: 66px;
            .inventory-header-tab {
                width: 25%;
                padding: 0;
                box-shadow: unset;
                outline: none;
                p {
                    width: 100%;
                    margin: 0;
                    border-right: 1px solid #00ffff;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    color: #f8f8f8;
                }
            }
            .isActiveTab {
                background: rgba(0, 255, 255, 0.15);
                backdrop-filter: blur(2px);
            }
            .inventory-header-tab:last-child p {
                border: none;
            }
        }
        .inventory-header-tabs-btn {
            display: flex;
            align-items: center;
            height: 66px;
            button {
                background: none;
                border-radius: 50%;
                border: none;
                outline: none;
                box-shadow: unset;
                width: 44px;
                height: 44px;
                margin-left: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
