@import "./modules/font.scss";
@import "./modules/theme.scss";
@import "./modules/modal.scss";
@import "./modules/toastr.scss";
@import "./modules/starsLight";

.min-100 {
    min-width: 100px!important;
}
.min-300 {
    min-width: 300px!important;
}