
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































a {
    text-decoration: none;
}
.upgrade-header {
    font-family: 'Poppins';
    border: 1px solid rgba(0, 255, 255, 0.3);
    border-radius: 4px;
    padding: 47px 70px;
    @media (max-width: 618px) {
        padding: 30px 20px;
        .upgrage-header-content {
            padding-left: 20px !important;
            .col-12 {
                padding-right: 1rem;
            }
        }
    }
    @media (max-width: 480px) {
        .upgrage-header-content {
            padding: 0 !important;
        }
    }
    .upgrage-header-content {
        padding-left: 40px;
        .upgrade-title {
            font-weight: 600;
            font-size: 24px;
            color: #00ffff;
            padding-left: 40px;
            position: relative;
            @media (min-width: 576px) {
                &::before {
                    content: '';
                    position: absolute;
                    top: 40%;
                    left: 15px;
                    width: 6px;
                    height: 6px;
                    background: #00ffff;
                    border-radius: 50%;
                }
            }
            @media (max-width: 575px) {
                width: 100%;
                padding: 0 1rem;
                text-align: center;
            }
        }
        .upgrade-coin {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #f8f8f8;
            padding-left: 36px;
            @media (max-width: 575px) {
                width: 100%;
                padding: 0 1rem;
                text-align: center;
            }
        }
        .upgrade-header-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: rgba(0, 255, 255, 0.2);
            height: 38px;
            border-radius: 54px;
            outline: unset !important;
            span {
                padding-left: 16px;
                font-weight: 500;
                font-size: 12px;
                color: #00ffff;
            }
        }
        .upgrade-header-btn2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: rgba(85, 85, 85, 0.2);
            height: 38px;
            border-radius: 54px;
            outline: unset !important;
            span {
                padding-left: 16px;
                font-weight: 500;
                font-size: 12px;
                color: #dcdcdc;
            }
        }
    }
}
