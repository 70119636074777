

























































































































































































































#SystemView {
    .nav-item {
        @media (max-width: 991px) {
            margin-bottom: 0 !important;
        }
    }
    .main-mb {
        .label-fr {
            display: flex;
            align-items: center;
            margin: 0.75rem 0;
            @media (max-width: 768px) {
                position: absolute;
                bottom: 2%;
                left: 2%;
                transform: translate(-2%, -2%);
            }
        }
        .bg-mb {
            width: 100%;
            height: 155px;
            margin: 1rem auto;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            .heart-icon-mb {
                width: 37px;
                height: 37px;
                position: absolute;
                bottom: 2%;
                right: 2%;
                transform: translate(-2%, -2%);
                img {
                    object-fit: scale-down;
                    width: 100%;
                    height: 100%;
                }
            }
            .logo-img-mb {
                width: 75px;
                height: 35px;
                position: absolute;
                top: 5%;
                left: 2%;
                transform: translate(-2%, -5%);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
        }
    }
    .tab-pane {
        width: 95%;
        margin: 0 auto;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .tab-content {
        position: relative;
        padding: 0 12px;
        @media (max-width: 991px) {
            padding: 0;
            margin-top: 0;
        }
        .heart-icon {
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            border: 1px solid #dcdcdc;
            img {
                object-fit: scale-down;
            }
            @media (max-width: 768px) {
                width: 37px;
                height: 37px;
            }
        }
        h1 {
            font-weight: 600;
            font-size: 40px;
            line-height: 170%;
            @media (max-width: 991px) {
                font-size: 32px;
            }
            @media (max-width: 768px) {
                font-size: 24px;
            }
            @media (max-width: 575px) {
                font-size: 20px;
            }
        }
        .des {
            width: 50%;
            @media (max-width: 991px) {
                width: 100%;
            }
        }
        .available,
        .ver {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 0;
            color: #dcdcdc;
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 768px) {
                font-size: 10px;
            }
            @media (max-width: 575px) {
                font-size: 8px;
            }
        }

        .available {
            padding: 4px 10px;
            border: 0.5px solid #dcdcdc;
            border-radius: 4px;
        }
        .tab-pane {
            position: relative;
            height: 100%;
            padding: 14px;
            @media (max-width: 991px) {
                padding-top: 0;
            }
        }
        .main-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: rgb(0, 255, 255, 0.5) 0px 2px 8px 0px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: right 0;
            }
        }
    }
    button {
        width: 200px;
        height: 50px;
        border: none;
        outline: none;
        position: relative;
        background: linear-gradient(to right, #18d4e2, #a506f9);
        cursor: pointer;
        @media (max-width: 768px) {
            width: 150px;
            height: 45px;
            p {
                font-size: clamp(14px, 1.5vw, 16px);
            }
        }
        @media (max-width: 575px) {
            max-width: 140px;
            width: 100%;
            height: 35px;
        }
        &:hover {
            background-size: 200%;
            animation: loop 1s infinite linear;
        }
        &::before {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
        }
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: clamp(14px, 1.5vw, 16px);
            line-height: 150%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #00ffff;
            z-index: 10;
        }
    }
    .card {
        background: transparent;
        border: none;
    }
    .main-tabs {
        margin-top: 3rem;
        flex-direction: row-reverse;
        @media (max-width: 991px) {
            flex-direction: column;
            margin-top: 3rem;
        }
        @media (max-width: 575px) {
            margin-top: 1.25rem;
        }
        .tab-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            img {
                width: 100%;
                height: 100%;
                @media (max-width: 991px) {
                    object-fit: cover;
                }
            }
        }

        .logo-img {
            width: 99px;
            height: 55px;
            position: relative;
            z-index: 5;
            margin-top: 2rem;
            @media (max-width: 1200px) {
                margin-top: 0;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                // width: 100%;
                height: 100%;
                object-position: center;
            }
        }
        .nav-link {
            width: 270px;
            height: 98px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0;
            @media (max-width: 1200px) {
                width: 225px;
            }
            @media (max-width: 991px) {
                margin-top: 10px;
                width: 185px;
                margin-bottom: 10px;
            }
            @media (max-width: 768px) {
                width: 145px;
                height: 78px;
            }
            @media (max-width: 575px) {
                width: 125px;
                height: 68px;
            }
            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: clamp(15px, 1.5vw, 16px);
                line-height: 150%;
                margin-bottom: 0;
                color: #f0f0f0;
                z-index: 5;
                text-transform: capitalize;
            }
        }
        .nav-item {
            padding: 5px 0;
            @media (max-width: 991px) {
                padding: 0 5px;
            }
        }
        .tab-img {
            z-index: 10;
            width: 50px;
            height: 50px;
            position: relative;
            @media (max-width: 768px) {
                width: 35px;
                height: 35px;
            }
            img {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                object-position: center;
            }
        }
        .svg-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            z-index: 10;
            // display: none;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .shape {
            stroke-dasharray: 140 540;
            stroke-dashoffset: -474;
            stroke-width: 0px;
            fill: transparent;
            stroke: #19f6e8;
            border-bottom: 5px solid black;
            transition: stroke-width 1.25s, stroke-dashoffset 1.25s,
                stroke-dasharray 1.25s;
            width: 100%;
            height: 98px;
            @media (max-width: 1200px) {
                width: 225px;
            }
            @media (max-width: 991px) {
                width: 185px;
            }
            @media (max-width: 768px) {
                width: 145px;
                height: 78px;
            }
            @media (max-width: 575px) {
                width: 125px;
                height: 68px;
            }
        }

        .background-card {
            background-color: rgb(26, 30, 36);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.35;
            z-index: 3;
        }

        .nav-pills {
            padding: 0 10px;
            @media (max-width: 991px) {
                flex-direction: row !important;
                overflow-x: scroll;
                width: 100%;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }
        .nav-pills .nav-link.active,
        .nav-pills .show > .nav-link {
            // border-bottom: 7px solid #00ffff !important;
            background: none;
            .svg-wrapper .shape {
                stroke-width: 3px;
                stroke-dashoffset: 0;
                stroke-dasharray: 760;
                // display: block;
            }
        }
        .nav-link:hover {
            .svg-wrapper .shape {
                stroke-width: 3px;
                stroke-dashoffset: 0;
                stroke-dasharray: 760;
                // display: block;
            }
        }
    }
}
