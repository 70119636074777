#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1428px 1164px #ff7e86, 445px 1026px #ff7e86, 1159px 1714px #ff7e86, 1298px 628px #ff7e86,
        460px 1199px #ff7e86, 926px 120px #ff7e86, 1908px 236px #ff7e86, 1287px 616px #ff7e86, 1428px 1782px #ff7e86,
        555px 796px #ff7e86, 639px 836px #ff7e86, 557px 812px #ff7e86, 27px 1377px #ff7e86, 1830px 67px #ff7e86,
        1305px 39px #ff7e86, 1877px 1384px #ff7e86, 197px 493px #ff7e86, 1349px 1831px #ff7e86, 9px 97px #ff7e86,
        725px 1149px #ff7e86, 266px 1409px #ff7e86, 74px 1520px #ff7e86, 1848px 1192px #ff7e86, 302px 1414px #ff7e86,
        1436px 771px #ff7e86, 1874px 1285px #ff7e86, 1611px 945px #ff7e86, 500px 551px #ff7e86, 342px 692px #ff7e86,
        1264px 293px #ff7e86, 1941px 1452px #ff7e86, 167px 993px #ff7e86, 973px 1706px #ff7e86, 276px 876px #ff7e86,
        1626px 116px #ff7e86, 245px 1766px #ff7e86, 1734px 356px #ff7e86, 1919px 872px #ff7e86, 1652px 1838px #ff7e86,
        1447px 170px #ff7e86, 1245px 1112px #ff7e86, 333px 1971px #ff7e86, 1321px 435px #ff7e86, 983px 1508px #ff7e86,
        1155px 1385px #ff7e86, 857px 73px #ff7e86, 281px 1745px #ff7e86, 1116px 464px #ff7e86, 615px 333px #ff7e86,
        797px 1756px #ff7e86, 1658px 29px #ff7e86, 836px 504px #ff7e86, 128px 877px #ff7e86, 1699px 1670px #ff7e86,
        1016px 135px #ff7e86, 33px 294px #ff7e86, 1585px 53px #ff7e86, 1859px 904px #ff7e86, 152px 876px #ff7e86,
        1923px 937px #ff7e86, 1685px 1489px #ff7e86, 1566px 658px #ff7e86, 1263px 43px #ff7e86, 1977px 540px #ff7e86,
        1304px 609px #ff7e86, 694px 1740px #ff7e86, 1301px 1275px #ff7e86, 1107px 1374px #ff7e86, 135px 540px #ff7e86,
        225px 1713px #ff7e86, 357px 236px #ff7e86, 1222px 1158px #ff7e86, 956px 1822px #ff7e86, 845px 798px #ff7e86,
        984px 1873px #ff7e86, 1942px 329px #ff7e86, 1320px 1537px #ff7e86, 807px 1818px #ff7e86, 1047px 606px #ff7e86,
        978px 541px #ff7e86, 1853px 1646px #ff7e86, 1479px 1295px #ff7e86, 1904px 701px #ff7e86, 90px 1509px #ff7e86,
        878px 639px #ff7e86, 154px 91px #ff7e86, 1617px 1652px #ff7e86, 1945px 1118px #ff7e86, 1532px 461px #ff7e86,
        200px 885px #ff7e86, 1529px 296px #ff7e86, 699px 1504px #ff7e86, 1881px 1822px #ff7e86, 865px 687px #ff7e86,
        1757px 314px #ff7e86, 1164px 1486px #ff7e86, 1660px 391px #ff7e86, 829px 1872px #ff7e86, 268px 1808px #ff7e86,
        303px 520px #ff7e86, 1797px 1465px #ff7e86, 1697px 619px #ff7e86, 1078px 1091px #ff7e86, 594px 793px #ff7e86,
        441px 312px #ff7e86, 259px 482px #ff7e86, 546px 1466px #ff7e86, 1921px 682px #ff7e86, 262px 1665px #ff7e86,
        1250px 1061px #ff7e86, 1009px 405px #ff7e86, 1372px 342px #ff7e86, 84px 1302px #ff7e86, 776px 1437px #ff7e86,
        425px 1156px #ff7e86, 1471px 1400px #ff7e86, 1496px 1282px #ff7e86, 238px 251px #ff7e86, 1518px 1961px #ff7e86,
        416px 667px #ff7e86, 1853px 1310px #ff7e86, 216px 353px #ff7e86, 1974px 674px #ff7e86, 768px 771px #ff7e86,
        1743px 714px #ff7e86, 1572px 1899px #ff7e86, 1988px 351px #ff7e86, 1507px 89px #ff7e86, 1084px 1265px #ff7e86,
        379px 1745px #ff7e86, 1160px 721px #ff7e86, 1712px 1023px #ff7e86, 1135px 1003px #ff7e86, 1446px 551px #ff7e86,
        590px 192px #ff7e86, 959px 1180px #ff7e86, 1640px 615px #ff7e86, 1788px 1307px #ff7e86, 1756px 1629px #ff7e86,
        911px 1786px #ff7e86, 1405px 1973px #ff7e86, 1872px 1827px #ff7e86, 171px 832px #ff7e86, 1908px 905px #ff7e86,
        622px 296px #ff7e86, 935px 659px #ff7e86, 195px 1753px #ff7e86, 46px 11px #ff7e86, 107px 85px #ff7e86,
        1024px 1756px #ff7e86, 1179px 1349px #ff7e86, 1765px 241px #ff7e86, 1226px 1802px #ff7e86, 911px 1975px #ff7e86,
        730px 1648px #ff7e86, 869px 616px #ff7e86, 525px 458px #ff7e86, 404px 591px #ff7e86, 1039px 868px #ff7e86,
        1243px 762px #ff7e86, 1217px 207px #ff7e86, 1190px 671px #ff7e86, 922px 329px #ff7e86, 1942px 329px #ff7e86,
        620px 1741px #ff7e86, 1073px 128px #ff7e86, 719px 510px #ff7e86, 89px 1608px #ff7e86, 1730px 652px #ff7e86,
        830px 786px #ff7e86, 931px 1666px #ff7e86, 1981px 1033px #ff7e86, 1854px 1769px #ff7e86, 1903px 564px #ff7e86,
        1590px 1181px #ff7e86, 1902px 1310px #ff7e86, 161px 1852px #ff7e86, 1790px 1878px #ff7e86, 1736px 805px #ff7e86,
        99px 535px #ff7e86, 1686px 717px #ff7e86, 1828px 1656px #ff7e86, 1051px 1874px #ff7e86, 716px 376px #ff7e86,
        1668px 1927px #ff7e86, 1356px 1791px #ff7e86, 837px 1529px #ff7e86, 39px 1743px #ff7e86, 1714px 273px #ff7e86,
        1144px 812px #ff7e86, 1027px 221px #ff7e86, 341px 480px #ff7e86, 1487px 567px #ff7e86, 1651px 1186px #ff7e86,
        402px 380px #ff7e86, 71px 612px #ff7e86, 26px 1211px #ff7e86, 612px 289px #ff7e86, 1121px 1673px #ff7e86,
        1378px 964px #ff7e86, 190px 1860px #ff7e86, 1487px 175px #ff7e86, 1030px 1634px #ff7e86, 205px 511px #ff7e86,
        528px 1596px #ff7e86, 1674px 1184px #ff7e86, 861px 604px #ff7e86, 53px 1179px #ff7e86, 1562px 111px #ff7e86,
        332px 1286px #ff7e86, 1127px 86px #ff7e86, 1703px 1405px #ff7e86, 994px 1007px #ff7e86, 967px 1967px #ff7e86,
        104px 999px #ff7e86, 1419px 294px #ff7e86, 703px 529px #ff7e86, 1175px 213px #ff7e86, 1310px 1762px #ff7e86,
        933px 1138px #ff7e86, 750px 267px #ff7e86, 1822px 1982px #ff7e86, 497px 303px #ff7e86, 1016px 1829px #ff7e86,
        1595px 138px #ff7e86, 714px 1962px #ff7e86, 1560px 1946px #ff7e86, 1666px 393px #ff7e86, 191px 1376px #ff7e86,
        1756px 1405px #ff7e86, 779px 1708px #ff7e86, 371px 338px #ff7e86, 840px 26px #ff7e86, 897px 702px #ff7e86,
        362px 1511px #ff7e86, 1637px 1116px #ff7e86, 298px 1100px #ff7e86, 919px 633px #ff7e86, 1272px 1139px #ff7e86,
        508px 1780px #ff7e86, 1633px 1379px #ff7e86, 1451px 1531px #ff7e86, 8px 1383px #ff7e86, 1377px 1064px #ff7e86,
        1608px 459px #ff7e86, 192px 236px #ff7e86, 239px 1708px #ff7e86, 297px 1688px #ff7e86, 1791px 1303px #ff7e86,
        1741px 141px #ff7e86, 1902px 1550px #ff7e86, 1070px 1709px #ff7e86, 708px 69px #ff7e86, 195px 1139px #ff7e86,
        627px 213px #ff7e86, 1323px 708px #ff7e86, 391px 1548px #ff7e86, 331px 835px #ff7e86, 1975px 287px #ff7e86,
        1320px 664px #ff7e86, 93px 403px #ff7e86, 1983px 1409px #ff7e86, 727px 1116px #ff7e86, 621px 120px #ff7e86,
        1125px 223px #ff7e86, 1797px 304px #ff7e86, 1434px 704px #ff7e86, 1463px 1087px #ff7e86, 907px 223px #ff7e86,
        1942px 1706px #ff7e86, 669px 1121px #ff7e86, 1760px 1796px #ff7e86, 902px 745px #ff7e86, 1339px 1689px #ff7e86,
        1367px 530px #ff7e86, 1513px 770px #ff7e86, 1538px 1368px #ff7e86, 1511px 29px #ff7e86, 214px 1923px #ff7e86,
        1240px 987px #ff7e86, 1250px 1091px #ff7e86, 447px 1706px #ff7e86, 1948px 378px #ff7e86, 395px 770px #ff7e86,
        527px 842px #ff7e86, 677px 955px #ff7e86, 948px 1138px #ff7e86, 122px 110px #ff7e86, 1910px 1376px #ff7e86,
        1707px 1283px #ff7e86, 1838px 1852px #ff7e86, 569px 1104px #ff7e86, 1336px 933px #ff7e86, 1579px 1504px #ff7e86,
        1304px 231px #ff7e86, 134px 1318px #ff7e86, 235px 1660px #ff7e86, 1805px 1990px #ff7e86, 687px 863px #ff7e86,
        1733px 654px #ff7e86, 501px 1445px #ff7e86, 1747px 1381px #ff7e86, 1734px 1529px #ff7e86, 982px 1571px #ff7e86,
        1895px 681px #ff7e86, 1826px 145px #ff7e86, 589px 1897px #ff7e86, 1376px 317px #ff7e86, 1576px 331px #ff7e86,
        1926px 518px #ff7e86, 1345px 729px #ff7e86, 1387px 816px #ff7e86, 532px 389px #ff7e86, 849px 1753px #ff7e86,
        1270px 1681px #ff7e86, 1773px 1723px #ff7e86, 1832px 357px #ff7e86, 1753px 744px #ff7e86, 245px 712px #ff7e86,
        1635px 1996px #ff7e86, 1135px 1224px #ff7e86, 392px 1052px #ff7e86, 351px 625px #ff7e86, 1171px 927px #ff7e86,
        1318px 963px #ff7e86, 705px 1142px #ff7e86, 1531px 640px #ff7e86, 470px 196px #ff7e86, 55px 487px #ff7e86,
        1269px 45px #ff7e86, 1517px 1109px #ff7e86, 1336px 1892px #ff7e86, 1692px 514px #ff7e86, 889px 921px #ff7e86,
        1082px 438px #ff7e86, 1613px 806px #ff7e86, 1084px 96px #ff7e86, 357px 804px #ff7e86, 459px 332px #ff7e86,
        99px 1832px #ff7e86, 1570px 945px #ff7e86, 795px 226px #ff7e86, 195px 36px #ff7e86, 977px 1609px #ff7e86,
        374px 1425px #ff7e86, 1747px 484px #ff7e86, 1108px 1832px #ff7e86, 571px 1576px #ff7e86, 730px 1784px #ff7e86,
        1195px 40px #ff7e86, 1937px 1529px #ff7e86, 1718px 1493px #ff7e86, 1960px 730px #ff7e86, 355px 169px #ff7e86,
        283px 922px #ff7e86, 828px 820px #ff7e86, 143px 1008px #ff7e86, 250px 589px #ff7e86, 1722px 201px #ff7e86,
        1754px 916px #ff7e86, 1080px 1990px #ff7e86, 516px 1075px #ff7e86, 33px 1987px #ff7e86, 393px 1418px #ff7e86,
        435px 945px #ff7e86, 1859px 578px #ff7e86, 630px 100px #ff7e86, 363px 1421px #ff7e86, 1340px 1100px #ff7e86,
        161px 1936px #ff7e86, 601px 1846px #ff7e86, 535px 649px #ff7e86, 905px 143px #ff7e86, 467px 223px #ff7e86,
        689px 727px #ff7e86, 671px 1737px #ff7e86, 394px 84px #ff7e86, 1557px 913px #ff7e86, 1824px 432px #ff7e86,
        1401px 321px #ff7e86, 101px 324px #ff7e86, 69px 1228px #ff7e86, 25px 899px #ff7e86, 1055px 904px #ff7e86,
        245px 1323px #ff7e86, 841px 535px #ff7e86, 35px 1252px #ff7e86, 1642px 1975px #ff7e86, 900px 1971px #ff7e86,
        623px 263px #ff7e86, 1798px 264px #ff7e86, 1091px 1786px #ff7e86, 287px 1162px #ff7e86, 803px 779px #ff7e86,
        1724px 1516px #ff7e86, 441px 1468px #ff7e86, 361px 759px #ff7e86, 239px 288px #ff7e86, 229px 637px #ff7e86,
        1341px 523px #ff7e86, 1710px 1060px #ff7e86, 551px 762px #ff7e86, 684px 200px #ff7e86, 270px 358px #ff7e86,
        1498px 1061px #ff7e86, 1486px 1566px #ff7e86, 1559px 1866px #ff7e86, 1900px 1110px #ff7e86,
        1998px 1701px #ff7e86, 1285px 107px #ff7e86, 1419px 365px #ff7e86, 1152px 45px #ff7e86, 742px 1459px #ff7e86,
        1059px 870px #ff7e86, 544px 1019px #ff7e86, 1797px 249px #ff7e86, 736px 298px #ff7e86, 765px 308px #ff7e86,
        103px 1473px #ff7e86, 739px 1684px #ff7e86, 663px 924px #ff7e86, 1317px 654px #ff7e86, 519px 1650px #ff7e86,
        1011px 1621px #ff7e86, 185px 209px #ff7e86, 933px 249px #ff7e86;
    animation: animStar 140s linear infinite;
}
#stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1076px 1896px #ff7e86, 404px 634px #ff7e86, 525px 201px #ff7e86, 1369px 1229px #ff7e86,
        1255px 80px #ff7e86, 481px 757px #ff7e86, 1976px 462px #ff7e86, 938px 383px #ff7e86, 180px 1275px #ff7e86,
        1691px 1864px #ff7e86, 373px 1267px #ff7e86, 1412px 70px #ff7e86, 130px 584px #ff7e86, 1062px 406px #ff7e86,
        627px 858px #ff7e86, 936px 555px #ff7e86, 1007px 148px #ff7e86, 757px 1257px #ff7e86, 1016px 630px #ff7e86,
        1117px 1094px #ff7e86, 20px 1773px #ff7e86, 1162px 965px #ff7e86, 1792px 534px #ff7e86, 721px 1695px #ff7e86,
        1613px 1595px #ff7e86, 1068px 879px #ff7e86, 1521px 418px #ff7e86, 1230px 727px #ff7e86, 1344px 27px #ff7e86,
        277px 1659px #ff7e86, 909px 1335px #ff7e86, 1339px 1083px #ff7e86, 1971px 1011px #ff7e86, 913px 1000px #ff7e86,
        706px 642px #ff7e86, 461px 1457px #ff7e86, 915px 558px #ff7e86, 586px 623px #ff7e86, 1863px 679px #ff7e86,
        1935px 1983px #ff7e86, 514px 1404px #ff7e86, 1507px 98px #ff7e86, 351px 1978px #ff7e86, 1909px 1414px #ff7e86,
        816px 137px #ff7e86, 1465px 1969px #ff7e86, 165px 494px #ff7e86, 1692px 1567px #ff7e86, 1196px 1211px #ff7e86,
        1070px 1097px #ff7e86, 230px 1103px #ff7e86, 794px 259px #ff7e86, 1031px 1698px #ff7e86;
    animation: animStar 180s linear infinite;
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 1428px 1164px #ff7e86, 445px 1026px #ff7e86, 1159px 1714px #ff7e86, 1298px 628px #ff7e86,
            460px 1199px #ff7e86, 1804px 907px #ff7e86, 1141px 1011px #ff7e86, 1226px 1208px #ff7e86,
            1628px 1841px #ff7e86, 1589px 1705px #ff7e86, 1453px 847px #ff7e86, 1065px 1752px #ff7e86,
            1418px 492px #ff7e86, 1328px 470px #ff7e86, 182px 1px #ff7e86, 409px 1303px #ff7e86, 209px 1716px #ff7e86,
            1161px 809px #ff7e86, 1577px 1292px #ff7e86, 829px 768px #ff7e86, 1951px 977px #ff7e86,
            1554px 1349px #ff7e86, 1904px 1876px #ff7e86, 1097px 972px #ff7e86, 876px 1172px #ff7e86,
            103px 1734px #ff7e86, 1799px 1554px #ff7e86, 1311px 1974px #ff7e86, 661px 604px #ff7e86,
            226px 1874px #ff7e86, 872px 495px #ff7e86, 1563px 1175px #ff7e86, 348px 797px #ff7e86, 1829px 1351px #ff7e86,
            473px 1262px #ff7e86, 803px 256px #ff7e86, 721px 1241px #ff7e86, 1861px 1783px #ff7e86, 116px 51px #ff7e86,
            1379px 863px #ff7e86, 177px 172px #ff7e86, 42px 1256px #ff7e86, 1344px 279px #ff7e86, 677px 1410px #ff7e86,
            271px 724px #ff7e86, 1361px 45px #ff7e86, 243px 840px #ff7e86, 1194px 1997px #ff7e86, 1676px 907px #ff7e86,
            1433px 1394px #ff7e86, 1179px 1972px #ff7e86, 1941px 1136px #ff7e86, 987px 1343px #ff7e86,
            686px 512px #ff7e86, 1252px 811px #ff7e86, 1178px 1448px #ff7e86, 1985px 1634px #ff7e86,
            1201px 1557px #ff7e86, 1981px 449px #ff7e86, 141px 1762px #ff7e86, 1028px 887px #ff7e86,
            788px 1174px #ff7e86, 1351px 395px #ff7e86, 444px 1451px #ff7e86, 1427px 1155px #ff7e86,
            1738px 573px #ff7e86, 125px 730px #ff7e86, 1315px 1400px #ff7e86, 1571px 1532px #ff7e86, 669px 26px #ff7e86,
            787px 1659px #ff7e86, 969px 152px #ff7e86, 950px 1689px #ff7e86, 1409px 1477px #ff7e86, 886px 668px #ff7e86,
            1120px 68px #ff7e86, 1324px 1581px #ff7e86, 1785px 1243px #ff7e86, 1578px 1200px #ff7e86,
            1519px 1460px #ff7e86, 1621px 277px #ff7e86, 1099px 168px #ff7e86, 1866px 1620px #ff7e86,
            1765px 1752px #ff7e86, 1161px 364px #ff7e86, 784px 532px #ff7e86, 335px 550px #ff7e86, 115px 1031px #ff7e86,
            1655px 1716px #ff7e86, 492px 833px #ff7e86, 247px 528px #ff7e86, 1690px 1359px #ff7e86, 568px 1002px #ff7e86,
            655px 707px #ff7e86, 1650px 32px #ff7e86, 279px 194px #ff7e86, 517px 443px #ff7e86, 1275px 109px #ff7e86,
            1337px 278px #ff7e86, 1549px 171px #ff7e86, 579px 1735px #ff7e86, 166px 20px #ff7e86, 1997px 38px #ff7e86,
            1856px 529px #ff7e86, 696px 32px #ff7e86, 1241px 400px #ff7e86, 1579px 550px #ff7e86, 1336px 1778px #ff7e86,
            603px 741px #ff7e86, 1790px 1747px #ff7e86, 1140px 1412px #ff7e86, 1890px 1835px #ff7e86,
            162px 632px #ff7e86, 649px 1348px #ff7e86, 1397px 1771px #ff7e86, 1960px 657px #ff7e86, 931px 1819px #ff7e86,
            1966px 1516px #ff7e86, 204px 726px #ff7e86, 209px 1692px #ff7e86, 1911px 1368px #ff7e86,
            250px 1282px #ff7e86, 460px 103px #ff7e86, 221px 1509px #ff7e86, 18px 637px #ff7e86, 1037px 900px #ff7e86,
            1950px 676px #ff7e86, 219px 1108px #ff7e86, 1438px 116px #ff7e86, 89px 1298px #ff7e86, 222px 1034px #ff7e86,
            841px 1568px #ff7e86, 403px 1305px #ff7e86, 355px 283px #ff7e86, 193px 239px #ff7e86, 1474px 1074px #ff7e86,
            1523px 82px #ff7e86, 1304px 262px #ff7e86, 1468px 1039px #ff7e86, 1479px 1094px #ff7e86,
            475px 1436px #ff7e86, 693px 1630px #ff7e86, 291px 888px #ff7e86, 1864px 108px #ff7e86;
    }
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 1076px 1896px #ff7e86, 404px 634px #ff7e86, 525px 201px #ff7e86, 1369px 1229px #ff7e86,
            1255px 80px #ff7e86, 481px 757px #ff7e86, 1976px 462px #ff7e86, 938px 383px #ff7e86, 180px 1275px #ff7e86,
            1691px 1864px #ff7e86, 373px 1267px #ff7e86, 1412px 70px #ff7e86, 130px 584px #ff7e86, 1062px 406px #ff7e86,
            627px 858px #ff7e86, 936px 555px #ff7e86, 1007px 148px #ff7e86, 757px 1257px #ff7e86, 1016px 630px #ff7e86,
            1117px 1094px #ff7e86, 20px 1773px #ff7e86, 1162px 965px #ff7e86, 1792px 534px #ff7e86, 721px 1695px #ff7e86,
            1613px 1595px #ff7e86, 1068px 879px #ff7e86, 1521px 418px #ff7e86, 1230px 727px #ff7e86, 1344px 27px #ff7e86,
            277px 1659px #ff7e86, 909px 1335px #ff7e86, 1339px 1083px #ff7e86, 1971px 1011px #ff7e86,
            913px 1000px #ff7e86, 706px 642px #ff7e86, 461px 1457px #ff7e86, 915px 558px #ff7e86, 586px 623px #ff7e86,
            1863px 679px #ff7e86, 1935px 1983px #ff7e86, 514px 1404px #ff7e86, 1507px 98px #ff7e86, 351px 1978px #ff7e86,
            1909px 1414px #ff7e86, 816px 137px #ff7e86, 1465px 1969px #ff7e86, 165px 494px #ff7e86,
            1692px 1567px #ff7e86, 1196px 1211px #ff7e86, 1070px 1097px #ff7e86, 230px 1103px #ff7e86,
            794px 259px #ff7e86, 1031px 1698px #ff7e86, 727px 1249px #ff7e86, 1728px 155px #ff7e86, 770px 1880px #ff7e86,
            1118px 94px #ff7e86, 1946px 368px #ff7e86, 1162px 1525px #ff7e86, 1942px 1345px #ff7e86,
            500px 1414px #ff7e86, 924px 1531px #ff7e86, 272px 1977px #ff7e86, 101px 1512px #ff7e86, 735px 883px #ff7e86,
            86px 1031px #ff7e86, 585px 160px #ff7e86, 213px 822px #ff7e86, 1150px 60px #ff7e86, 828px 1970px #ff7e86;
    }
}
#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1850px 499px #ff7e86, 1064px 1281px #ff7e86, 312px 884px #ff7e86, 1672px 1779px #ff7e86,
        543px 1234px #ff7e86, 1266px 244px #ff7e86, 1454px 1388px #ff7e86, 938px 1760px #ff7e86, 1559px 54px #ff7e86,
        411px 872px #ff7e86, 526px 554px #ff7e86, 568px 276px #ff7e86, 1586px 1207px #ff7e86, 957px 597px #ff7e86,
        1800px 1261px #ff7e86, 154px 537px #ff7e86, 208px 1874px #ff7e86, 1074px 1213px #ff7e86, 1486px 951px #ff7e86,
        577px 513px #ff7e86, 761px 1172px #ff7e86, 44px 1063px #ff7e86, 389px 1873px #ff7e86, 919px 955px #ff7e86,
        1218px 699px #ff7e86, 1430px 1462px #ff7e86, 1869px 914px #ff7e86, 1550px 1078px #ff7e86, 1886px 1778px #ff7e86,
        1259px 1047px #ff7e86, 1956px 1165px #ff7e86, 577px 14px #ff7e86, 352px 715px #ff7e86, 783px 1439px #ff7e86,
        1576px 1324px #ff7e86, 73px 278px #ff7e86, 704px 1547px #ff7e86;
    animation: animStar 220s linear infinite;
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: 1850px 499px #ff7e86, 1064px 1281px #ff7e86, 312px 884px #ff7e86, 1672px 1779px #ff7e86,
            543px 1234px #ff7e86, 1266px 244px #ff7e86, 1454px 1388px #ff7e86, 938px 1760px #ff7e86, 1559px 54px #ff7e86,
            411px 872px #ff7e86, 526px 554px #ff7e86, 568px 276px #ff7e86, 1586px 1207px #ff7e86, 957px 597px #ff7e86,
            1800px 1261px #ff7e86, 154px 537px #ff7e86, 208px 1874px #ff7e86, 1074px 1213px #ff7e86,
            1486px 951px #ff7e86, 577px 513px #ff7e86, 761px 1172px #ff7e86, 44px 1063px #ff7e86, 389px 1873px #ff7e86,
            919px 955px #ff7e86, 1218px 699px #ff7e86, 1430px 1462px #ff7e86, 1869px 914px #ff7e86,
            1550px 1078px #ff7e86, 1886px 1778px #ff7e86, 1259px 1047px #ff7e86, 1956px 1165px #ff7e86,
            577px 14px #ff7e86, 352px 715px #ff7e86, 783px 1439px #ff7e86, 1576px 1324px #ff7e86, 73px 278px #ff7e86,
            704px 1547px #ff7e86, 1984px 1389px #ff7e86, 831px 30px #ff7e86, 1354px 1381px #ff7e86, 1287px 991px #ff7e86,
            784px 324px #ff7e86, 990px 1664px #ff7e86, 846px 626px #ff7e86, 1518px 559px #ff7e86, 1340px 301px #ff7e86,
            1182px 345px #ff7e86, 1387px 35px #ff7e86, 1924px 1732px #ff7e86, 1390px 1346px #ff7e86,
            648px 1041px #ff7e86, 728px 1065px #ff7e86, 680px 1092px #ff7e86, 966px 1143px #ff7e86, 1272px 930px #ff7e86,
            1194px 446px #ff7e86, 920px 1091px #ff7e86, 1174px 91px #ff7e86, 1523px 287px #ff7e86, 1392px 442px #ff7e86,
            814px 1593px #ff7e86, 1134px 1971px #ff7e86, 1998px 1328px #ff7e86, 808px 1660px #ff7e86,
            1791px 932px #ff7e86, 1503px 1648px #ff7e86, 156px 847px #ff7e86, 1750px 770px #ff7e86, 460px 726px #ff7e86,
            607px 418px #ff7e86, 91px 1497px #ff7e86, 182px 1036px #ff7e86, 520px 1945px #ff7e86, 851px 138px #ff7e86,
            1326px 1368px #ff7e86, 884px 457px #ff7e86, 510px 109px #ff7e86, 1051px 977px #ff7e86, 1965px 939px #ff7e86,
            1002px 1435px #ff7e86;
    }
}
#stars3,
#stars2,
#stars1,
#stars {
    position: fixed;
}
// .container-effect {
//     position: relative;
//     z-index: 0;
//     height: 100%;
//     width: 100%;
// }
@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}


#stars21 {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1428px 1164px #00ffff, 445px 1026px #00ffff, 1159px 1714px #00ffff, 1298px 628px #00ffff,
        460px 1199px #00ffff, 926px 120px #00ffff, 1908px 236px #00ffff, 1287px 616px #00ffff, 1428px 1782px #00ffff,
        555px 796px #00ffff, 639px 836px #00ffff, 557px 812px #00ffff, 27px 1377px #00ffff, 1830px 67px #00ffff,
        1305px 39px #00ffff, 1877px 1384px #00ffff, 197px 493px #00ffff, 1349px 1831px #00ffff, 9px 97px #00ffff,
        725px 1149px #00ffff, 266px 1409px #00ffff, 74px 1520px #00ffff, 1848px 1192px #00ffff, 302px 1414px #00ffff,
        1436px 771px #00ffff, 1874px 1285px #00ffff, 1611px 945px #00ffff, 500px 551px #00ffff, 342px 692px #00ffff,
        1264px 293px #00ffff, 1941px 1452px #00ffff, 167px 993px #00ffff, 973px 1706px #00ffff, 276px 876px #00ffff,
        1626px 116px #00ffff, 245px 1766px #00ffff, 1734px 356px #00ffff, 1919px 872px #00ffff, 1652px 1838px #00ffff,
        1447px 170px #00ffff, 1245px 1112px #00ffff, 333px 1971px #00ffff, 1321px 435px #00ffff, 983px 1508px #00ffff,
        1155px 1385px #00ffff, 857px 73px #00ffff, 281px 1745px #00ffff, 1116px 464px #00ffff, 615px 333px #00ffff,
        797px 1756px #00ffff, 1658px 29px #00ffff, 836px 504px #00ffff, 128px 877px #00ffff, 1699px 1670px #00ffff,
        1016px 135px #00ffff, 33px 294px #00ffff, 1585px 53px #00ffff, 1859px 904px #00ffff, 152px 876px #00ffff,
        1923px 937px #00ffff, 1685px 1489px #00ffff, 1566px 658px #00ffff, 1263px 43px #00ffff, 1977px 540px #00ffff,
        1304px 609px #00ffff, 694px 1740px #00ffff, 1301px 1275px #00ffff, 1107px 1374px #00ffff, 135px 540px #00ffff,
        225px 1713px #00ffff, 357px 236px #00ffff, 1222px 1158px #00ffff, 956px 1822px #00ffff, 845px 798px #00ffff,
        984px 1873px #00ffff, 1942px 329px #00ffff, 1320px 1537px #00ffff, 807px 1818px #00ffff, 1047px 606px #00ffff,
        978px 541px #00ffff, 1853px 1646px #00ffff, 1479px 1295px #00ffff, 1904px 701px #00ffff, 90px 1509px #00ffff,
        878px 639px #00ffff, 154px 91px #00ffff, 1617px 1652px #00ffff, 1945px 1118px #00ffff, 1532px 461px #00ffff,
        200px 885px #00ffff, 1529px 296px #00ffff, 699px 1504px #00ffff, 1881px 1822px #00ffff, 865px 687px #00ffff,
        1757px 314px #00ffff, 1164px 1486px #00ffff, 1660px 391px #00ffff, 829px 1872px #00ffff, 268px 1808px #00ffff,
        303px 520px #00ffff, 1797px 1465px #00ffff, 1697px 619px #00ffff, 1078px 1091px #00ffff, 594px 793px #00ffff,
        441px 312px #00ffff, 259px 482px #00ffff, 546px 1466px #00ffff, 1921px 682px #00ffff, 262px 1665px #00ffff,
        1250px 1061px #00ffff, 1009px 405px #00ffff, 1372px 342px #00ffff, 84px 1302px #00ffff, 776px 1437px #00ffff,
        425px 1156px #00ffff, 1471px 1400px #00ffff, 1496px 1282px #00ffff, 238px 251px #00ffff, 1518px 1961px #00ffff,
        416px 667px #00ffff, 1853px 1310px #00ffff, 216px 353px #00ffff, 1974px 674px #00ffff, 768px 771px #00ffff,
        1743px 714px #00ffff, 1572px 1899px #00ffff, 1988px 351px #00ffff, 1507px 89px #00ffff, 1084px 1265px #00ffff,
        379px 1745px #00ffff, 1160px 721px #00ffff, 1712px 1023px #00ffff, 1135px 1003px #00ffff, 1446px 551px #00ffff,
        590px 192px #00ffff, 959px 1180px #00ffff, 1640px 615px #00ffff, 1788px 1307px #00ffff, 1756px 1629px #00ffff,
        911px 1786px #00ffff, 1405px 1973px #00ffff, 1872px 1827px #00ffff, 171px 832px #00ffff, 1908px 905px #00ffff,
        622px 296px #00ffff, 935px 659px #00ffff, 195px 1753px #00ffff, 46px 11px #00ffff, 107px 85px #00ffff,
        1024px 1756px #00ffff, 1179px 1349px #00ffff, 1765px 241px #00ffff, 1226px 1802px #00ffff, 911px 1975px #00ffff,
        730px 1648px #00ffff, 869px 616px #00ffff, 525px 458px #00ffff, 404px 591px #00ffff, 1039px 868px #00ffff,
        1243px 762px #00ffff, 1217px 207px #00ffff, 1190px 671px #00ffff, 922px 329px #00ffff, 1942px 329px #00ffff,
        620px 1741px #00ffff, 1073px 128px #00ffff, 719px 510px #00ffff, 89px 1608px #00ffff, 1730px 652px #00ffff,
        830px 786px #00ffff, 931px 1666px #00ffff, 1981px 1033px #00ffff, 1854px 1769px #00ffff, 1903px 564px #00ffff,
        1590px 1181px #00ffff, 1902px 1310px #00ffff, 161px 1852px #00ffff, 1790px 1878px #00ffff, 1736px 805px #00ffff,
        99px 535px #00ffff, 1686px 717px #00ffff, 1828px 1656px #00ffff, 1051px 1874px #00ffff, 716px 376px #00ffff,
        1668px 1927px #00ffff, 1356px 1791px #00ffff, 837px 1529px #00ffff, 39px 1743px #00ffff, 1714px 273px #00ffff,
        1144px 812px #00ffff, 1027px 221px #00ffff, 341px 480px #00ffff, 1487px 567px #00ffff, 1651px 1186px #00ffff,
        402px 380px #00ffff, 71px 612px #00ffff, 26px 1211px #00ffff, 612px 289px #00ffff, 1121px 1673px #00ffff,
        1378px 964px #00ffff, 190px 1860px #00ffff, 1487px 175px #00ffff, 1030px 1634px #00ffff, 205px 511px #00ffff,
        528px 1596px #00ffff, 1674px 1184px #00ffff, 861px 604px #00ffff, 53px 1179px #00ffff, 1562px 111px #00ffff,
        332px 1286px #00ffff, 1127px 86px #00ffff, 1703px 1405px #00ffff, 994px 1007px #00ffff, 967px 1967px #00ffff,
        104px 999px #00ffff, 1419px 294px #00ffff, 703px 529px #00ffff, 1175px 213px #00ffff, 1310px 1762px #00ffff,
        933px 1138px #00ffff, 750px 267px #00ffff, 1822px 1982px #00ffff, 497px 303px #00ffff, 1016px 1829px #00ffff,
        1595px 138px #00ffff, 714px 1962px #00ffff, 1560px 1946px #00ffff, 1666px 393px #00ffff, 191px 1376px #00ffff,
        1756px 1405px #00ffff, 779px 1708px #00ffff, 371px 338px #00ffff, 840px 26px #00ffff, 897px 702px #00ffff,
        362px 1511px #00ffff, 1637px 1116px #00ffff, 298px 1100px #00ffff, 919px 633px #00ffff, 1272px 1139px #00ffff,
        508px 1780px #00ffff, 1633px 1379px #00ffff, 1451px 1531px #00ffff, 8px 1383px #00ffff, 1377px 1064px #00ffff,
        1608px 459px #00ffff, 192px 236px #00ffff, 239px 1708px #00ffff, 297px 1688px #00ffff, 1791px 1303px #00ffff,
        1741px 141px #00ffff, 1902px 1550px #00ffff, 1070px 1709px #00ffff, 708px 69px #00ffff, 195px 1139px #00ffff,
        627px 213px #00ffff, 1323px 708px #00ffff, 391px 1548px #00ffff, 331px 835px #00ffff, 1975px 287px #00ffff,
        1320px 664px #00ffff, 93px 403px #00ffff, 1983px 1409px #00ffff, 727px 1116px #00ffff, 621px 120px #00ffff,
        1125px 223px #00ffff, 1797px 304px #00ffff, 1434px 704px #00ffff, 1463px 1087px #00ffff, 907px 223px #00ffff,
        1942px 1706px #00ffff, 669px 1121px #00ffff, 1760px 1796px #00ffff, 902px 745px #00ffff, 1339px 1689px #00ffff,
        1367px 530px #00ffff, 1513px 770px #00ffff, 1538px 1368px #00ffff, 1511px 29px #00ffff, 214px 1923px #00ffff,
        1240px 987px #00ffff, 1250px 1091px #00ffff, 447px 1706px #00ffff, 1948px 378px #00ffff, 395px 770px #00ffff,
        527px 842px #00ffff, 677px 955px #00ffff, 948px 1138px #00ffff, 122px 110px #00ffff, 1910px 1376px #00ffff,
        1707px 1283px #00ffff, 1838px 1852px #00ffff, 569px 1104px #00ffff, 1336px 933px #00ffff, 1579px 1504px #00ffff,
        1304px 231px #00ffff, 134px 1318px #00ffff, 235px 1660px #00ffff, 1805px 1990px #00ffff, 687px 863px #00ffff,
        1733px 654px #00ffff, 501px 1445px #00ffff, 1747px 1381px #00ffff, 1734px 1529px #00ffff, 982px 1571px #00ffff,
        1895px 681px #00ffff, 1826px 145px #00ffff, 589px 1897px #00ffff, 1376px 317px #00ffff, 1576px 331px #00ffff,
        1926px 518px #00ffff, 1345px 729px #00ffff, 1387px 816px #00ffff, 532px 389px #00ffff, 849px 1753px #00ffff,
        1270px 1681px #00ffff, 1773px 1723px #00ffff, 1832px 357px #00ffff, 1753px 744px #00ffff, 245px 712px #00ffff,
        1635px 1996px #00ffff, 1135px 1224px #00ffff, 392px 1052px #00ffff, 351px 625px #00ffff, 1171px 927px #00ffff,
        1318px 963px #00ffff, 705px 1142px #00ffff, 1531px 640px #00ffff, 470px 196px #00ffff, 55px 487px #00ffff,
        1269px 45px #00ffff, 1517px 1109px #00ffff, 1336px 1892px #00ffff, 1692px 514px #00ffff, 889px 921px #00ffff,
        1082px 438px #00ffff, 1613px 806px #00ffff, 1084px 96px #00ffff, 357px 804px #00ffff, 459px 332px #00ffff,
        99px 1832px #00ffff, 1570px 945px #00ffff, 795px 226px #00ffff, 195px 36px #00ffff, 977px 1609px #00ffff,
        374px 1425px #00ffff, 1747px 484px #00ffff, 1108px 1832px #00ffff, 571px 1576px #00ffff, 730px 1784px #00ffff,
        1195px 40px #00ffff, 1937px 1529px #00ffff, 1718px 1493px #00ffff, 1960px 730px #00ffff, 355px 169px #00ffff,
        283px 922px #00ffff, 828px 820px #00ffff, 143px 1008px #00ffff, 250px 589px #00ffff, 1722px 201px #00ffff,
        1754px 916px #00ffff, 1080px 1990px #00ffff, 516px 1075px #00ffff, 33px 1987px #00ffff, 393px 1418px #00ffff,
        435px 945px #00ffff, 1859px 578px #00ffff, 630px 100px #00ffff, 363px 1421px #00ffff, 1340px 1100px #00ffff,
        161px 1936px #00ffff, 601px 1846px #00ffff, 535px 649px #00ffff, 905px 143px #00ffff, 467px 223px #00ffff,
        689px 727px #00ffff, 671px 1737px #00ffff, 394px 84px #00ffff, 1557px 913px #00ffff, 1824px 432px #00ffff,
        1401px 321px #00ffff, 101px 324px #00ffff, 69px 1228px #00ffff, 25px 899px #00ffff, 1055px 904px #00ffff,
        245px 1323px #00ffff, 841px 535px #00ffff, 35px 1252px #00ffff, 1642px 1975px #00ffff, 900px 1971px #00ffff,
        623px 263px #00ffff, 1798px 264px #00ffff, 1091px 1786px #00ffff, 287px 1162px #00ffff, 803px 779px #00ffff,
        1724px 1516px #00ffff, 441px 1468px #00ffff, 361px 759px #00ffff, 239px 288px #00ffff, 229px 637px #00ffff,
        1341px 523px #00ffff, 1710px 1060px #00ffff, 551px 762px #00ffff, 684px 200px #00ffff, 270px 358px #00ffff,
        1498px 1061px #00ffff, 1486px 1566px #00ffff, 1559px 1866px #00ffff, 1900px 1110px #00ffff,
        1998px 1701px #00ffff, 1285px 107px #00ffff, 1419px 365px #00ffff, 1152px 45px #00ffff, 742px 1459px #00ffff,
        1059px 870px #00ffff, 544px 1019px #00ffff, 1797px 249px #00ffff, 736px 298px #00ffff, 765px 308px #00ffff,
        103px 1473px #00ffff, 739px 1684px #00ffff, 663px 924px #00ffff, 1317px 654px #00ffff, 519px 1650px #00ffff,
        1011px 1621px #00ffff, 185px 209px #00ffff, 933px 249px #00ffff;
    animation: animStar 120s linear infinite;
}
#stars22 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1076px 1896px #00ffff, 404px 634px #00ffff, 525px 201px #00ffff, 1369px 1229px #00ffff,
        1255px 80px #00ffff, 481px 757px #00ffff, 1976px 462px #00ffff, 938px 383px #00ffff, 180px 1275px #00ffff,
        1691px 1864px #00ffff, 373px 1267px #00ffff, 1412px 70px #00ffff, 130px 584px #00ffff, 1062px 406px #00ffff,
        627px 858px #00ffff, 936px 555px #00ffff, 1007px 148px #00ffff, 757px 1257px #00ffff, 1016px 630px #00ffff,
        1117px 1094px #00ffff, 20px 1773px #00ffff, 1162px 965px #00ffff, 1792px 534px #00ffff, 721px 1695px #00ffff,
        1613px 1595px #00ffff, 1068px 879px #00ffff, 1521px 418px #00ffff, 1230px 727px #00ffff, 1344px 27px #00ffff,
        277px 1659px #00ffff, 909px 1335px #00ffff, 1339px 1083px #00ffff, 1971px 1011px #00ffff, 913px 1000px #00ffff,
        706px 642px #00ffff, 461px 1457px #00ffff, 915px 558px #00ffff, 586px 623px #00ffff, 1863px 679px #00ffff,
        1935px 1983px #00ffff, 514px 1404px #00ffff, 1507px 98px #00ffff, 351px 1978px #00ffff, 1909px 1414px #00ffff,
        816px 137px #00ffff, 1465px 1969px #00ffff, 165px 494px #00ffff, 1692px 1567px #00ffff, 1196px 1211px #00ffff,
        1070px 1097px #00ffff, 230px 1103px #00ffff, 794px 259px #00ffff, 1031px 1698px #00ffff;
    animation: animStar 140s linear infinite;
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 1428px 1164px #00ffff, 445px 1026px #00ffff, 1159px 1714px #00ffff, 1298px 628px #00ffff,
            460px 1199px #00ffff, 1804px 907px #00ffff, 1141px 1011px #00ffff, 1226px 1208px #00ffff,
            1628px 1841px #00ffff, 1589px 1705px #00ffff, 1453px 847px #00ffff, 1065px 1752px #00ffff,
            1418px 492px #00ffff, 1328px 470px #00ffff, 182px 1px #00ffff, 409px 1303px #00ffff, 209px 1716px #00ffff,
            1161px 809px #00ffff, 1577px 1292px #00ffff, 829px 768px #00ffff, 1951px 977px #00ffff,
            1554px 1349px #00ffff, 1904px 1876px #00ffff, 1097px 972px #00ffff, 876px 1172px #00ffff,
            103px 1734px #00ffff, 1799px 1554px #00ffff, 1311px 1974px #00ffff, 661px 604px #00ffff,
            226px 1874px #00ffff, 872px 495px #00ffff, 1563px 1175px #00ffff, 348px 797px #00ffff, 1829px 1351px #00ffff,
            473px 1262px #00ffff, 803px 256px #00ffff, 721px 1241px #00ffff, 1861px 1783px #00ffff, 116px 51px #00ffff,
            1379px 863px #00ffff, 177px 172px #00ffff, 42px 1256px #00ffff, 1344px 279px #00ffff, 677px 1410px #00ffff,
            271px 724px #00ffff, 1361px 45px #00ffff, 243px 840px #00ffff, 1194px 1997px #00ffff, 1676px 907px #00ffff,
            1433px 1394px #00ffff, 1179px 1972px #00ffff, 1941px 1136px #00ffff, 987px 1343px #00ffff,
            686px 512px #00ffff, 1252px 811px #00ffff, 1178px 1448px #00ffff, 1985px 1634px #00ffff,
            1201px 1557px #00ffff, 1981px 449px #00ffff, 141px 1762px #00ffff, 1028px 887px #00ffff,
            788px 1174px #00ffff, 1351px 395px #00ffff, 444px 1451px #00ffff, 1427px 1155px #00ffff,
            1738px 573px #00ffff, 125px 730px #00ffff, 1315px 1400px #00ffff, 1571px 1532px #00ffff, 669px 26px #00ffff,
            787px 1659px #00ffff, 969px 152px #00ffff, 950px 1689px #00ffff, 1409px 1477px #00ffff, 886px 668px #00ffff,
            1120px 68px #00ffff, 1324px 1581px #00ffff, 1785px 1243px #00ffff, 1578px 1200px #00ffff,
            1519px 1460px #00ffff, 1621px 277px #00ffff, 1099px 168px #00ffff, 1866px 1620px #00ffff,
            1765px 1752px #00ffff, 1161px 364px #00ffff, 784px 532px #00ffff, 335px 550px #00ffff, 115px 1031px #00ffff,
            1655px 1716px #00ffff, 492px 833px #00ffff, 247px 528px #00ffff, 1690px 1359px #00ffff, 568px 1002px #00ffff,
            655px 707px #00ffff, 1650px 32px #00ffff, 279px 194px #00ffff, 517px 443px #00ffff, 1275px 109px #00ffff,
            1337px 278px #00ffff, 1549px 171px #00ffff, 579px 1735px #00ffff, 166px 20px #00ffff, 1997px 38px #00ffff,
            1856px 529px #00ffff, 696px 32px #00ffff, 1241px 400px #00ffff, 1579px 550px #00ffff, 1336px 1778px #00ffff,
            603px 741px #00ffff, 1790px 1747px #00ffff, 1140px 1412px #00ffff, 1890px 1835px #00ffff,
            162px 632px #00ffff, 649px 1348px #00ffff, 1397px 1771px #00ffff, 1960px 657px #00ffff, 931px 1819px #00ffff,
            1966px 1516px #00ffff, 204px 726px #00ffff, 209px 1692px #00ffff, 1911px 1368px #00ffff,
            250px 1282px #00ffff, 460px 103px #00ffff, 221px 1509px #00ffff, 18px 637px #00ffff, 1037px 900px #00ffff,
            1950px 676px #00ffff, 219px 1108px #00ffff, 1438px 116px #00ffff, 89px 1298px #00ffff, 222px 1034px #00ffff,
            841px 1568px #00ffff, 403px 1305px #00ffff, 355px 283px #00ffff, 193px 239px #00ffff, 1474px 1074px #00ffff,
            1523px 82px #00ffff, 1304px 262px #00ffff, 1468px 1039px #00ffff, 1479px 1094px #00ffff,
            475px 1436px #00ffff, 693px 1630px #00ffff, 291px 888px #00ffff, 1864px 108px #00ffff;
    }
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 1076px 1896px #00ffff, 404px 634px #00ffff, 525px 201px #00ffff, 1369px 1229px #00ffff,
            1255px 80px #00ffff, 481px 757px #00ffff, 1976px 462px #00ffff, 938px 383px #00ffff, 180px 1275px #00ffff,
            1691px 1864px #00ffff, 373px 1267px #00ffff, 1412px 70px #00ffff, 130px 584px #00ffff, 1062px 406px #00ffff,
            627px 858px #00ffff, 936px 555px #00ffff, 1007px 148px #00ffff, 757px 1257px #00ffff, 1016px 630px #00ffff,
            1117px 1094px #00ffff, 20px 1773px #00ffff, 1162px 965px #00ffff, 1792px 534px #00ffff, 721px 1695px #00ffff,
            1613px 1595px #00ffff, 1068px 879px #00ffff, 1521px 418px #00ffff, 1230px 727px #00ffff, 1344px 27px #00ffff,
            277px 1659px #00ffff, 909px 1335px #00ffff, 1339px 1083px #00ffff, 1971px 1011px #00ffff,
            913px 1000px #00ffff, 706px 642px #00ffff, 461px 1457px #00ffff, 915px 558px #00ffff, 586px 623px #00ffff,
            1863px 679px #00ffff, 1935px 1983px #00ffff, 514px 1404px #00ffff, 1507px 98px #00ffff, 351px 1978px #00ffff,
            1909px 1414px #00ffff, 816px 137px #00ffff, 1465px 1969px #00ffff, 165px 494px #00ffff,
            1692px 1567px #00ffff, 1196px 1211px #00ffff, 1070px 1097px #00ffff, 230px 1103px #00ffff,
            794px 259px #00ffff, 1031px 1698px #00ffff, 727px 1249px #00ffff, 1728px 155px #00ffff, 770px 1880px #00ffff,
            1118px 94px #00ffff, 1946px 368px #00ffff, 1162px 1525px #00ffff, 1942px 1345px #00ffff,
            500px 1414px #00ffff, 924px 1531px #00ffff, 272px 1977px #00ffff, 101px 1512px #00ffff, 735px 883px #00ffff,
            86px 1031px #00ffff, 585px 160px #00ffff, 213px 822px #00ffff, 1150px 60px #00ffff, 828px 1970px #00ffff;
    }
}
#stars23 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1850px 499px #00ffff, 1064px 1281px #00ffff, 312px 884px #00ffff, 1672px 1779px #00ffff,
        543px 1234px #00ffff, 1266px 244px #00ffff, 1454px 1388px #00ffff, 938px 1760px #00ffff, 1559px 54px #00ffff,
        411px 872px #00ffff, 526px 554px #00ffff, 568px 276px #00ffff, 1586px 1207px #00ffff, 957px 597px #00ffff,
        1800px 1261px #00ffff, 154px 537px #00ffff, 208px 1874px #00ffff, 1074px 1213px #00ffff, 1486px 951px #00ffff,
        577px 513px #00ffff, 761px 1172px #00ffff, 44px 1063px #00ffff, 389px 1873px #00ffff, 919px 955px #00ffff,
        1218px 699px #00ffff, 1430px 1462px #00ffff, 1869px 914px #00ffff, 1550px 1078px #00ffff, 1886px 1778px #00ffff,
        1259px 1047px #00ffff, 1956px 1165px #00ffff, 577px 14px #00ffff, 352px 715px #00ffff, 783px 1439px #00ffff,
        1576px 1324px #00ffff, 73px 278px #00ffff, 704px 1547px #00ffff;
    animation: animStar 160s linear infinite;
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: 1850px 499px #00ffff, 1064px 1281px #00ffff, 312px 884px #00ffff, 1672px 1779px #00ffff,
            543px 1234px #00ffff, 1266px 244px #00ffff, 1454px 1388px #00ffff, 938px 1760px #00ffff, 1559px 54px #00ffff,
            411px 872px #00ffff, 526px 554px #00ffff, 568px 276px #00ffff, 1586px 1207px #00ffff, 957px 597px #00ffff,
            1800px 1261px #00ffff, 154px 537px #00ffff, 208px 1874px #00ffff, 1074px 1213px #00ffff,
            1486px 951px #00ffff, 577px 513px #00ffff, 761px 1172px #00ffff, 44px 1063px #00ffff, 389px 1873px #00ffff,
            919px 955px #00ffff, 1218px 699px #00ffff, 1430px 1462px #00ffff, 1869px 914px #00ffff,
            1550px 1078px #00ffff, 1886px 1778px #00ffff, 1259px 1047px #00ffff, 1956px 1165px #00ffff,
            577px 14px #00ffff, 352px 715px #00ffff, 783px 1439px #00ffff, 1576px 1324px #00ffff, 73px 278px #00ffff,
            704px 1547px #00ffff, 1984px 1389px #00ffff, 831px 30px #00ffff, 1354px 1381px #00ffff, 1287px 991px #00ffff,
            784px 324px #00ffff, 990px 1664px #00ffff, 846px 626px #00ffff, 1518px 559px #00ffff, 1340px 301px #00ffff,
            1182px 345px #00ffff, 1387px 35px #00ffff, 1924px 1732px #00ffff, 1390px 1346px #00ffff,
            648px 1041px #00ffff, 728px 1065px #00ffff, 680px 1092px #00ffff, 966px 1143px #00ffff, 1272px 930px #00ffff,
            1194px 446px #00ffff, 920px 1091px #00ffff, 1174px 91px #00ffff, 1523px 287px #00ffff, 1392px 442px #00ffff,
            814px 1593px #00ffff, 1134px 1971px #00ffff, 1998px 1328px #00ffff, 808px 1660px #00ffff,
            1791px 932px #00ffff, 1503px 1648px #00ffff, 156px 847px #00ffff, 1750px 770px #00ffff, 460px 726px #00ffff,
            607px 418px #00ffff, 91px 1497px #00ffff, 182px 1036px #00ffff, 520px 1945px #00ffff, 851px 138px #00ffff,
            1326px 1368px #00ffff, 884px 457px #00ffff, 510px 109px #00ffff, 1051px 977px #00ffff, 1965px 939px #00ffff,
            1002px 1435px #00ffff;
    }
}
#stars32,
#stars22,
#stars21,
#stars20 {
    position: fixed;
}
// .container-effect {
//     position: relative;
//     z-index: 0;
//     height: 100%;
//     width: 100%;
// }
@keyframes animStar2 {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}
