
































































































































































































































































































































































































































































.item-buy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    background: rgba(53, 63, 72, 0.2);
    padding: 5px 15px;
    &.odd {
        background: rgba(53, 63, 72, 0.4);
    }
    &-name {
        flex: 1 1 auto;
        max-width: 275px;
        min-width: 275px;
        @media (max-width: 767px) {
            min-width: 175px;
            max-width: 180px;
        }
    }
    &-quantity {
        flex: 0 0 100px;
        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        span {
            display: none;
        }
        @media (max-width: 575px) {
            display: none;
        }
    }
    &-newly {
        flex: 0 0 110px;

        .sell-item-button.newly {
            width: 100px;
            border-radius: 15px;
            height: 30px;
            .sell-item-button-content {
                border-radius: 15px;
                font-size: 14px;
            }
        }
    }
    &-price {
        flex: 1 1 auto;
    }
    .hero-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &.left {
            justify-content: flex-start;
            flex: 1 1 auto;
            width: 100%;
        }
        &-avatar {
            width: 48px;
            flex: 0 0 48px;
            height: 48px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
            /* GrayScale/Label */

            border: 0.5px solid #555555;
            border-radius: 8px;
            padding: 5px;
            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            margin-right: 10px;
        }
        &-text {
            text-align: left;
            flex: 1 1 auto;
            max-width: calc(100% - 50px);
            padding-right: 10px;
            &-name {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                text-overflow: ellipsis;
                width: 90%;
            }
            &-platform {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #fcfcfc9f;
            }
        }
        &-text2 {
            text-align: center;
            .small {
                font-size: 14px;
            }
            &-name {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #0fff;
            }

            &-platform {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
            }
            &-price {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
                letter-spacing: 2px;
                display: none;
            }
        }
    }
    @media (max-width: 480px) {
        flex-wrap: wrap;
        &-newly {
            width: 50%;
            flex: 0 0 50%;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            order: 4;
        }
        &-quantity {
            width: 50%;
            flex: 0 0 50%;
            margin-top: 5px;
            display: flex;
            order: 3;
            span {
                display: block;
                margin-right: 5px;
            }
        }
        &-price {
            width: 50%;
            flex: 0 0 50%;
        }
    }
    @media (max-width: 413px) {
        flex-wrap: wrap;
        &-name,
        &-price {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .hero-info {
                &-text2 {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin: 10px 0;
                    &-price {
                        display: flex;
                        flex: 1 1 auto;
                    }
                    &-name {
                        flex: 1 1 auto;
                    }
                    &-platform {
                        flex: 1 1 auto;
                    }
                }
            }
        }
    }
}
