
























































































































































































































































































































.dashboard-page {
    // margin-top: -20px;
    .carousel-item-home {
        padding: 0;
        min-height: 590px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background: url('~@/assets/images/mockup/dashboard/bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right center;
        .hero-book {
            align-items: center;
            .title {
                position: relative;
                margin-bottom: 5vh;
                .before {
                    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 1px;
                    font-family: 'HHBI';

                    line-height: 120%;
                    text-transform: uppercase;

                    -webkit-text-stroke-color: #4effff;
                    opacity: 0.6;
                    font-size: clamp(45px, 3vw, 80px);
                }

                .after {
                    font-family: 'HHBI';
                    font-size: clamp(40px, 3vw, 65px);
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
            }
            .subtext {
                color: #00ffff;
                font-family: 'Helvetica';
                font-size: clamp(1.6em, 4vw, 1.9em);
                padding-bottom: 10px;
                position: relative;
                letter-spacing: 0.6px;
                word-break: normal;
                margin: 25px 0;
                font-weight: 700;

                padding-top: 5vh;
                text-shadow: 5px 4px 5px black;
                &::after {
                    content: '';
                    top: 0;
                    left: 0;
                    width: calc(80% + 10px);
                    height: 2px;
                    background: #ffff;
                    position: absolute;
                    font-weight: 600;
                    max-width: 150px;
                }
                &.subtext-2 {
                    font-size: 20px;
                    padding: 15px 0 0;
                    margin: 0;
                    &::after {
                        content: unset;
                    }
                    &:last-child {
                        font-size: 40px;
                        text-shadow: 2px 1px 5px rgba(255, 255, 255, 0.377);
                    }
                }
            }
        }
    }
    .section-2 {
        padding: 50px 0;
        .statistical-menu {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item-menu {
                height: 50px;
                flex: 1;
                max-width: 150px;
                background: rgba(53, 63, 72, 0.4);
                backdrop-filter: blur(2px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-weight: 700;
                cursor: pointer;
                &.active {
                    background: rgb(0, 255, 255, 0.15);
                    backdrop-filter: blur(2px);
                    color: #00ffff;
                }
                p {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    border-right: 1px solid #00ffff;
                }
                p.border-none {
                    border: none;
                }
            }
        }
        .statistical-body {
            margin-top: 1rem;
            background: rgba(53, 63, 72, 0.4);
            min-height: 250px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .box {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 315px;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 100px;
                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .text {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex: 1 1 auto;
                    flex-direction: column;
                    .title {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1em;
                        font-weight: 600;
                    }
                    .volume {
                        color: #00ffff;
                        text-transform: capitalize;
                        font-size: clamp(1.8em, 4vw, 2.5em);
                        font-weight: 900;
                        font-family: 'HHBI';

                        margin-right: 15px;
                    }
                }
            }
            @media (max-width: 767px) {
                flex-wrap: wrap;
                padding: 15px 0;
                .box {
                    min-width: 250px;
                }
            }
        }
    }
    .section-3 {
        padding: 50px 0;
        div.title {
            color: #fff;
            font-weight: 900;
            text-align: left;
            margin: 25px 0 10px;
            font-size: 2em;
            text-transform: uppercase;
            text-align: center;
            font-family: 'HHBI';
        }
        .header-box {
            display: flex;
            align-items: center;
            justify-content: center;
            .header-item {
                height: 50px;
                flex: 1;
                max-width: 150px;
                background: rgba(0, 255, 255, 0.15);
                backdrop-filter: blur(2px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-weight: 700;
                cursor: pointer;
                background: rgba(53, 63, 72, 0.4);
                backdrop-filter: blur(2px);
                &.active {
                    background: rgba(0, 255, 255, 0.15);
                    backdrop-filter: blur(2px);
                    color: #00ffff;
                }
            }
        }
        .body-box {
            margin-top: 1rem;
            width: 100%;
            padding: 25px 0;
            .no-data {
                position: relative;
                margin: 5vh auto;
                text-align: center;
                .before {
                    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 1px;
                    font-family: 'HHBI';

                    line-height: 120%;
                    text-transform: uppercase;

                    -webkit-text-stroke-color: #4effff;
                    opacity: 0.6;
                    font-size: clamp(35px, 3vw, 50px);
                }

                .after {
                    font-family: 'HHBI';
                    font-size: clamp(25px, 3vw, 40px);
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
            }
        }
    }
    @media (max-width: 767px) {
        .section-2,
        .section-3 {
            max-width: calc(100% - 25px);
        }
    }
    @media (max-width: 575px) {
        .section-3 {
            .body-box {
                background-size: cover;
                background-position: center;
            }
            div.title {
                font-size: clamp(2em, 4vw, 3em);
            }
        }
    }
    .view-more {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        a {
            padding: 5px 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-decoration: none;
            .text {
                color: #fff;
                margin-right: 20px;
                font-size: 17px;
                font-weight: 600;
                letter-spacing: 1px;
                font-family: 'HHBI';
                &:hover {
                    color: #00ffff;
                }
            }
        }
    }
    .paginate-more {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 5px;
        button {
            background: transparent;
            border: 0;
            box-shadow: none;
            padding: 0;
        }
        .text {
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0 15px;
            span {
                color: #00ffff;
            }
        }
    }
}
.count-main {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    margin: auto;
    border-radius: 15px;
    .title-count {
        position: relative;
        margin-bottom: 5vh;

        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            font-family: 'HHBI';

            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #4effff;
            opacity: 0.6;
            font-size: clamp(45px, 3vw, 80px);
        }

        .after {
            font-family: 'HHBI';
            font-size: clamp(40px, 3vw, 65px);
            position: absolute;
            bottom: -15px;
            left: 0;
            right: 0;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
    }
    .sub-count {
        color: #ffffff;
        font-family: 'Helvetica';
        font-size: 24px;
        padding: 10px 0 0;
        position: relative;
        letter-spacing: 0.6px;
        word-break: normal;
        margin: 15px auto 60px;
        font-weight: 700;
        text-shadow: 5px 4px 5px black;
        text-transform: capitalize;
        text-align: left;
        &::after {
            content: '';
            top: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #ffff;
            position: absolute;
            font-weight: 600;
            max-width: 150px;
        }
    }
    .count-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
