











































































































































































































































































































































































































































































































































































$box-max-width: 375px;
$img-max-width: calc(375px * 0.9);
$price-max-width: calc(375px * 0.8);
$price-max-height: calc(375px / 10 * 1.3);
$liner-gradient-color: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
$button-max-width: calc(375px * 0.6);
$button-max-height: calc(375px / 10 * 1.5);
.customer-open-box {
    .modal-dialog {
        // max-width: 320px;

        .modal-content {
            border-radius: 5px;
        }
        .video-cn {
            width: 100%;
            position: relative;
            padding-bottom: 74.69%; /* ratio 16/9 */
        }
    }
}
#mysteryReward___BV_modal_outer_,
#mysteryReceive___BV_modal_outer_ {
    z-index: unset !important;
}
#mysteryReward,
#mysteryReceive {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        outline: none;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        outline: none;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    .box-i,
    .box {
        max-width: 200px;
        width: 100%;
        padding: 5px 0 15px;
        margin: 0 auto 5px;
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            width: 100%;
            overflow: hidden;
            text-align: center;
            position: relative;
            max-width: 200px;
            margin: 10px auto;
            min-height: 200px;
            display: flex;
            align-items: flex-end;
            img {
                width: 100%;
                height: 100%;
                max-width: 130px;
                object-fit: scale-down;
                &.diamond,
                &.silver,
                &.platinum,
                &.gold {
                    max-width: 150px;
                }
                &.hbg {
                    max-width: 110px;
                }
                object-fit: scale-down;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.2em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
    }
}
#info-mystery-box {
    .text-confirm {
        font-size: 1.2em;
        margin: 0px;
        p {
            margin-bottom: 5px;
        }
    }
}
.text-diamond {
    font-size: 1em;
    text-align: center;
    margin: 10px auto;
    font-family: 'HHBI';
    text-transform: uppercase;
    min-height: unset;
    color: #ff00ff;
    text-shadow: 1px 0px 2px #ff00ff;
}
.input-opn {
    max-width: 350px;
    margin: 10px auto;
    input {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    label {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
    }
    small {
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
    }
}
.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
.back-to-shop {
    margin: 40px 0 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    .icons {
        img {
            width: 40px;
            height: auto;
        }
    }
    .text {
        font-family: 'HHBI';
        color: #00ffff;
        font-size: 27px;
        margin-left: 15px;
    }
    @media (max-width: 575px) {
        .text {
            font-size: 20px;
        }
        margin-bottom: 30px;
    }
}

.box-name-mystery {
    position: relative;
    margin-top: 5px;
    &.title {
        @media (min-width: 992px) {
            min-height: 140px;
        }
    }

    .before {
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 0.5px;
        font-family: 'HHBI';

        font-size: clamp(50px, 6vw, 80px);
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
        &.diamond {
            -webkit-text-stroke-color: #ff00ff;
            opacity: 1;
        }
        &.gold {
            -webkit-text-stroke-color: #f3ff00;
            opacity: 0.6;
        }
        &.silver {
            -webkit-text-stroke-color: #98d7e0;
            opacity: 0.6;
        }
        &.platinum {
            -webkit-text-stroke-color: #4effff;
            opacity: 0.4;
        }
        &.herobox {
            color: #fff;
            text-shadow: 1px 0px 9px #ff00ff;
        }
    }
    .after {
        font-size: clamp(40px, 4vw, 70px);
        font-family: 'HHBI';
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        text-transform: uppercase;
        width: max-content;
        &.diamond {
            color: #ff00ff;
            text-shadow: 1px 0px 9px #ff00ff;
        }
        &.gold {
            color: #f3ff00;
            text-shadow: 1px 0px 9px #f3ff00;
        }
        &.silver {
            color: #98d7e0;
            text-shadow: 1px 0px 9px #98d7e0;
        }
        &.platinum {
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
    }
    @media (max-width: 991px) {
        margin-bottom: 10px;
        .mobile {
            display: block;
        }
        .before {
            text-align: center;
            margin: auto;
        }
        .after {
            left: 0;
            right: 0;
            margin: 0 auto;
            width: max-content;
        }
    }
    @media (max-width: 991px) {
        .before {
            font-size: clamp(70px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            font-size: clamp(40px, 3vw, 75px);
        }
    }
    @media (max-width: 575px) {
        .before {
            font-size: clamp(55px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            font-size: clamp(30px, 3vw, 65px);
        }
    }
    @media (max-width: 424px) {
        .before {
            font-size: clamp(45px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            font-size: clamp(25px, 3vw, 65px);
        }
    }
    @media (max-width: 374px) {
        .before {
            font-size: clamp(40px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            bottom: -10px;
            font-size: clamp(30px, 3vw, 65px);
        }
    }
}
.box-mystery-subtitle {
    color: #4effff;
    font-size: clamp(1em, 4vw, 1.5em);
    max-width: 991px;
    line-height: 120%;
    margin: auto;
    text-align: center;
    font-family: 'HHBI';
    &.sub-title2 {
        text-align: left;
        position: relative;
        width: max-content;
        font-size: clamp(1em, 4vw, 2em);
        text-transform: uppercase;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
}
.btn-modal-info-mystery-box {
    background: #00ffff;
    width: 50px;
    height: 30px;
    border: 0;
    border-radius: 5px;
    padding: 0;
    z-index: 10;
    color: black;
    font-weight: bold;
    font-family: 'HHBI';
}
.box-mystery-img {
    width: 100%;
    max-width: 275px;
    margin: 15px auto 0;
    position: relative;
    img {
        width: 100%;
        z-index: 10;
        position: relative;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
        object-fit: scale-down;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
        z-index: 0;
        animation: light 2s infinite linear;
    }
}
.item-on-mystery {
    border: 1px solid #00ffff;
    background: linear-gradient(180deg, #18274a 0%, #001326 100%);
    width: 100%;
    min-height: 300px;
    padding: 15px;
    max-height: 400px;
    overflow: {
        x: hidden;
        y: auto;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        // background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #18274a;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: #00ffff;
    }
    .item-hero {
        width: 100%;
        border: 1px solid #00b1ff;
        min-height: 155px;
        position: relative;
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .avatar-item {
            width: 100%;
            overflow: hidden;
            text-align: center;
            margin-top: 10px;
            img {
                width: 100%;
                height: 100%;
                max-width: 110px;
                object-fit: scale-down;
                &.diamond,
                &.silver,
                &.platinum,
                &.gold {
                    max-width: 130px;
                }
                &.hbg {
                    max-width: 90px;
                }
            }
        }
        .name-item {
            color: #4effff;
            font-size: 18px;
            line-height: 120%;
            text-align: center;
            font-family: 'HHBI';
            margin-bottom: 5px;
        }
        .percent-item {
            position: absolute;
            top: -15px;
            left: 0;
            right: 0;
            margin: auto;
            background: #4effff;
            width: 100px;
            height: 25px;
            border-radius: 15px;
            text-align: center;
            font-size: clamp(1em, 4vw, 1.5em);
            line-height: 120%;
            text-align: center;
            font-family: 'HHBI';
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.box-mystery {
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: flex;
    .box-img {
        position: relative;
        width: 100%;
        max-width: 250px;
        min-width: 175px;
    }
    .box-content {
        background: {
            image: url('~@/assets/images/mockup/shop/content.png');
            size: 100% 100%;
            position: bottom center;
            repeat: no-repeat;
        }
        margin-top: 5px;
        width: 100%;
        min-height: 300px;
        padding: 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            button {
                background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
                border-radius: 0;
                width: 100%;
                min-width: 90px;
                height: 45px;
                padding: 5px;
                color: #000;
                font-weight: 600;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px auto 0;
                font-family: 'HHBI';
                font-size: 20px;
            }
        }
    }
}
.open-mystery-box {
    .raffle-roller {
        height: 100px;
        position: relative;
        margin: 30px auto 30px auto;
        width: 300px;
        @media (min-width: 576px) {
            width: 500px;
        }
        @media (min-width: 768px) {
            width: 700px;
        }
        @media (min-width: 992px) {
            width: 620px;
        }
        @media (min-width: 1440px) {
            width: 900px;
        }
    }
    .raffle-roller-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100px;
        width: 100%;
    }
    .raffle-roller-holder {
        overflow: hidden;
        border-radius: 2px;
        border: 1px solid #00ffff;
    }
    .raffle-roller-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 120px;
        width: 100%;
    }
    .raffle-roller-container {
        width: 9999999999999999999px;
        max-width: 999999999999999999px;
        height: 120px;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        margin-left: 0;
        transition: all 8s cubic-bezier(0.08, 0.6, 0, 1);
    }
    .raffle-roller-holder {
        .arrow {
            position: absolute;
            border: none;
            z-index: 1040;
            width: 40px;
            height: 20px;
            bottom: 10px;
            left: calc(50% - 20px);
            img {
                width: 100%;
            }
        }
    }
    .item {
        display: inline-block;
        float: left;
        margin: 4px 0px 0.5px 5px;
        width: 110px;
        height: 110px;
        border: 1px solid #70677c;
        background: #14202b;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px 0;
        .avatar {
            width: 50px;
            height: 55px;
            margin: 0 auto 5px;
            background-size: contain !important;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .name {
            color: #00ffff;
            font-family: 'HHBI';
            line-height: 120%;
            text-align: center;
        }
    }
    .class_red_item {
        border-bottom: 4px solid #eb4b4b;
    }
    img {
        border: 0;
        vertical-align: middle;
    }
    .winning-item {
        border: 2px solid #66b233;
        position: relative;
        top: -1px;
        border-bottom: 4px solid #66b233;
    }
    .inventory {
        margin: 0 auto;
        width: 960px;
        max-width: 953px;
        padding: 10px 15px 6px;
        height: auto;
        border: 2px solid #1c3344;
        background: #0e1a23;
    }
    .inventory > .item {
        float: none;
        cursor: pointer;
        margin: 4px 2px 0.5px 2px;
    }
    .inventory > .item:hover {
        background-size: 90%;
        background-color: #182a38;
    }
    .inventory > .item:active {
        height: 83px;
        width: 80px;
        position: relative;
        top: -2px;
        border: 2px solid #356d27;
        border-bottom: 4px solid #356d27;
    }
    .action {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        button {
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 0;
            width: 100%;
            min-width: 175px;
            max-width: 250px;
            height: 45px;
            padding: 5px;
            color: #000;
            font-weight: 600;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 5px 0;
            font-family: 'HHBI';
            font-size: 20px;
        }
        a {
            button {
                background: #001326;
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }
}
