




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.box-market {
    @media (max-width: 575px) and (min-width: 414px) {
        .col-xs-6 {
            flex: 0 0 50%;
        }
    }
    .content-market {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 1399px) {
            max-width: calc(100% - 10px);
        }
        .left-filter {
            flex: 0 0 375px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            height: auto;
            padding-bottom: 25px;
            margin-right: 25px;
            .title {
                background: #00ffff;
                height: 60px;
                color: #00172a;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                font-weight: 600;
                text-transform: uppercase;
            }
            .search-id {
                padding: 0 15px;
                margin-top: 25px;
                position: relative;
                input.form-control {
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1px;
                    &::-webkit-input-placeholder {
                        color: #fff;
                        // opacity: 0.6;
                        text-align: left;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-style: italic;
                    }
                    height: 40px;
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    border-radius: 0;
                    padding-right: 50px;
                }
                button {
                    background: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    border: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 25px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .collapse-section {
                padding: 0 15px;
                margin-top: 15px;
                .button-collapse-search {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    .text {
                        height: 40px;
                        width: calc(100% - 40px);
                        border: 1px solid #00ffff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #fff;
                        padding: 0 0.75rem;
                        font-style: italic;
                    }
                    .icons {
                        width: 40px;
                        height: 40px;

                        background: #00ffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .price-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-price,
                .right-price {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .class-filter {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            @media (max-width: 1205px) {
                .title {
                    position: relative;
                }
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 375px;
                height: 100%;
                bottom: 0;
                z-index: 1037;
            }
        }
        .right-box {
            flex: 1 1 auto;

            .sort-box {
                display: flex;
                justify-content: flex-end;
                margin: 0 0 15px auto;
                padding-right: 5px;
                position: relative;
                max-width: 475px;
                .btn-box-buy-now {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    min-width: 120px;
                    max-width: 150px;
                    width: max-content;
                    height: 40px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 5px 2px #00b1ff;
                    border: 0;
                    @media (max-width: 576px) {
                        font-size: 15px;
                    }
                }
                select {
                    max-width: 275px;
                    height: 40px;
                    background: linear-gradient(
                        to right,
                        #00172a 0%,
                        #00000b 100%
                    );
                    color: #fff;
                    border-radius: 0;
                    border: 1px solid #00ffff;
                    option {
                        background: #00000b;
                    }
                }
                img {
                    height: 15px;
                    width: auto;
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
    }
    .filter-active {
        position: fixed;
        left: 0;
        top: 175px;
        z-index: 1030;
        background: #00ffff;
        border-radius: 0;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        width: 150px;
        .icons {
            i,
            svg {
                font-size: 16px;
            }
            margin: 0 10px;
        }
        .name {
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }
    }
    @media (max-width: 991px) {
        .filter-active {
            top: 90px;
        }
    }
    .close {
        background: #00ffff;
        width: 35px;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        height: 35px;
        justify-content: center;
        align-items: center;
        line-height: 0;
        color: #000;
        opacity: 1;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        box-shadow: 5px 5px 5px black;
        display: none;
        @media (max-width: 1205px) {
            display: flex;
        }
    }
    .backdrop-buy {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1010;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.5);
    }
    .box-buy-now {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 10px);
        height: max-content;
        max-width: 720px;
        margin: auto;
        z-index: 1030;
        border-radius: 15px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.5);
        border: 1px solid #02ffff;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
        padding: 15px;
        @media (max-height: 800px) {
            position: absolute;
            bottom: auto;
            top: 100px;
            // min-height: 500px;
        }
        .box-title {
            width: 100%;
            position: relative;
            text-align: center;
            max-height: 60px;
            .before {
                -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                -webkit-text-stroke-width: 1px;
                font-family: 'HHBI';

                line-height: 120%;
                text-transform: uppercase;

                -webkit-text-stroke-color: #4effff;
                opacity: 0.6;
                font-size: clamp(30px, 5.5vw, 50px);
            }

            .after {
                font-family: 'HHBI';
                font-size: clamp(30px, 3vw, 40px);
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
        }
        .left-nav {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 15px;
            width: 100%;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 0.7px solid #ffff;
                width: 100%;
                max-width: 100px;
                max-height: 100px;
                margin: auto;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
                margin: 5px;

                &.active {
                    border: 2px solid #4effff;
                    margin: 4px;
                }
                img {
                    width: auto;
                    height: 60px;
                    object-fit: scale-down;
                }
                .box-name {
                    font-size: 15px;
                    text-align: center;
                    margin-bottom: 5px;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    &.diamond {
                        color: #ff00ff;
                        text-shadow: 1px 0px 9px #ff00ff;
                    }
                    &.gold {
                        color: #f3ff00;
                        text-shadow: 1px 0px 9px #f3ff00;
                    }
                    &.silver {
                        color: #98d7e0;
                        text-shadow: 1px 0px 9px #98d7e0;
                    }
                    &.platinum {
                        color: #4effff;
                        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                    }
                    &.herobox {
                        color: #fff;
                        text-shadow: 1px 0px 9px #ff00ff;
                    }
                }
                @media (max-height: 800px) {
                    width: 70px;
                    height: 70px;
                    img {
                        width: 100%;
                        height: auto;
                        max-height: 35px;
                        margin-bottom: 5px;
                    }
                    .box-name {
                        font-size: 13px;
                    }
                }
            }
        }
        .right-content {
            width: 100%;
            margin-top: 10px;
            .text-platinum {
                font-family: 'HHBI';
                font-size: 20px;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                span {
                    color: #f3ff00;
                    text-shadow: 1px 0px 9px #f3ff00;
                }
            }
            .action {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    margin: 5px;
                    &.buy-now {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        border-radius: 0;
                        width: auto;
                        padding: 5px 15px;
                        height: 45px;
                        color: #000;
                        font-weight: 600;
                        text-transform: capitalize;
                        min-width: 140px;
                        font-size: 1.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &.cancel {
                        background: linear-gradient(
                            90deg,
                            #762525 0%,
                            #f92323 100%
                        );
                        border-radius: 0;
                        width: auto;
                        padding: 5px 15px;
                        height: 45px;
                        color: #fff;
                        font-weight: 600;
                        text-transform: capitalize;
                        min-width: 140px;
                        font-size: 1.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid;
                        &:hover {
                            color: #fff !important;
                            border: 2px solid #fff !important;
                        }
                    }
                }
            }
        }
    }
}
.box-list-market {
    .box {
        width: 100%;
        min-height: 260px;
        padding: 5px 0 15px;
        margin: 0 auto 25px;
        max-width: 300px;
        background: {
            image: url(~@/assets/images/mockup/account/body-box.png);
            repeat: no-repeat;
            size: 100% 100%;
        }
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 155px;
                object-fit: scale-down;
                object-position: bottom;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 7px;
            &.selling {
                flex-direction: column;
                button {
                    margin: 5px auto;
                }
            }
            button {
                width: auto;
                padding: 5px 20px;
                min-width: 90px;
                min-height: 40px;
                border-radius: 5px;
                border: 0;
                color: #ffff;
                font-weight: 600;
                &.sell {
                    background: {
                        image: url('~@/assets/images/mockup/account/sell.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.transfer {
                    background: {
                        image: url('~@/assets/images/mockup/account/transfer.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.price {
                    background: {
                        image: url('~@/assets/images/mockup/account/price.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.cancel {
                    background: {
                        image: url('~@/assets/images/mockup/account/cancel.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
            }
        }
        .action {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                margin: 5px;
                &.buy {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.cancel {
                    background: linear-gradient(
                        90deg,
                        #762525 0%,
                        #f92323 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #fff;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid;
                    &:hover {
                        color: #fff !important;
                        border: 2px solid #fff !important;
                    }
                }
            }
        }
    }
    .box-price {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0 10px;
        .left {
            flex: 0 0 25%;
            color: #ffff;
            font-weight: 600;
        }
        .right {
            flex: 0 0 75%;
            padding-left: 5px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .hbg {
                font-size: 1em;
                font-weight: 900;
                line-height: 120%;
                font-family: 'Helvetica';
                color: #00ffff;
            }
            .usd {
                font-size: 0.7em;
                font-weight: 800;
                margin-left: 10px;
                font-family: 'Helvetica';
                color: #fff;
            }
        }
    }
}
.text-normal {
    font-family: 'helvetica-neue-regular';
    color: white;
    font-size: 28px;
    text-align: center;
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}

#modal-received {
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 600px;
        }
    }

    .modal-content {
        background-image: url(~@/assets/images/mockup/market/bg-modal-market-congrat.png);
        background-size: 110% 110%;
        background-position: center center;
    }

    .modal-body {
        background: none;
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;

        .box {
            max-width: 250px;
            width: 100%;
            padding: 5px 0 15px;
            margin: 0 auto 5px;
            .box-id {
                color: #00ffff;
                font-size: 1.2em;
                font-weight: 800;
                margin-bottom: 0px;
                font-family: 'HHBI';
                line-height: 1.2em;
                text-align: center;
            }
            .box-img {
                text-align: center;
                position: relative;
                max-width: 300px;
                margin: 10px auto;
                img {
                    width: 100%;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 10;
                    max-height: 210px;
                    object-fit: cover;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
                @media (max-width: 767px) {
                    max-width: 250px;
                }
            }
            .box-name {
                font-size: 1.5em;
                text-align: center;
                margin: 10px auto;
                font-family: 'HHBI';
                text-transform: uppercase;
                min-height: unset;
                &.diamond {
                    color: #ff00ff;
                    text-shadow: 1px 0px 9px #ff00ff;
                }
                &.metal {
                    color: #fff;
                    text-shadow: 1px 0px 9px #ffff;
                }
                &.gold {
                    color: #f3ff00;
                    text-shadow: 1px 0px 9px #f3ff00;
                }
                &.silver {
                    color: #98d7e0;
                    text-shadow: 1px 0px 9px #98d7e0;
                }
                &.platinum {
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                    @media (max-width: 374px) {
                        font-size: 1.7em;
                    }
                }
                &.herobox {
                    color: #fff;
                    text-shadow: 1px 0px 9px #ff00ff;
                }
            }
        }

        .box-img-modifer {
            width: 250px;
            margin: 0 auto;
            max-height: 250px;
            overflow: hidden;
        }

        .x1 {
            width: 75px;
            height: 50px;
            margin: 15px auto 30px;
            border: 1px solid #00ffff;

            font-size: 43px;
            text-shadow: 0px 0px 10px #00ffff;
            line-height: 50px;
            text-align: center;
        }

        .btn-confirm {
            background-color: #00ffff;
        }
    }
}
