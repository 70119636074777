



































































































































































































































































































































































































































































































































































































































































































































































































.text-confirm-2 {
    font-size: 1.4em;
    color: #fff;
    font-family: 'HHBI';
    text-align: center;
    margin-top: 10px;
}

#transferHBG,
#transfer,
#withdraw,
#deposit {
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            font-weight: bold;
            letter-spacing: 1px;
            option {
                background: #00000b;
                &:hover {
                    background: #00ffff !important;
                    color: #00000b !important;
                    /* selected */
                }
            }

            option:hover,
            option:checked,
            option:active,
            option:focus {
                background: #00ffff !important;
                color: #00000b !important;
                /* selected */
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
}

.balance-2 {
    .wallet-ingame {
        .title {
            color: #fff;
            font-family: 'Helvetica';
            font-size: clamp(1.6em, 4vw, 1.9em);
            padding-bottom: 10px;
            position: relative;
            letter-spacing: 0.6px;
            width: max-content;
            margin: 25px 0 0;
            font-weight: 900;
            padding: 0 10px 10px;
            &::after {
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #00ffff;
                position: absolute;
                font-weight: 600;
            }
        }
        .list-in-game {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .box-dark {
                border: 1px solid #00ffff;
                -webkit-backdrop-filter: blur(19px);
                backdrop-filter: blur(19px);
                flex: 1;
                margin: 0;
                position: relative;
                border-radius: 4px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background: #00ffff;
                    top: 0;
                    right: 0;
                    border-radius: 2px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background: #00ffff;
                    bottom: 0;
                    left: 0;
                    border-radius: 1.5px;
                }
                .balance-title {
                    color: #fff;
                    padding: 30px 0 10px 10%;
                    font-weight: 600;
                    font-size: 1.2em;
                    position: relative;
                    text-align: left;
                    &::after {
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: calc(50% + 10px);
                        height: 2px;
                        background: #00ffff;
                        position: absolute;
                        font-weight: 600;
                    }
                }
                .balance-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 20px 10%;
                    .top {
                        color: #00ffff;
                        font-weight: 600;
                        font-size: 1.5em;
                    }
                    .bottom {
                        color: #ffff;
                        font-weight: 600;
                        font-size: 0.9em;
                        font-style: italic;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 5px;
                    }
                }
            }
            .balance-hgb {
                flex: 1 1 auto;
                margin-left: 0;
                .balance-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 15px;
                    min-height: calc(100% - 100px);
                    position: relative;
                }
            }
            .balance-body-left {
                flex: 0 0 calc(100% - 100px);
            }
            .balance-body-right {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                // position: absolute;
                // top: 10%;
                // right: 15px;
                img {
                    width: 65px !important;
                    height: 65px !important;
                }
                @media (max-width: 767px) {
                    top: calc(10% - 15px);
                }
            }
            .balance-body-bottom {
                flex: 0 0 100%;
                width: 100%;
                .balance-deposit {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 5px 10px;
                    a {
                        flex: 1 1 auto;
                        border-radius: 0;
                        width: auto;
                        min-width: 90px;
                        height: 45px;
                        padding: 5px;
                        color: #000;
                        font-weight: 600;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 5px;
                        flex-wrap: wrap;
                    }
                }
            }
            .box-game {
                width: 100%;
                margin: 0;
                flex: unset !important;
                .box-dark {
                    min-height: 80px;
                    height: 100%;
                    text-align: center;
                    width: 100%;
                    display: flex;
                    margin: auto !important;
                    padding: 0 15px;
                    align-items: center;
                    max-width: unset;
                    @media (max-width: 991px) {
                        max-width: 500px;
                    }
                    .circle {
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                        margin: 0;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .text {
                        color: #00ffffff;
                        line-height: 120%;
                        font-weight: 600;
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .name-energy {
                            color: #fff;
                            font-size: 1.2em;
                        }
                        .value {
                            font-family: 'HHBI';
                            font-size: 1.5em;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        border-radius: 105px;
        outline: unset;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
        p {
            font-size: 17px;
        }
    }
}
