











































































































































































































































































































































































































































.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        color: #f8f8f8;
        font-family: 'Poppins';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
    }
    .search-member {
        max-width: 467px;
        height: 48px;
        width: 100%;
        border-radius: 128px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            rgba(53, 63, 72, 0.5);
        input {
            outline: none;
            border: none;
            background: none;
            height: 40px;
            width: 90%;
            border-radius: 0;
            color: #fff;
            &:focus {
                box-shadow: none;
            }
        }
        button {
            max-width: 20px;
            width: 100%;
            height: 20px;
            margin-left: auto;
            border-radius: 267px;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none !important;
        }
    }
    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            height: 42px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 0.8);
            // opacity: 0.5;
            border-radius: 128px;
        }
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }
        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            margin-left: 2em;
            margin-right: auto;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }
    .table-responsive {
        .customer-header {
            background-color: transparent;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #00ffff;
        }
        .table th,
        .table td {
            border: none;
        }
        table {
            thead {
                tr {
                    th {
                    }
                }
            }
            tbody {
                tr {
                    height: 70px;
                    td {
                        background: none;
                        height: 100%;
                        padding: 0 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;
                        p {
                            height: 70px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;
                            background: rgba(0, 255, 255, 0.1);
                            justify-content: center;
                        }
                    }
                    &:nth-of-type(even) {
                        td {
                            p {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .table-statistical {
        margin: 35px auto;
        .table-item {
            border-radius: 1px;
            height: 75px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Poppins';
            justify-content: space-between;
            p {
                height: 100%;
                margin-bottom: 0;
                width: 95%;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                display: flex;
                align-items: center;
            }
            .left,
            .right {
                padding: 0%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .left {
                p {
                    padding-left: 5rem;
                }
            }
            .right {
                p {
                    justify-content: center;
                }
            }
            &:nth-of-type(even) {
                p {
                    background: transparent;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }
    .box-statistical {
        // background: linear-gradient(to right, #18d4e2, #a506f9);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 120px;
        height: 100%;
        outline: none;
        width: 100%;
        max-width: 427px;
        padding: 15px;
        border-radius: 4px;
        position: relative;
        margin: 0 auto;
        &::before {
            content: '';
            position: absolute;
            inset: 1px;
            border-radius: 4px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 1);
        }
        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;
            img {
                width: 50px;
                height: auto;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            z-index: 1;
            .text-title {
                color: #4effff;
                font-size: 1.4em;
                line-height: 120%;
                font-family: 'HHBI';
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
            }
            .text-content {
                color: #ffffff;
                font-size: 1.6em;
                line-height: 120%;
                font-family: 'HHBI';
            }
        }
    }
}
