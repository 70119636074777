$body-bg: #fff;
$body-color: #333;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

// Import BT sources
@import "node_modules/bootstrap/scss/bootstrap";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

html {
    overflow: hidden;
}

body {
    padding: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: "helvetica-neue-regular" !important;
    min-height: 100vh;
    max-height: 100vh;
    height: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        // background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 0px;
        background-color: #f5f5f53d;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: rgb(104, 100, 100);
    }
}

@keyframes light {
    0% {
        height: 0;
        opacity: 0;
    }

    25% {
        height: 100%;
        opacity: 0.1;
    }

    50% {
        height: 100%;
        opacity: 1;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        height: 0%;
    }
}

@media (max-width: 1199px) {
    .box-left {
        flex: 0 0 300px;
    }

    .box-right {
        flex: 1 1 auto;
        max-width: calc(100% - 300px);
    }
}

@media (max-width: 991px) {
    .box-detail {
        max-width: calc(100% - 30px);
    }

    .box-right,
    .box-left {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

#particles-js {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}

.cursor-pointer {
    cursor: pointer;
}

.text-main {
    color: #00ffff;
}

.text-dark-hbg {
    color: #00172a;
}

.button-search {
    background: rgba(0, 255, 255, 0.2);
    border-radius: 0;
    width: auto;
    padding: 5px 15px;
    height: 45px;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    min-width: 140px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto 10px;
}

.button-reset {
    background: linear-gradient(90deg, #762525 0%, #f92323 100%);
    border-radius: 0;
    width: auto;
    padding: 5px 15px;
    height: 45px;
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    min-width: 140px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto 10px;
    border: 1px solid;

    &:hover {
        color: #fff !important;
        border: 2px solid #fff !important;
    }
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: "HHBI";
    margin: 20px 0;
    text-transform: capitalize;

    span {
        color: #00ffff;
        font-weight: bold;

        &.warning {
            color: red;
        }
    }
}

// .hero-list {
.rarity-filter,
.skin-filter,
.class-filter {
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-weight: 500;
        font-family: "HHBI";
        padding: 5px !important;
        margin: 0 !important;
        cursor: pointer;

        img {
            max-width: 45px;
        }

        &.active {
            border: 1px solid #00ffff;
            border-radius: 5px;
        }
    }
}

.skin-filter,
.class-filter {
    .item {
        flex-direction: row;

        .icon {
            margin-right: 15px;

            img {
                max-width: 40px;
            }
        }
    }
}

.box-name-2 {
    position: relative;

    .before {
        -webkit-text-fill-color: transparent;
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 0.5px;
        font-family: "Helvetica";

        font-size: clamp(80px, 6vw, 90px);
        line-height: 120%;
        text-transform: uppercase;
        -webkit-text-stroke-color: #ffff;
        opacity: 0.4;
    }

    .after {
        font-size: clamp(40px, 4vw, 50px);
        font-family: "Helvetica";
        text-transform: uppercase;
        font-weight: 900;
        color: #4effff;
        text-align: center;
        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
    }

    @media (max-width: 991px) {
        margin-bottom: 10px;

        .mobile {
            display: block;
        }

        .before {
            text-align: center;
            margin: auto;
        }

        .after {
            left: 0;
            right: 0;
            margin: 0 auto;
            width: max-content;
        }
    }

    @media (max-width: 991px) {
        .before {
            font-size: clamp(70px, 5.5vw, 120px);
            opacity: 0.3;
        }

        .after {
            font-size: clamp(40px, 3vw, 75px);
        }
    }

    @media (max-width: 575px) {
        .before {
            font-size: clamp(55px, 5.5vw, 120px);
            opacity: 0.3;
        }

        .after {
            font-size: clamp(30px, 3vw, 65px);
        }
    }

    @media (max-width: 424px) {
        .before {
            font-size: clamp(45px, 5.5vw, 120px);
            opacity: 0.3;
        }

        .after {
            font-size: clamp(25px, 3vw, 65px);
        }
    }

    @media (max-width: 374px) {
        .before {
            font-size: clamp(40px, 5.5vw, 120px);
            opacity: 0.3;
        }

        .after {
            bottom: -10px;
            font-size: clamp(30px, 3vw, 65px);
        }
    }
}

textarea,
input {
    transform: translateZ(0px) !important;
    // font-size: max(1.1rem, 16px)!important;
}

@supports (-webkit-overflow-scrolling: touch) {
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
        font-size: 16px !important;
        padding: 10px 5px;
        line-height: normal !important;
    }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px !important;
        padding: 10px 5px;
        line-height: normal !important;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px !important;
        line-height: normal !important;
        padding: 10px 5px;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px !important;
        line-height: normal !important;
        padding: 10px 5px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px !important;
        line-height: normal !important;
        padding: 10px 5px;
    }
}

.btn-lock-2 {
    margin: 0 auto;
    width: 100%;
    font-size: 18px;
    font-family: "HHBI";
    color: #00ffff;
    background: #00172a;
    border: 1px solid #00ffff;
    border-radius: 10px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #f92323;
        color: #00172a !important;
    }
}

.btn-help {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-family: "HHBI";
    color: #00ffff;
    background: #00172a;
    border: 1px solid #00ffff;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #f92323;
        color: #00172a !important;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

body.modal-open {
    overflow: hidden;

    .modal {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            // background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f53d;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 1px;
            background-color: rgb(104, 100, 100);
        }
    }
}

.page-break-title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 45px;

    &::before {
        content: "";
        width: 40px;
        flex: 0 0 40px;
        height: 14px;
        background: #00ffff;
        margin-right: 15px;
    }

    &::after {
        content: "";
        flex: 1 1 auto;
        width: 100%;
        height: 1px;
        background: #ccc;
        margin-right: 45px;
    }

    .text {
        flex: 0 0 auto;
        margin-right: 10px;
        font-family: "HHBI";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #f0f0f0;
    }
}

.left-input-field {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    min-width: 200px;

    .top {
        width: 100%;
        text-align: right;
        color: #fff;
    }

    .bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .not-max {
            width: 100% !important;
            min-width: unset;
        }
    }
}

@keyframes fill-anim {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.7;
    }

    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.7;
    }

    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.7;
    }
}

@keyframes ring {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.3;
    }

    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.3;
    }

    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.3;
    }
}

.heading-section {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 15px;
    position: relative;

    &::before {
        content: "";
        width: 40px;
        flex: 0 0 40px;
        height: 14px;
        background: #00ffff;
        margin-right: 50px;
        margin-top: 7px;
    }

    &::after {
        content: "";
        width: 8px;
        flex: 0 0 8px;
        height: 14px;
        background: #00ffff;
        margin-right: 15px;
        margin-top: 7px;
        position: absolute;
        left: 45px;
    }

    .text {
        flex: 1 1 auto;
        margin-right: 10px;
        font-family: "HHBI";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        color: #f0f0f0;

        &.borderStyle {
            padding-bottom: 25px;
            border-bottom: 1px solid #fff;
        }
    }
}

.custom-button {
    background: rgba(0, 255, 255, 0.16);
    border: none;
    border-radius: 30px;
    padding: 0px 30px;
    height: 45px;
    margin: 0px auto 0;
    color: #00ffff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
        padding: 0px 15px;
    }

    img {
        margin-right: 15px;

        @media (max-width: 991px) {
            margin-right: 10px;
        }
    }

    &:hover {
        color: #00ffff;
        text-decoration: none;
    }
}

.box-wallet {
    width: 100%;
    min-height: 150px;
    border: 1px solid #00ffff;
    margin-bottom: 15px;
    padding: 10px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:after,
    &::before {
        z-index: 0;
        content: "";
        width: 6px;
        height: 6px;
        background: #00ffff;
    }

    &:after {
        position: absolute;
        top: 1px;
        right: 1px;
    }

    &::before {
        position: absolute;
        bottom: 1px;
        left: 1px;
    }

    &.main-box {
        min-height: 315px;
        padding: 15px 25px;

        @media (max-width: 413px) {
            padding: 15px 10px;
        }
    }

    &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        &.border-b {
            border-bottom: 0.5px solid #00ffffa2;
            padding: 0 0 10px;
        }

        &-image {
            margin-right: 20px;

            img {
                width: 29px;
                height: 29px;
            }
        }

        &-title {
            font-family: "HHBI";
            font-style: italic;
            font-weight: 400;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.75px;
            color: #fcfcfc;
        }
    }

    &-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 15px 0;
        flex-wrap: wrap;

        &-image {
            position: relative;
            margin-right: 15px;
            flex: 0 0 65px;

            img {
                width: 43px;
                height: 43px;
                margin-right: 15px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 5px;
                height: 5px;
                background: #00ffff;
                border-radius: 50%;
            }
        }

        &-amount {
            flex: 1 1 auto;
            // font-family: "Epilogue";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #00ffff;
        }

        &-symbol {
            flex: 1 1 auto;
            font-family: "HHBI";
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.75px;
            color: #00ffff;
            text-align: center;
        }
    }

    &-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        a {
            .box-wallet-footer-button {
                max-width: unset;
            }
        }

        &-button {
            margin: 10px;
            background: #55555550;
            border-radius: 54px;
            height: 44px;
            width: 160px;
            border: none;
            box-shadow: unset;
            outline: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: calc(50% - 20px);

            &:hover,
            &:focus,
            &:active {
                background: rgba(0, 255, 255, 0.95);

                * {
                    color: #000 !important;
                }
            }

            &-text {
                font-family: "HHBI";
                font-style: italic;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #dcdcdc;
            }

            &-image {
                margin-right: 12px;

                img {
                    width: 17px;
                    height: auto;
                    max-height: 17px;
                    object-fit: scale-down;
                }
            }
        }
    }
}

// hero name color
.free-color {
    color: #dcdcdc !important;
}

.silver-color,
.common-color {
    color: #ffffff !important;
}

.gold-color,
.rare-color {
    color: #fffd79 !important;
}

.platinum-color,
.epic-color {
    color: #76f9ff !important;
}

.diamond-color,
.legend-color {
    color: #ff80e7 !important;
}
.sell-item-button {
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    background: linear-gradient(-175deg, #18d4e2 10%, #a506f9 100%);
    width: 275px;
    height: 55px;
    img {
        margin: 0 10px;
    }
    &.cancel {
        border-radius: 25px;
        width: 135px;
        height: 32px;
        margin: auto;
        background: rgba(53, 63, 72, 0.2);
        border: 0.5px solid #888888;
        .sell-item-button-content {
            background: transparent;
            color: #888888;
        }
        &.sold {
            width: 100px;
            height: 32px;
            .sell-item-button-content {
                background: transparent;
                color: #fe3f61;
            }
        }
    }
    &-content {
        flex: 1 1 auto;
        background: #1d2025;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        line-height: 20px;
        color: #00ffff;
        transition: 1s;
        &.cancel {
            border-radius: 25px;
            font-size: 14px;
        }
    }
    &:hover,
    &:active,
    &:focus {
        .sell-item-button-content {
            // background: #182e33;
            backdrop-filter: blur(2px);
            color: #fe3f61;
        }
        img {
            filter: invert(36%) sepia(45%) saturate(4225%) hue-rotate(329deg) brightness(106%) contrast(99%);
        }
        box-shadow: 0px 0px 10px -5px #a506f9, 0px 0px 10px -5px #a506f9, 0px 0px 10px -5px #18d4e2,
            0px 0px 10px -5px #18d4e2, 0px 0px 10px -5px #a506f9, 0px 0px 10px -5px #18d4e2;
    }
}
