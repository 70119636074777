























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































$box-max-width: 375px;
$img-max-width: calc(375px * 0.9);
$price-max-width: calc(375px * 0.8);
$price-max-height: calc(375px / 10 * 1.3);
$liner-gradient-color: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
$button-max-width: calc(375px * 0.6);
$button-max-height: calc(375px / 10 * 1.5);
.back-to-shop {
    margin: 0px 0 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    .icons {
        img {
            width: 40px;
            height: auto;
        }
    }
    .text {
        font-family: 'HHBI';
        color: #00ffff;
        font-size: 27px;
        margin-left: 15px;
    }
    @media (max-width: 575px) {
        .text {
            font-size: 20px;
        }
        margin-bottom: 30px;
    }
}

.hero-detail {
    padding-top: 70px;
    .box-name {
        position: relative;

        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 0.5px;
            font-family: 'Helvetica';

            font-size: clamp(80px, 6vw, 90px);
            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #ffff;
            opacity: 0.4;
        }
        .after {
            font-size: clamp(40px, 4vw, 50px);
            font-family: 'Helvetica';
            position: absolute;
            bottom: 5px;
            left: 5px;
            text-transform: uppercase;
            font-weight: 900;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 991px) {
            margin-bottom: 10px;
            .mobile {
                display: block;
            }
            .before {
                text-align: center;
                margin: auto;
            }
            .after {
                left: 0;
                right: 0;
                margin: 0 auto;
                width: max-content;
            }
        }
        @media (max-width: 991px) {
            .before {
                font-size: clamp(70px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .before {
                font-size: clamp(55px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .before {
                font-size: clamp(45px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .before {
                font-size: clamp(40px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                bottom: -10px;
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .section-box-mobile,
    .section-buy-box {
        max-width: $box-max-width;
        width: 100%;
        .hero-id {
            color: #ffff;
            font-size: 1.7em;
            font-weight: 800;
            margin-bottom: 0px;
            text-align: left;
            padding-left: 15px;
        }
        [class|='box'] {
            &[class$='-img'] {
                text-align: center;
                position: relative;
                max-width: $img-max-width;
                margin: auto;

                img {
                    width: 100%;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 10;
                    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
                @media (max-width: 767px) {
                    max-width: 250px;
                }
            }

            &[class$='-price'] {
                margin: 30px auto;
                width: $price-max-width;
                min-height: $price-max-height;
                background: {
                    image: url('~@/assets/images/button/price.png');
                    size: 100% 100%;
                    repeat: no-repeat;
                }
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 5px 0;
                .hbg {
                    font-size: 2em;
                    text-align: center;
                    font-family: 'HHBI';
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
                .usd {
                    font-size: 1em;
                    text-align: center;
                    font-family: 'HHBI';
                    margin-left: 5px;
                    color: #ffffff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
                @media (max-width: 767px) {
                    max-width: 200px;
                    .hbg {
                        font-size: 20px;
                    }
                }
                @media (max-width: 575px) {
                    max-width: 250px;
                }
            }
            &[class$='-buy-quantity'] {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: $button-max-width;
                margin: 40px auto;
                button {
                    background: $liner-gradient-color;
                    border-radius: 0px;
                    padding: 2px;
                    width: 50px;
                    height: 50px;
                    div {
                        background: #000000;
                        width: 100%;
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        padding: 5px;
                        span {
                            font-family: 'HHBI';
                            font-size: 2.5em;
                            margin-left: -5px;
                        }
                    }
                }
                span {
                    width: 100px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 2.5em;
                }
                @media (max-width: 767px) {
                    max-width: 200px;
                }
            }
            &[class$='-buy-button'] {
                button {
                    background: $liner-gradient-color;
                    width: $button-max-width;
                    padding: 0;
                    height: $button-max-height;
                    padding: 0;
                    color: #000000;
                    font-family: 'Helvetica';
                    font-size: 1.7em;
                    font-weight: 900;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 25px auto;
                    @media (max-width: 767px) {
                        max-width: 200px;
                        margin: 15px auto;
                    }
                    @media (max-width: 575px) {
                        font-size: 1.6em;
                    }
                }
            }
        }
        .hero-exp {
            max-width: $img-max-width;
            position: relative;
            width: 100%;
            border: 1px solid #00ffff;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px auto 10px;
            background: rgba(0, 255, 255, 0.2);
            .current {
                background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: 0;
            }
        }
        .hero-exp-text {
            position: relative;
            z-index: 10;
            font-weight: 500;
            font-size: 1.6 em;
            color: #ffff;
            text-align: center;
            span {
                color: #00ffff;
                font-weight: 700;
                font-family: 'Helvetica';
            }
        }
        @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: unset;
            // .box-img {
            //     flex: 0 0 50%;
            // }
            // .action-buy {
            //     flex: 0 0 50%;
            // }
        }
        @media (max-width: 575px) {
            flex-wrap: wrap;
            .box-img {
                flex: 0 0 100%;
            }
            .action-buy {
                flex: 0 0 100%;
            }
        }
    }
    .section-box-mobile {
        align-items: flex-start;
        justify-content: space-around;

        .left {
            flex: 0 0 45%;
            img {
                width: 100%;
                height: auto;
            }
        }
        .right {
            margin-left: 20px;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .exp-section {
                padding-left: 15px;
                margin-top: 20px;
                width: 100%;
                .hero-exp {
                    max-width: 100%;
                }
            }
            .box-buy {
                margin-top: 20px;
                display: flex;
                max-width: unset;
                width: 100%;
                margin-left: auto;
                margin-right: 0;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
                padding-left: 15px;
                .left {
                    flex: auto;
                    .hero-price {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        .left {
                            flex: 0 0 25%;
                            color: #ffff;
                            font-weight: 600;
                        }
                        .right {
                            flex: 0 0 75%;
                            padding: 0 10px;

                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .hbg {
                                font-size: 1.4em;
                                font-weight: 900;
                                line-height: 120%;
                                font-family: 'Helvetica';
                                color: #00ffff;
                            }
                            .usd {
                                font-size: 1em;
                                font-weight: 900;
                                margin-left: 10px;
                                font-family: 'Helvetica';
                                color: #fff;
                            }
                        }
                    }
                }
                .right {
                    flex: auto;
                    margin-left: 15px;
                    text-align: center;
                    button {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        color: #002338;
                        width: 90px;
                        height: 40px;
                        font-weight: 700;
                        font-size: 21px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0;
                        box-shadow: 0 0 5px 2px #00b1ff;
                        border: 0;
                    }
                }
            }
            .hero-id {
                padding-left: 15px;
            }
        }
        @media (max-width: 575px) {
            .left,
            .right {
                flex: 0 0 calc(100% - 15px);
                max-width: 400px;
                margin: 0 15px;
                .exp-section,
                .box-buy,
                .hero-id {
                    margin-top: 15px;
                    padding-left: 0;
                    .hero-exp {
                        margin: 0 auto;
                    }
                }
                .box-buy {
                    .right {
                        button {
                            margin: 5px auto;
                        }
                    }
                }
            }
        }
    }
}
.hero-detail {
    .nav-market {
        background: {
            image: url(~@/assets/images/mockup/account/nav-inventory.png);
            size: 100% 100%;
            repeat: no-repeat;
        }
        height: 50px;
        width: 100%;
        max-width: 768px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 35px auto 55px;
        .item-market {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            color: #fff;
            border-left: 2px solid rgba(0, 255, 255, 0.42);
            cursor: pointer;
            &.active {
                background: #00ffff;
                color: #000;
            }

            &:last-child {
                &.active {
                    background: {
                        color: unset;
                        image: url(~@/assets/images/mockup/account/right-active.png);
                        size: 100% 100%;
                        repeat: no-repeat;
                    }
                }
            }
            &:first-child {
                border-left: 0 !important;
                &.active {
                    background: {
                        color: unset;
                        image: url(~@/assets/images/mockup/account/left-active.png);
                        size: 100% 100%;
                        repeat: no-repeat;
                    }
                }
            }
            a {
                // color: #fff;
                font-weight: 600;
                font-family: 'Helvetica';
                font-size: clamp(0.6em, 2vw, 1.2em);
            }
        }
    }
    .box-right {
        .box-buy {
            display: flex;
            max-width: 400px;
            width: fit-content;
            min-width: px;
            margin-left: auto;
            margin-right: 0;
            .left {
                flex: 1 1 auto;
                .hero-price {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    .left {
                        flex: 0 0 25%;
                        color: #ffff;
                        font-weight: 600;
                    }
                    .right {
                        flex: 0 0 75%;
                        padding: 0 10px;

                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .hbg {
                            font-size: 1.4em;
                            font-weight: 900;
                            line-height: 120%;
                            font-family: 'Helvetica';
                            color: #00ffff;
                        }
                        .usd {
                            font-size: 1em;
                            font-weight: 900;
                            margin-left: 10px;
                            font-family: 'Helvetica';
                            color: #fff;
                        }
                    }
                }
            }
            .right {
                flex: 1 1 auto;
                margin-left: 15px;
                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    width: 90px;
                    height: 40px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 5px 2px #00b1ff;
                    border: 0;
                }
            }
        }
        .box-info {
            min-height: 150px;
            width: 100%;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            border: 1px solid #00ffff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            .info {
                display: flex;
                align-items: center;
                font-weight: 600;
                padding: 0 15px;
                .left {
                    color: #fff;
                    margin-right: 15px;
                }
                .right {
                    color: #00ffff;
                    word-break: break-all;
                }
                .icon-right {
                    img {
                        width: 35px;
                    }
                    margin-right: 5px;
                }
            }
        }
        .box-stat {
            margin-top: 50px;
            .nav-stat {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .item-menu {
                    height: 50px;
                    flex: 1;
                    max-width: 150px;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #00ffff;
                    border-right: 1px solid #00ffff;
                    font-weight: 700;
                    cursor: pointer;
                    font-size: 1.2em;
                    &.active {
                        background: linear-gradient(
                            180deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        color: #001326;
                    }
                }
            }
            .body-stat {
                background: linear-gradient(180deg, #18274a 0%, #001326 100%);
                min-height: 250px;
                border: 1px solid #00ffff;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .tabStat {
                    width: 100%;
                    .stat {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin: 15px auto;
                        .left {
                            flex: 0 0 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 50px;
                            .box {
                                width: 50px;
                                height: 50px;
                                border: 2px solid #00ffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    width: 100%;
                                    object-fit: scale-down;
                                }
                            }
                        }
                        .right {
                            flex: 1 1 auto;
                            max-width: 500px;
                            height: 50px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .top {
                                color: #00ffff;
                                font-weight: 600;
                                font-size: 1.2em;
                            }
                            .bottom {
                                position: relative;
                                width: 100%;
                                border: 1px solid #00ffff;
                                height: 25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0px auto;
                                background: rgba(0, 255, 255, 0.2);
                                .current {
                                    background: linear-gradient(
                                        90deg,
                                        #00ffa9 0%,
                                        #00b1ff 100%
                                    );
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #111;
                                    font-family: 'HHBI';
                                    font-size: large;
                                }
                            }
                        }
                    }
                }
                .tabInstinct {
                    .instinct-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 25px;
                        .left {
                            flex: 0 0 45%;
                            .top {
                                color: #00ffff;
                                font-weight: 600;
                                font-size: 1.2em;
                                text-align: center;
                                margin-top: 15px;
                            }
                            .bottom {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                        .right {
                            flex: 1 1 auto;
                            padding: 0 15px 0 0;
                            .top {
                                color: #00ffff;
                                font-weight: 600;
                                font-size: 1.2em;
                            }
                            .bottom {
                                color: #fff;
                                font-weight: 500;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
        .box-offer {
            .table-responsive {
                .customer-header {
                    background-color: #00ffff;
                }
                .table th,
                .table td {
                    border: 1px solid #00ffff;
                }
                table {
                    thead {
                        tr {
                            th {
                                border-right-color: #000 !important;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                background: linear-gradient(
                                    180deg,
                                    #00172a 0%,
                                    #00000b 100%
                                );
                                height: 40px;
                                padding: 0 0.75rem;
                                vertical-align: middle;
                                color: #fff;
                                font-weight: 500;
                            }
                            &:nth-of-type(even) {
                                td {
                                    box-shadow: inset 0px 0px 2005px
                                        rgba(0, 255, 255, 0.2);
                                }
                            }
                        }
                    }
                }
            }
            .action-offer {
                margin-top: 20px;
                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    width: 200px;
                    height: 50px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 15px 5px #00b1ff;
                    border: 0;
                }
            }
        }
    }
}

.text-normal {
    font-family: 'helvetica-neue-regular';
    color: white;
    font-size: 28px;
    text-align: center;
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}

#modal-received {
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 600px;
        }
    }

    .modal-content {
        background-image: url(~@/assets/images/mockup/market/bg-modal-market-congrat.png);
        background-size: 110% 110%;
        background-position: center center;
    }

    .modal-body {
        background: none;
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;

        .hero-name {
            font-size: 1.5em;
            text-shadow: 0px 0px 20px #00ffff;

            span {
                font-size: 2em;
                color: white;
            }
        }

        .box-img-modifer {
            width: 250px;
            margin: 0 auto;
            max-height: 250px;
            overflow: hidden;
        }

        .x1 {
            width: 75px;
            height: 50px;
            margin: 15px auto 30px;
            border: 1px solid #00ffff;

            font-size: 43px;
            text-shadow: 0px 0px 10px #00ffff;
            line-height: 50px;
            text-align: center;
        }

        .btn-confirm {
            background-color: #00ffff;
        }
    }
}

#modal-market-offer {
    .box-input-group {
        font-family: 'hemiheadRg-BoldItalic';
        height: 65px;

        input {
            outline: none;
            margin: 0;
            border-radius: 0;
            color: #fff;
            font-size: clamp(1.5rem, 2vw, 2.4rem);
            height: 65px;

            border: 1px solid #00ffff;
            background: linear-gradient(to right, #18274a, #001326);
            display: block;
            width: 50%;

            &::placeholder {
                color: #fff;
                font-size: clamp(1.5rem, 2vw, 2.4rem);
            }
        }
        .text {
            background: #00ffff;
            font-size: clamp(1.5rem, 1.5vw, 2.4rem);
            color: #000;
            line-height: 65px;
            padding: 0 10px 0 5px;
        }
    }

    .btn-confirm,
    .btn-cancel {
        background-color: #00ffff;
        color: #000;
        font-family: 'helvetica';
        font-size: 20px;
    }

    .btn-cancel {
        background: none;
        border: 1px solid #00ffff;
    }
}
