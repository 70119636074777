












































































.accordion {
    .card,
    .card * {
        border: 0;
    }
    .card-header {
        padding: 0;
    }
    .card-body {
        background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
        color: #fff;
        font-size: 16px;
    }
}
.fq-title {
    background: cyan;
    color: black;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 18px;
}
.faq-box {
    .content-title {
        font-size: 16px;
        text-align: initial;
        line-height: 120%;
        font-weight: 600;
        margin-bottom: 15px;
        letter-spacing: 1px;
    }
    .content-detail {
        font-size: clamp(14px, 3vw, 16px);
        text-align: initial;
        line-height: 120%;
        font-weight: 600;
        text-align: initial;
        margin-bottom: 10px;
        color: #333;
        span {
            font-weight: 700;
            color: #333;
        }
    }
}
