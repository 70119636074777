



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-control-label {
    color: #ffff;
    font-size: 1.2em;
}
#change-email {
    .input-group {
        margin: 15px auto;
        width: 75%;
        min-width: 200px;
    }
}
.connect-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    padding: 5px;
    background: rgba(85, 85, 85, 0.2);
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: scale-down;
    }
}
#change-password {
    .form-group {
        margin: 15px auto;
        a {
            color: #00ffff;
        }
        label {
            font-weight: 600;
            color: #ffff;
            font-size: 16px;
            margin-top: 15px;
        }
        .input-group-text {
            background: transparent;
            padding: 0;
            margin: 10px auto;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
        }
        input {
            height: 40px;
            font-size: clamp(15px, 1vw, 16.2px);
            font-weight: 600;
            margin-top: 10px;
        }
    }
}
.group-input {
    .edit-mail-btn {
        width: 22px !important;
        height: 22px !important;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .label {
        color: #fff;
        width: 100px;
        text-align: center;
        font-weight: 600;
    }
}
.text-showcase {
    color: #fff;
    text-align: center;
    font-size: 1.2em;
    .key-text {
        color: #00ffff;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 25px;
    }
}
.note-warning {
    font-size: 0.9em;
    margin: 15px auto 10px;
    color: #fff;
    text-align: center;
    font-style: italic;
}
.confirm-form {
    .text {
        color: #fff;
        text-align: center;
        font-size: 1.2em;
        .key-text {
            color: #00ffff;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 25px;
        }
    }
    .note {
        font-size: 0.8em;
        margin: 25px auto 15px;
    }
    .confirm-form-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 15px 0;
        .button-confirm {
            margin: 0;
            height: 40px;
        }
        input {
            margin: 0;
            height: 40px;
            max-width: 50%;
        }
    }
}
.setting-tab {
    padding: 0;
    .title {
        color: #f8f8f8;
        font-family: 'Poppins';
        font-size: clamp(1.2em, 4vw, 1.8em);
        letter-spacing: 0.6px;
        width: max-content;
        font-weight: 900;
    }
    .subtitle {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.5em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 15px;
        text-transform: uppercase;
    }
    .setup-account {
        background: {
            image: url(~@/assets/images/mockup/account/setting-box.png);
            size: 100% 100%;
            repeat: no-repeat;
        }
        width: 100%;
        max-width: 500px;
        min-height: 200px;
        margin: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        .text {
            color: #fff;
            text-align: center;
            font-size: 1.2em;
        }
        button {
            background: rgba(0, 255, 255, 0.15);
            border-radius: 267px;
            width: auto;
            padding: 5px 15px;
            height: 45px;
            border: none;
            font-weight: 600;
            text-transform: capitalize;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;

            /* cyan */

            color: #00ffff;
        }
    }
    .change-info-box {
        .save-btn {
            max-width: 269px;
            width: 100%;
            background: rgba(0, 255, 255, 0.2);
            border: none;
            border-radius: 19.5px;
            &:focus,
            :active {
                outline: none;
            }
            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #00ffff;
                margin-bottom: 0;
            }
        }
        .label {
            color: #fff;
            text-transform: capitalize;
            margin-bottom: 5px;
        }
        .general-setting {
            border-bottom: 1px solid #888888;
        }
        .group-input {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                margin: 0 15px 0 0;
                height: 40px;
                background: transparent;
                border: none;
                color: #fff;
                font-weight: 600;
                border-radius: 0;
            }
            button {
                max-width: 180px;
                width: 100%;
                flex: 1 1 auto;
                margin: 0 0 0 15px;
                padding: 0;
                height: 40px;
                font-size: 1.2em;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                input,
                button {
                    flex: unset;
                }
                input {
                    width: 100%;
                }
                button {
                    margin: 10px 0 0;
                }
            }
        }
        .change-password {
            justify-content: flex-start;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .des-fr {
                width: 100%;
                justify-content: space-between;
                align-items: center;
                display: flex;
                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #888888;
                    margin-bottom: 0;
                }
                .change-password-btn {
                    width: 18px;
                    height: 18px;
                    background: transparent;
                    border: none;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }
            }

            .group-input {
                margin-top: 0;
            }
            .label {
                margin-bottom: 5px;
            }
            button {
                width: auto;
                flex: unset;
                padding: 5px 25px;
                margin: 0;
            }
        }
    }
    .button-confirm {
        background: rgba(0, 255, 255, 0.2);
        border-radius: 0;
        width: auto;
        padding: 5px 5px;
        height: 45px;
        color: #000;
        font-weight: 600;
        text-transform: capitalize;
        min-width: 140px;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form-group {
        span {
            text-align: center;
        }
    }
}
.invalid-feedback {
    display: block;
    span {
        margin: auto;
        display: block;
        width: 100%;
        text-align: center;
        &:first-child {
            margin-top: -10px;
        }
    }
}
