












































































































































































































































































































































































































































































































































































































































































































































































































































































































































.input-opn {
    max-width: 425px;
    margin: 10px auto;
    input {
        width: 100%;
        margin: 0 auto;
        max-width: unset;
    }
    label {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 10px;
    }
    small {
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
    }

    button {
        border-radius: 10px;
        height: 40px;
        width: 150px;
        border: none;
        font-family: 'HHBI';
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.75px;
        color: #000;
        box-shadow: unset;
        outline: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 255, 255, 1);
        margin: 10px auto;
    }
}
.count-percent {
    min-height: 200px;
    height: 100%;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
        text-align: center;
        font-family: 'HHBI';
        font-style: italic;
        font-weight: 400;
        color: #fff;
        font-size: 25px;
        line-height: 20px;
        margin-bottom: 25px;
        span {
            font-size: 25px;
            line-height: 20px;
            color: #00ffff;
        }
    }
    span {
        text-align: center;
        font-family: 'HHBI';
        font-style: italic;
        font-weight: 400;
        font-size: 25px;

        line-height: 27px;
        color: #fff;
        &.amount-received {
            span {
                font-size: 29px;
                line-height: 20px;
                color: #00ffff !important;
            }
        }
    }
}
#burn-box-success {
    .text-confirm {
        font-size: 17px;
        .count-percent {
            min-height: unset;
            display: block;
            width: 100%;
            span {
                font-size: 22px;
            }
        }
    }
}
.burn__page {
    &__navigation {
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 768px;
        margin: 0 auto 50px;
        button {
            padding: 0;
            box-shadow: none !important;
            outline: none !important;
            border: 0 !important;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48b7;
            backdrop-filter: blur(2px);
            height: 50px;
            width: 100%;
            max-width: 210px;
            padding: 5px 10px;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #f0f0f0;
            text-align: center;
            margin-right: 15px;
            position: relative;

            &::after,
            &::before {
                height: 50%;
                width: 6px;
                top: 0;
                bottom: 0;
                content: '';
                background: #00ffff;
                margin: auto;
                position: absolute;
            }
            &::after {
                left: -2.5px;
            }
            &::before {
                right: -1.5px;
            }
            &:hover,
            &.active {
                background-color: rgba(0, 255, 255, 0.5) !important;
            }
        }
    }

    .burn__title {
        margin-bottom: 50px;
        &-text {
            font-family: hemiheadRg-BoldItalic;
            font-size: 42px;
            color: #00ffff;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            height: fit-content;
        }
    }
    .box__outside {
        max-width: 300px;
        border: 1px solid #fff;
        border-radius: 5px;
    }
    .box__burn {
        position: relative;
        width: 275px;
        height: 384px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &__circle {
            &-one,
            &-two,
            &-three {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 0;
                border-radius: 50%;
            }

            &-one {
                background: #fe3f61;
                width: calc(275px * 0.45);
                height: calc(275px * 0.45);
                animation: ring 4s infinite ease-in-out;
            }
            &-two {
                border: 2px solid #fe3f61;
                width: calc(275px * 0.75);
                height: calc(275px * 0.75);
                animation: fill-anim 5s infinite ease-in-out;
            }
            &-three {
                border: 2px solid #fe3f61;
                width: 275px;
                height: 275px;
                animation: fill-anim 6s infinite ease-in-out;
            }
        }
        &__selection {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-content: center;
            &-unpick {
                display: flex;
                justify-content: center;
                align-content: center;
                position: absolute;
                z-index: 10;
                height: 36px;
                width: 36px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                .button-chose {
                    padding: 0;
                    background: none !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 36px;
                    width: 36px;
                }
            }
            &-pick {
                display: flex;
                justify-content: center;
                align-content: center;
            }
        }
    }

    .box__information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        &__stat {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 32px;
            &-name {
                flex: 0 0 auto;
            }
            &-value {
                flex: 1 1 auto;
                text-align: right;
                padding-right: 15px;
            }
            &-symbol {
                flex: 0 0 45px;
                color: #00ffff;
            }
        }
    }

    .box__total {
        background-color: rgba(0, 255, 255, 0.15);
        width: 100%;
        min-height: 80px;
        border-radius: 5px;
        padding: 10px 15px;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        &::after,
        &::before {
            height: 50%;
            width: 6px;
            top: 0;
            bottom: 0;
            content: '';
            background: #00ffff;
            margin: auto;
            position: absolute;
        }
        &::after {
            left: -2.5px;
        }
        &::before {
            right: -1.5px;
        }
        &-title {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #f0f0f0;
            text-align: center;
        }
        &-value {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #ffff;
            text-align: center;
            span {
                color: #00ffff;
            }
        }
    }
    .box__expected {
        background-color: rgba(0, 255, 255, 0.15);
        width: 100%;
        min-height: 80px;
        border-radius: 5px;
        padding: 0 15px;
        .box__information {
            &__stat {
                margin-bottom: 5px;
            }
        }
    }
    .box__table__burn {
        margin-top: 60px;
        .table-burn {
            table {
                th,
                tr,
                td {
                    border: 0;
                    min-width: 100px;
                    &:first-child {
                        min-width: unset;
                        padding: 0;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
                th {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #f0f0f0;
                    padding: 24px;
                    position: relative;
                    text-align: center !important;
                    &::after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 2px;
                        height: 16px;
                        content: '';
                        margin: auto;
                        background: #00ffff;
                    }
                    &:first-child,
                    &:last-child {
                        &::after {
                            background: transparent;
                        }
                    }
                }
                tbody {
                    tr,
                    td {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        /* or 150% */
                        vertical-align: middle;
                        letter-spacing: 0.01em;

                        /* GrayScale/Input BG */
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: 25px;
                        color: #f0f0f0;
                    }
                    tr:nth-of-type(odd) {
                        background-color: rgba(0, 255, 255, 0.15);
                        border-radius: 1px;
                    }
                    tr {
                        &:hover {
                            background-color: rgba(0, 255, 255, 0.05);
                            border-radius: 1px;
                            color: #f0f0f0;
                        }
                    }
                }
            }
            .btn-open-detail {
                padding: 0;
                box-shadow: none !important;
                outline: none !important;
                border: 0 !important;
                height: 25px;
                width: 25px;
                background: #00172a;
                border-radius: 50%;
                margin: auto 5px !important;
                img {
                    width: 15px;
                    height: 15px;
                }
                &.open {
                    width: 25px;
                    height: 25px;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
            .b-table-details {
                background: #001326c4 !important;
            }
            .detail__table__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10px;
                text-transform: capitalize;
                &-left {
                    margin-right: 15px;
                    padding-right: 15px;
                }
                &-right {
                }
            }
        }
    }
}
