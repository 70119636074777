




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.container-wallet {
    @media (max-width: 991px) {
        .row {
            margin: 0;
        }
    }
    .col-12 {
        padding-left: 5px;
        padding-right: 5px;
        .row {
            margin: 0;
            .col-12 {
                padding: 0;
            }
        }
    }
    .box-wallet {
        width: 100%;
        min-height: 150px;
        border: 1px solid #00ffff;
        margin-bottom: 15px;
        padding: 10px 10px;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 6px;
            height: 6px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            right: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            left: 1px;
        }
        &.main-box {
            min-height: 315px;
            padding: 15px 25px;
            @media (max-width: 413px) {
                padding: 15px 10px;
            }
        }
        &-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            &.border-b {
                border-bottom: 1px solid #00ffff;
                padding: 0 0 15px;
            }
            &-image {
                margin-right: 20px;
                img {
                    width: 29px;
                    height: 29px;
                }
            }
            &-title {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 20px;
                line-height: 33px;
                letter-spacing: 0.75px;
                color: #fcfcfc;
            }
        }
        &-body {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 15px 0;
            flex-wrap: wrap;
            &-image {
                position: relative;
                margin-right: 15px;
                flex: 0 0 65px;
                img {
                    width: 43px;
                    height: 43px;
                    margin-right: 15px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                    border-radius: 50%;
                }
            }
            &-amount {
                flex: 1 1 auto;
                font-family: 'Epilogue';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                color: #00ffff;
            }
            &-symbol {
                flex: 1 1 auto;
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.75px;
                color: #00ffff;
                text-align: center;
            }
        }
        .box-wallet-footer {
            justify-content: flex-start;
        }
        &-footer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            a {
                .box-wallet-footer-button {
                    max-width: unset;
                }
            }
            &-button {
                margin: 10px;
                background: #55555550;
                border-radius: 54px;
                height: 44px;
                width: 160px;
                border: none;
                box-shadow: unset;
                outline: none !important;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: calc(50% - 20px);
                &:hover,
                &:focus,
                &:active {
                    background: rgba(0, 255, 255, 0.2);
                    * {
                        color: #00ffff !important;
                    }
                }
                &-text {
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 0.75px;
                    color: #dcdcdc;
                }
                &-image {
                    margin-right: 12px;
                    img {
                        width: 17px;
                        height: auto;
                        max-height: 17px;
                        object-fit: scale-down;
                    }
                }
            }
        }
    }
}
#transferHBG,
#transfer,
#swap,
#withdraw,
#deposit {
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
            background: rgba(0, 255, 255, 0.15) !important;
            border: none;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
