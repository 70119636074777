








































































































































































































































































































































































































































































































































































































































.page__vac {
    &__title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            font-family: 'HHBI';

            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #4effff;
            opacity: 0.6;
            font-size: clamp(45px, 3vw, 80px);
        }

        .after {
            font-family: 'HHBI';
            font-size: clamp(40px, 3vw, 65px);
            position: absolute;
            bottom: -15px;
            left: 0;
            right: 0;
            margin: auto;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            text-align: center;
        }

        @media (max-width: 480px) {
            margin-bottom: 20px;
        }
    }
    &__heading {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 0 0 15px;

        &::before {
            content: '';
            width: 40px;
            flex: 0 0 40px;
            height: 14px;
            background: #00ffff;
            margin-right: 15px;
            margin-top: 7px;
        }
        .text {
            flex: 1 1 auto;
            margin-right: 10px;
            font-family: 'HHBI';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #f0f0f0;
            &.borderStyle {
                padding-bottom: 25px;
                border-bottom: 1px solid #fff;
            }
        }
    }
    &__body {
        &__lab {
            &__choose {
                max-width: 400px;
                min-width: 300px;
                width: 100%;
                margin: auto;
                position: relative;
                .image-lab {
                    position: relative;
                    z-index: 0;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .light {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 100%;
                    height: auto;
                }
                @media (max-width: 480px) {
                    max-width: 325px;
                }
            }
            &__picking {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                button {
                    padding: 0;
                    background: none !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 84px;
                    width: 84px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &__picked {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                img {
                    width: 100%;
                    height: auto;
                    max-width: 50%;
                    animation: infinite upD 2s linear;
                }
            }
            &__injected {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                margin-top: 25px;
                &-button {
                    &-content {
                        position: relative;
                        z-index: 10;
                        font-family: 'hemiheadRg-BoldItalic';
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        /* or 144% */

                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.75px;
                        // color: #FE3F61;
                        justify-content: center;
                    }
                    border: 1px solid #00ffff;
                    background: #00172a;
                    box-shadow: unset;
                    position: relative;
                    width: 200px;
                    height: 50px;
                    border-radius: 0;
                    color: #00ffff;
                    &:after,
                    &::before {
                        z-index: 0;
                        content: '';
                        width: 5px;
                        height: 5px;
                        background: #00ffff;
                    }

                    &:after {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                    }
                    &::before {
                        position: absolute;
                        bottom: 1px;
                        right: 1px;
                        -webkit-transform: scaleY(0.5);
                        transform: scaleY(0.5);
                        -webkit-transform-origin: 50% 100%;
                        transform-origin: 50% 100%;
                        -webkit-transition-property: transform;
                        transition-property: transform;
                        -webkit-transition-duration: 0.5s;
                        transition-duration: 0.5s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: #000;
                        &::before {
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            -webkit-transform: scaleY(1);
                            transform: scaleY(1);
                            -webkit-transition-timing-function: cubic-bezier(
                                0.52,
                                1.64,
                                0.37,
                                0.66
                            );
                            transition-timing-function: cubic-bezier(
                                0.52,
                                1.64,
                                0.37,
                                0.66
                            );
                        }
                    }
                }
                &-clear {
                    flex: 0 0 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    margin-right: 15px;
                    button {
                        padding: 0;
                        background: none !important;
                        box-shadow: none !important;
                        outline: none !important;
                        border: 0 !important;
                        height: 50px;
                        width: 50px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                @media (max-width: 767px) {
                    margin-bottom: 25px;
                }
            }
            &__information {
                height: 100%;
                width: 100%;
                padding: 0 10px;
                &__infoVac {
                    min-height: 55px;
                    margin-bottom: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &-label {
                        font-family: 'HHBI';
                        font-style: italic;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;

                        color: #f8f8f8;
                    }
                    &-box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 15px;
                        border: 0.5px solid #888888;
                        border-radius: 20px;
                        width: 100%;
                        max-width: 173px;
                        .amount {
                            flex: 1 1 auto;
                            font-family: 'Work Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.75px;
                            color: #f0f0f0;
                        }
                        .buy-now {
                            flex: 0 0 20px;
                            width: 20px;
                            margin-left: 15px;
                            a {
                                padding: 0;
                                background: #00172a;
                                border: 2px solid #ffff;
                                box-shadow: none !important;
                                outline: none !important;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                padding: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                    &-exp {
                        img {
                            width: 29.43px;
                            height: 52.38px;
                        }
                        @media (max-width: 481px) {
                            display: none;
                        }
                    }
                }
                &__description {
                    min-height: 140px;
                    margin-bottom: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    span {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 29px;
                        color: #00ffff;
                        &.icons {
                            margin-right: 15px;
                        }
                    }
                }
                &__hero {
                    background: linear-gradient(
                            0deg,
                            rgba(0, 0, 0, calc(0.2 * 0.6)),
                            rgba(0, 0, 0, calc(0.2 * 0.6))
                        ),
                        rgba(53, 62, 72, calc(1 * 0.6));
                    min-height: 200px;
                    margin-bottom: 15px;
                    border-radius: 8px;
                    width: 100%;
                    padding: 35px 10px;
                    &-header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-family: 'HHBI';
                        font-style: italic;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 20px;
                        border-bottom: 1px solid #00ffff;
                        padding-bottom: 15px;
                        .header {
                            &-level {
                                color: #00ffff;
                                margin-right: 30px;
                            }
                            &-name {
                                color: #ffffff;
                            }
                        }
                        @media (max-width: 575px) {
                            font-size: 18px;
                        }
                    }
                    &-body {
                        margin-top: 20px;
                    }
                    .box-info-hero {
                        font-family: 'HHBI';
                        font-style: italic;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #fff;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        .left-title {
                            color: #00ffff;
                        }
                        img {
                            width: 20px;
                            height: auto;
                            max-height: 25px;
                        }
                        &.stat {
                            max-width: 67px;
                        }
                    }
                    .right-stat {
                        display: flex;
                        flex: wrap;
                        justify-content: space-around;
                        border-left: 1px solid rgba(204, 204, 204, 0.39);
                        .box-1 {
                            flex: 0 0 75px;
                        }
                        .box-2 {
                            flex: 1 1 auto;
                        }
                        .box-info-hero {
                            justify-content: flex-start;
                            .left-title {
                                flex: 0 0 30px;
                            }
                        }
                        @media (max-width: 575px) {
                            border: 0;
                            padding-left: 0 !important;
                            .box-1,
                            .box-2 {
                                flex: 1 1 auto;
                            }
                        }
                    }
                }
            }
        }
        &__history {
            &__box__table {
                margin-top: 60px;
                .table-vac {
                    table {
                        th,
                        tr,
                        td {
                            border: 0;
                            min-width: 100px;
                            &:first-child {
                                min-width: unset;
                                padding: 0;
                                text-align: center;
                                vertical-align: middle;
                            }
                        }
                        th {
                            font-family: 'Work Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.01em;
                            color: #f0f0f0;
                            padding: 24px;
                            position: relative;
                            text-align: center !important;
                            &::after {
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                width: 2px;
                                height: 16px;
                                content: '';
                                margin: auto;
                                background: #00ffff;
                            }
                            &:last-child {
                                &::after {
                                    background: transparent;
                                }
                            }
                        }
                        tbody {
                            tr,
                            td {
                                font-family: 'Work Sans';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                /* or 150% */
                                vertical-align: middle;
                                letter-spacing: 0.01em;

                                /* GrayScale/Input BG */
                                padding-top: 15px;
                                padding-bottom: 15px;
                                padding-left: 25px;
                                color: #f0f0f0;
                            }
                            tr:nth-of-type(odd) {
                                background-color: rgba(0, 255, 255, 0.15);
                                border-radius: 1px;
                            }
                            tr {
                                &:hover {
                                    background-color: rgba(0, 255, 255, 0.05);
                                    border-radius: 1px;
                                    color: #f0f0f0;
                                }
                            }
                        }
                    }
                    .btn-open-detail {
                        padding: 0;
                        box-shadow: none !important;
                        outline: none !important;
                        border: 0 !important;
                        height: 25px;
                        width: 25px;
                        background: #00172a;
                        border-radius: 50%;
                        margin: auto 5px !important;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                        &.open {
                            width: 25px;
                            height: 25px;
                            img {
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                    .b-table-details {
                        background: #001326c4 !important;
                    }
                    .detail__table__item {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 5px 10px;
                        text-transform: capitalize;
                        &-left {
                            margin-right: 15px;
                            padding-right: 15px;
                        }
                        &-right {
                        }
                    }
                }
            }
        }
    }
    @keyframes upD {
        0%,
        50%,
        100% {
            transform: translateY(0);
        }
        25%,
        75% {
            transform: translateY(-15px);
        }
    }
}
#injectedReceived {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    @media (max-width: 575px) {
        .VueCarousel-navigation-next {
            right: 5% !important;
        }
        .VueCarousel-navigation-prev {
            left: 5% !important;
        }
    }
    .hero-receiver {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: 0 15px 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                max-height: 35px;
                                height: auto;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
#injectedReceived___BV_modal_outer_ {
    z-index: unset !important;
}
