








#accountGame {
    padding: 0;
}
