




















































































































































.metamaskLogin {
    border: 1px solid #00ffff !important;
    background: linear-gradient(180deg, #18274a 0%, #001326 100%) !important;
    color: #fff !important;
    font-weight: 500 !important;
    height: 50px !important;
    width: 100% !important;
    max-width: 375px !important;
    display: flex !important;
    align-items: center !important;
    font-size: 18px !important;
    justify-content: center !important;
    border-radius: 0 !important;
    &:hover {
        color: #00ffff !important;
    }
}
