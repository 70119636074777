




































































































































































































































































































































































































































































































































































.mystery-receive {
    color: #00ffff;
    font-size: 1.5em;
    font-weight: 800;
    font-family: 'HHBI';
    line-height: 150%;
    text-align: center;
    padding: 0px 10px;
    margin: 10px auto;
}
.btn-choose-hero {
    cursor: pointer;
    width: 150px;
    outline: none;
    height: 60px;
    display: flex;
    position: relative;
    padding: 0;
    outline: none;
    font-family: 'Poppins';
    font-size: 17px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none;
    color: #00ffff;
    border: 1px solid #00ffff;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn-choose-hero:before {
    content: '';
    height: 60px;
    width: 150px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00ffff;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @media (max-width: 991px) {
        height: 60px;
        width: 150px;
    }
    @media (max-width: 575px) {
        height: 60px;
        width: 150px;
    }
}
.btn-choose-hero:hover,
.btn-choose-hero:focus,
.btn-choose-hero:active {
    color: black;
}
.btn-choose-hero:hover:before,
.btn-choose-hero:focus:before,
.btn-choose-hero:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.remove {
    position: unset;
    background: red;
    color: #ffff;
    height: 50px;
    border: none;
    transform: unset;
    &:hover {
        box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
            0 0 42px rgba(164, 0, 0, 0.745), 0 0 82px rgba(164, 0, 0, 0.745),
            0 0 92px rgba(164, 0, 0, 0.745);
    }
}
.genesis {
    .genesis-header {
        .btn-back {
            color: #00ffff;
            font-size: 20px;
            font-family: 'helvetica';

            position: absolute;
            // top: 0;
            bottom: 100%;
            left: 0;
            transition: 0.3s;
            cursor: pointer;

            img {
                width: 25px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .genesis-title {
            background-image: url(~@/assets/images/frame/frame-large.png);
            background-size: 100% 100%;
            padding: 20px;

            .text {
                font-family: hemiheadRg-BoldItalic;
                font-size: 42px;
                color: #00ffff;
            }
        }
    }

    .column {
        max-width: 300px;
    }

    @media (max-width: 885px) {
        .column-center {
            order: 3;
        }
    }

    .genesis-hero-item,
    .frame-quesion-mark {
        position: relative;
        .img {
            background: transparent;
            max-width: 235px;
            width: 100%;
            height: 300px;
            margin: 0 auto;
            position: relative;
            background: linear-gradient(to right, #00ffff 4px, transparent 4px)
                    0 0,
                linear-gradient(to right, #00ffff 4px, transparent 4px) 0 100%,
                linear-gradient(to left, #00ffff 4px, transparent 4px) 100% 0,
                linear-gradient(to left, #00ffff 4px, transparent 4px) 100% 100%,
                linear-gradient(to bottom, #00ffff 4px, transparent 4px) 0 0,
                linear-gradient(to bottom, #00ffff 4px, transparent 4px) 100% 0,
                linear-gradient(to top, #00ffff 4px, transparent 4px) 0 100%,
                linear-gradient(to top, #00ffff 4px, transparent 4px) 100% 100%;

            background-repeat: no-repeat;
            background-size: 20px 20px;

            // border: 3px solid #00ffff;
        }
        .hero {
            cursor: pointer;
            position: relative;
            background: linear-gradient(45deg, #022534, #02404e);
            border: 2px solid #00505c;
            width: 100%;
            min-width: 170px;
            max-width: 235px;
            padding: 15px 0;
            margin: 5px auto;
            flex: 1;
            min-height: 225px;
        }
        .summon-count {
            font-family: 'hemiheadRg-BoldItalic';
            color: #00ffff;
            text-shadow: 0px 0px 10px #00ffff;
        }

        .btn-choose-hero {
            cursor: pointer;
            display: flex;

            &-text {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                span {
                    font-family: hemiheadRg-BoldItalic;
                    font-size: 18px;
                    text-shadow: 0px 0px 10px #00ff60;
                }
            }
        }
    }
    .frame-estimate {
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;
        .main-frame-est {
            border-bottom-right-radius: 24px;
            border-top-left-radius: 24px;
            background: rgba(41, 56, 64, 0.5);
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            min-height: 84px;
        }
        .horizon-line {
            position: relative;
            width: 1px;
            min-height: 55px;
            margin: auto 0;
            background: #00ffff;
        }
        .dots {
            background: #00ffff;
            width: 4px;
            height: 4px;
            border-radius: 100%;
        }
        h3 {
            font-size: 23px;
        }

        .bg-half-hexagon {
            width: 100%;

            position: absolute;
            // bottom: -50px;
            bottom: 0;
            left: 0;
        }

        .img-box {
            width: 65px;
            height: 65px;
        }
    }
    .frame-process {
        border: 1px solid #00ffff;
        background-color: #fffff748;
        .process {
            width: auto;
            height: 100%;
            background-image: linear-gradient(to right, #00ffa8, #00b0ff);

            left: 0;
            top: 0;
        }
        p {
            position: relative;
            z-index: 1;

            font-family: 'helvetica';
            font-size: 12px;
            font-weight: bold;
            color: #01171d;
        }
        span {
            font-weight: bold;
        }
    }

    .summon-violet {
        background-image: url(~@/assets/images/mockup/summon/summon-bg.png);
        background-size: 100% 100%;
        padding: 20px 0;
        transition: 0.3s;
        max-width: 200px;
        width: 100%;
        &.blue {
            background-image: url(~@/assets/images/mockup/summon/hexagon-violet-blue.png);
        }

        font-family: 'Poppins';

        h3 {
            font-size: 12px;
            color: #00ffff;
        }

        .img-badge {
            width: 30px;
            height: 30px;
        }

        h4 {
            font-size: 14px;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}
