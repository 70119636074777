













































































































.market-item {
    text-decoration: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    background: rgba(53, 63, 72, 0.2);
    padding: 5px 15px;
    &.odd {
        background: rgba(53, 63, 72, 0.4);
    }
    &-name {
        flex: 1 1 auto;
        max-width: 275px;
        min-width: 275px;
        flex: 1 1 auto;
        max-width: 275px;
        min-width: 275px;
        @media (max-width: 575px) {
            min-width: 200px;
            max-width: 225px;
        }
    }
    &-quantity {
        flex: 0 0 100px;
        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        @media (max-width: 575px) {
            display: none;
        }
    }
    &-price {
        flex: 1 1 200px;
    }
    .hero-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &.left {
            justify-content: flex-start;
        }
        &-avatar {
            width: 48px;
            height: 48px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
            /* GrayScale/Label */

            border: 0.5px solid #555555;
            border-radius: 8px;
            padding: 5px;
            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            margin-right: 10px;
        }
        &-text {
            text-align: left;
            &-name {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
            }
            &-platform {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #fcfcfc9f;
            }
        }
        &-text2 {
            text-align: center;
            .small {
                font-size: 14px;
            }
            &-name {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #0fff;
            }

            &-platform {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
            }
        }
    }
}
