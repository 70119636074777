









































.page-marketplace {
    padding-top: 80px;
    .coming-soon {
        min-height: unset;
        .page-name {
            .before {
                font-size: clamp(45px, 8.5vw, 90px);
            }
            .after {
                font-size: clamp(30px, 5vw, 55px);
            }
        }
    }
    .nav-market {
        background: {
            image: url(~@/assets/images/mockup/account/nav-inventory.png);
            size: 100% 100%;
            repeat: no-repeat;
        }
        height: 50px;
        width: 100%;
        max-width: 768px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 35px auto 55px;
        .item-market {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            color: #fff;
            border-left: 2px solid rgba(0, 255, 255, 0.42);
            cursor: pointer;
            &.active {
                background: #00ffff;
                span {
                    color: #000;
                }
            }

            &:last-child {
                &.active {
                    background: {
                        color: unset;
                        image: url(~@/assets/images/mockup/account/right-active.png);
                        size: 100% 100%;
                        repeat: no-repeat;
                    }
                }
            }
            &:first-child {
                border-left: 0 !important;
                &.active {
                    background: {
                        color: unset;
                        image: url(~@/assets/images/mockup/account/left-active.png);
                        size: 100% 100%;
                        repeat: no-repeat;
                    }
                }
            }
            a,
            span {
                color: #fff;
                font-weight: 600;
                font-family: 'Helvetica';
                font-size: clamp(0.9em, 2vw, 1.2em);
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 991px) {
    .page-marketplace {
        padding-top: 25px;
    }
}
@media (max-width: 575px) {
    .page-marketplace {
        // padding-top: 0;
        .nav-market {
            margin: 10px auto 55px;
        }
    }
}
