





















































































































































































































.custom-select {
    outline: unset;
    box-shadow: none;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 26px;
}
.Item-inventory {
    .box-available {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #f8f8f8;
    }
    .land-name-content {
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            margin: 0;
            text-align: center;
        }
    }
    a {
        text-decoration: none !important;
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        max-width: 135px;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
    }
}
