



















































































































































































































































































#FooterView {
    font-family: 'Poppins';
    padding: 4rem 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        rgba(53, 63, 72, 0.6);
    @media (max-width: 991px) {
        padding: 3rem 0;
    }
    @media (max-width: 768px) {
        padding: 2rem 0;
    }
    .col-to-top {
        display: flex;
        justify-content: center;
        @media (max-width: 575px) {
            justify-content: flex-end;
        }
    }

    p.reserved {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #dcdcdc !important;
        margin: 0;
    }
    .linked-email {
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #dcdcdc;
    }

    button {
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        position: relative;
        cursor: pointer;
        background: linear-gradient(to right, #18d4e2, #a506f9);
        &:hover {
            background-size: 200%;
            animation: loop 1s infinite linear;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: scale-down;
        }
        @media (max-width: 991px) {
            width: 35px;
            height: 35px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
        }
    }
    .footer-logo {
        max-width: 169px;
        width: 100%;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
            max-width: 139px;
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }
    .social-link {
        width: 40px;
        height: 40px;
        @media (max-width: 768px) {
            width: 30px;
            height: 30px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .divide-line {
        height: 1px;
        width: 100%;
        background: #353f48;
        margin: 3rem 0;
        @media (max-width: 991px) {
            margin: 2rem 0;
        }
    }
    .footer-title {
        font-weight: 600;
        color: #f8f8f8;
    }
    .col1-p {
        color: #dcdcdc;
        font-size: clamp(14px, 1.5vw, 16px);
    }
    .mini-line {
        height: 0.5px;
        width: 35px;
        background: #dcdcdc;
    }
    .col_3a {
        font-weight: 400;
        color: #f0f0f0;
    }
    .col-line {
        width: 1px;
        background: #f0f0f0;
        height: 100%;
    }
    .col_3p {
        font-weight: 400;
        color: #dcdcdc;
    }
    .boder-left-footer {
        position: relative;
        .boder-left-footer-line {
            position: absolute;
            right: -35px;
            width: 1px;
            height: 20px;
            background: #f8f8f8;
        }
    }
}
