









































































































































































































































































































































































































































































































































































































































.hero-market {
    @media (max-width: 575px) and (min-width: 414px) {
        .col-xs-6 {
            flex: 0 0 50%;
        }
    }

    .content-market {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 1399px) {
            max-width: calc(100% - 10px);
        }
        .sort-hero {
            display: flex;
            justify-content: flex-start;
            margin: 0 0 15px auto;
            padding-right: 5px;
            position: relative;
            max-width: 275px;
            select {
                max-width: 275px;
                height: 40px;
                background: linear-gradient(to right, #00172a 0%, #00000b 100%);
                color: #fff;
                border-radius: 0;
                border: 1px solid #00ffff;
                option {
                    background: #00000b;
                }
            }
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        .left-filter {
            flex: 0 0 375px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            height: auto;
            padding-bottom: 25px;
            margin-right: 25px;
            .title {
                background: #00ffff;
                height: 60px;
                color: #00172a;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                font-weight: 600;
                text-transform: uppercase;
            }
            .search-id {
                padding: 0 15px;
                margin-top: 25px;
                position: relative;
                input.form-control {
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1px;
                    &::-webkit-input-placeholder {
                        color: #fff;
                        // opacity: 0.6;
                        text-align: left;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-style: italic;
                    }
                    height: 40px;
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    border-radius: 0;
                    padding-right: 50px;
                }
                button {
                    background: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    border: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 25px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .collapse-section {
                padding: 0 15px;
                margin-top: 15px;
                .button-collapse-search {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    .text {
                        height: 40px;
                        width: calc(100% - 40px);
                        border: 1px solid #00ffff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #fff;
                        padding: 0 0.75rem;
                        font-style: italic;
                    }
                    .icons {
                        width: 40px;
                        height: 40px;
                        background: #00ffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .price-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-price,
                .right-price {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .class-filter {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            .level-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-level,
                .right-level {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .rarity-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                button {
                    color: #fff;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    &.active {
                        filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
                        transform: scale(1.2);
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .skin-filter {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        width: 40px;
                        height: 40px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            .stat-filter {
                .range-slider {
                    width: 300px;
                    margin: auto;
                    text-align: center;
                    position: relative;
                    height: 6em;
                }

                .range-slider input[type='range'] {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                input[type='number'] {
                    border: 1px solid #ddd;
                    text-align: center;
                    font-size: 1.6em;
                    -moz-appearance: textfield;
                }

                input[type='number']::-webkit-outer-spin-button,
                input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                input[type='number']:invalid,
                input[type='number']:out-of-range {
                    border: 2px solid #ff6347;
                }

                input[type='range'] {
                    -webkit-appearance: none;
                    width: 100%;
                }

                input[type='range']:focus {
                    outline: none;
                }

                input[type='range']:focus::-webkit-slider-runnable-track {
                    background: #2497e3;
                }

                input[type='range']:focus::-ms-fill-lower {
                    background: #2497e3;
                }

                input[type='range']:focus::-ms-fill-upper {
                    background: #2497e3;
                }

                input[type='range']::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 5px;
                    cursor: pointer;
                    animate: 0.2s;
                    background: #2497e3;
                    border-radius: 1px;
                    box-shadow: none;
                    border: 0;
                }

                input[type='range']::-webkit-slider-thumb {
                    z-index: 2;
                    position: relative;
                    box-shadow: 0px 0px 0px #000;
                    border: 1px solid #2497e3;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    background: #a1d0ff;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -7px;
                }
            }
            @media (max-width: 1205px) {
                .title {
                    position: relative;
                }
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 375px;
                height: 100%;
                bottom: 0;
                z-index: 1037;
            }
        }
        .right-hero {
            flex: 1 1 auto;
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
    }
    .filter-active {
        position: fixed;
        left: 0;
        top: 175px;
        z-index: 1030;
        background: #00ffff;
        border-radius: 0;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        width: 150px;
        .icons {
            i,
            svg {
                font-size: 16px;
            }
            margin: 0 10px;
        }
        .name {
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }
    }

    @media (max-width: 991px) {
        .filter-active {
            top: 90px;
        }
    }
    .close {
        background: #00ffff;
        width: 35px;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        height: 35px;
        justify-content: center;
        align-items: center;
        line-height: 0;
        color: #000;
        opacity: 1;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        box-shadow: 5px 5px 5px black;
        display: none;
        @media (max-width: 1205px) {
            display: flex;
        }
    }
}

#hero-list-market {
    // display: flex;
    // flex-wrap: wrap;
    // @media (max-width: 767px) {
    //     justify-content: space-around;
    // }
    .hero {
        flex: 1;
        min-width: 170px;
        max-width: 250px;
        width: 100%;
        min-height: 300px;
        margin: 0 auto 30px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        // border: 1px solid #00ffff;
                        img {
                            width: 100%;
                            height: auto;
                            max-height: 190px;
                            object-fit: scale-down;
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-price {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    .left {
                        flex: 0 0 15px;
                        color: #ffff;
                        font-weight: 600;
                        font-size: 12px;
                    }
                    .right {
                        flex: 1 1 auto;
                        padding-left: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .hbg {
                            font-size: 15px;
                            font-weight: 900;
                            line-height: 120%;
                            font-family: 'Helvetica';
                            color: #00ffff;
                        }
                        .usd {
                            font-size: 0.8em;
                            font-weight: 900;
                            margin-left: 10px;
                            font-family: 'Helvetica';
                            color: #fff;
                        }
                    }
                    @media (max-width: 575px) {
                        flex-direction: column;
                        .left {
                            width: 100%;
                            flex: unset;
                            text-align: left;
                        }
                        .right {
                            width: 100%;
                            flex: unset;
                            text-align: right;
                        }
                    }
                }
            }
        }
        .right-hero {
            flex: 0 0 50px;
            max-width: 50px;
            padding-top: 30px;
            .stat-hero {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                img {
                    width: 35px;
                    height: auto;
                    max-height: 35px;
                    object-fit: scale-down;
                    margin-bottom: 10px;
                }
                .hero-level {
                    width: 35px;
                    height: 35px;
                    margin-bottom: 10px;
                    background: #000b1a;
                    color: #00ffff;
                    border: 1px solid #00ffff;
                    font-size: larger;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 900;
                    letter-spacing: 1px;
                }
                .hero-summon {
                    width: 35px;
                    height: 35px;
                    margin-bottom: 10px;
                    background: #000b1a;
                    color: #00ff60;
                    border: 1px solid #00ff60;
                    font-size: larger;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 900;
                    letter-spacing: 1px;
                    font-size: 15px;
                }
            }
        }
    }
}
