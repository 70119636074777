

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.inventory-tab-item {
    font-family: 'Poppins';
    .inventory-tab-listHero a {
        text-decoration: none !important;
    }
    button {
        outline: unset !important;
    }
    input::placeholder {
        color: #f8f8f8;
    }
    .custom-select {
        outline: unset;
        box-shadow: none;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        height: 26px;
        position: relative;
        z-index: 5;
        width: 100%;
        padding-right: 0;
    }
    .img-dropDown {
        position: absolute;
        right: 32px;
        z-index: 0;
    }
}
