
















































































































































































































.HeroBook.bg-main-container {
    background-image: url('~@/assets/images/background/global.svg'),
        linear-gradient(45deg, #00112c, #212323, #00112c);
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center center;
    // min-height: 100vh;
    // width: 100%;
}
main.content {
    min-height: calc(100vh - 90px);
    height: 100%;
    padding: 20px 0;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 991px) {
        min-height: calc(100vh - 60px);
    }
    @media (max-width: 575px) {
        min-height: calc(100vh);
        padding-bottom: 80px;
    }
}
