
















































































































.loginByEmail {
    width: 100%;
    max-width: 375px;
    .emailBTN {
        border: 1px solid #00ffff;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        color: #fff;
        font-weight: 500;
        height: 50px;
        width: 100%;
        max-width: 375px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 0;
        &:hover {
            color: #00ffff;
        }
        .icons {
            padding-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 17px;
                width: auto;
            }
        }
    }
    .box-login {
        min-height: 300px;
        background: {
            image: url(~@/assets/images/mockup/auth/box.png);
            size: 100% 100%;
        }
        padding: 15px;
        .form-group {
            margin: 1.75rem auto 1.75rem !important;
            text-align: right;
            a {
                color: #00ffff;
                font-size: 17px;
                font-weight: 500;
            }
            input {
                color: #fff;
                font-weight: 600;
                letter-spacing: 1px;
                &::-webkit-input-placeholder {
                    color: #fff;
                    opacity: 0.4;
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                    letter-spacing: 1px;
                }
                height: 45px;
                border: 1px solid #00ffff;
                background: linear-gradient(180deg, #18274a 0%, #001326 100%);
                border-radius: 0;
            }
            button {
                background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
                color: #002338;
                width: 150px;
                height: 40px;
                font-weight: 700;
                font-size: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
