






































































































































































































































































































































































































































































































































































































































#transform-hero {
    .transform__title {
        width: 100%;
        max-width: 725px;
        margin: 0 auto 50px;
        &-text {
            font-family: 'HHBI';
            font-size: 42px;
            color: #00ffff;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            height: fit-content;
            text-align: center;
            line-height: 100%;
        }
        img {
            width: 100%;
        }
        @media (max-width: 480px) {
            margin: 0 auto 15px;
            &-text {
                font-size: 30px;
            }
        }
    }
    .transform-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        &-container {
            max-width: 482px;
            position: relative;
            &-header {
                background: #00ffff2c;
                width: 286px;
                height: 46px;
                margin: 0 auto 10px;
                transform: skewX(-15deg);
                display: flex;
                align-items: center;
                justify-content: space-around;
                .text {
                    transform: skewX(15deg);
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 33px;
                    /* or 183% */

                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    letter-spacing: 0.75px;

                    /* cyan */

                    color: #00ffff;
                }
            }
            .background-transform {
                width: 100%;
                height: auto;
            }
            .box-received {
                &.hero {
                    img {
                        max-height: 190px;
                        max-width: 200px;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                    max-height: 250px;
                    max-width: 200px;
                    position: absolute;
                    top: 5%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 10;
                    margin: auto;
                    animation: infinite DownU 3s linear;
                    object-fit: scale-down;
                }
            }
            .picking {
                position: absolute;
                top: 15%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                button {
                    padding: 0;
                    background: none !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 84px;
                    width: 84px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .picked {
                // position: absolute;
                // left: 0;
                // right: 0;
                // bottom: 5%;
                margin-right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                transform: skewX(15deg);
                button {
                    padding: 0;
                    background: #0512207d !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .transform-button {
            &-content {
                position: relative;
                z-index: 10;
                font-family: 'hemiheadRg-BoldItalic';
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                /* or 144% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.75px;
                // color: #FE3F61;
                justify-content: center;
                img {
                    filter: invert(35%) sepia(131%) saturate(624%)
                        hue-rotate(147deg) brightness(100%) contrast(106%);
                }
                padding: 0 5px;
            }
            border: 1px solid #00ffff;
            background: transparent;
            box-shadow: unset;
            position: relative;
            width: 150px;
            height: 50px;
            border-radius: 0;
            color: #00ffff;
            &:after,
            &::before {
                z-index: 0;
                content: '';
                width: 5px;
                height: 5px;
                background: #00ffff;
            }

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
            }
            &::before {
                position: absolute;
                bottom: 1px;
                right: 1px;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: #000;

                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                    transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                }

                img {
                    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(15deg)
                        brightness(22%) contrast(102%);
                }
            }
        }
        @media (max-width: 767px) {
            flex-wrap: wrap;
            .picking_hero {
                flex: 1 1 45%;
                max-width: 45%;
                order: 1;
            }

            .received {
                flex: 1 1 45%;
                max-width: 45%;
                order: 2;
            }
            .transform-box-button {
                order: 3;
                flex: 0 0 100%;
                // display: none;
            }
            &-container {
                &-header {
                    width: 100%;
                }
            }
        }
        @media (max-width: 575px) {
            &-container {
                &-header {
                    .text {
                        font-size: 15px;
                    }
                }
                .box-received {
                    &.hero {
                        img {
                            max-height: 110px;
                            max-width: 120px;
                        }
                    }
                }
            }
        }
        @media (max-width: 413px) {
            flex-direction: column;
            &-container {
                margin-bottom: 25px;
                &-header {
                    .text {
                        font-size: 15px;
                    }
                }
                .box-received {
                    &.hero {
                        img {
                            max-height: 110px;
                            max-width: 120px;
                        }
                    }
                }
            }
            .picking_hero,
            .received {
                flex: unset;
                width: 100%;
                max-width: 235px;
            }
            .received {
                margin-bottom: 5px;
            }
        }
    }
    .list__nft {
        margin: auto;
        margin-top: 40px;
        padding: 20px;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            rgba(53, 63, 72, 0.95);
        border: 0.5px solid #888888;
        border-radius: 16px;
    }
    @keyframes DownU {
        0%,
        50%,
        100% {
            transform: translateY(0);
        }
        25%,
        75% {
            transform: translateY(25px);
        }
    }
    .transform__history {
        &__box__table {
            margin-top: 60px;
            &__heading {
                display: inline-flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                margin: 0 0 15px;

                &::before {
                    content: '';
                    width: 40px;
                    flex: 0 0 40px;
                    height: 14px;
                    background: #00ffff;
                    margin-right: 15px;
                    margin-top: 7px;
                }
                .text {
                    flex: 1 1 auto;
                    margin-right: 10px;
                    font-family: 'HHBI';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: #f0f0f0;
                    &.borderStyle {
                        padding-bottom: 25px;
                        border-bottom: 1px solid #fff;
                    }
                }
            }
            .table-transform {
                table {
                    th,
                    tr,
                    td {
                        border: 0;
                        min-width: 100px;
                        &:first-child {
                            min-width: unset;
                            padding: 0;
                            text-align: center;
                            vertical-align: middle;
                        }
                    }
                    th {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.01em;
                        color: #f0f0f0;
                        padding: 24px;
                        position: relative;
                        text-align: center !important;
                        &::after {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: 2px;
                            height: 16px;
                            content: '';
                            margin: auto;
                            background: #00ffff;
                        }
                        &:last-child {
                            &::after {
                                background: transparent;
                            }
                        }
                    }
                    tbody {
                        tr,
                        td {
                            font-family: 'Work Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            /* or 150% */
                            vertical-align: middle;
                            letter-spacing: 0.01em;

                            /* GrayScale/Input BG */
                            padding-top: 15px;
                            padding-bottom: 15px;
                            padding-left: 25px;
                            color: #f0f0f0;
                        }
                        tr:nth-of-type(odd) {
                            background-color: rgba(0, 255, 255, 0.15);
                            border-radius: 1px;
                        }
                        tr {
                            &:hover {
                                background-color: rgba(0, 255, 255, 0.05);
                                border-radius: 1px;
                                color: #f0f0f0;
                            }
                        }
                    }
                }
            }
        }
    }
}
#transform-received___BV_modal_outer_ {
    z-index: unset !important;
}
#transform-received {
    .box {
        max-width: 300px;
        width: 100%;
        min-height: 350px;
        margin: 5px auto;
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                // max-height: 210px;
                // object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            color: #fff;
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            color: #fff;
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
        .box-open {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 7px;
            width: 120px;
            height: 40px;
            padding: 5px;
            button {
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                border: 1.5px solid #fff;
                padding: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                box-shadow: 0px 0px 5px #00ffff;
                text-transform: uppercase;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 210px;
        }
    }
}
