



























































































































































































































































































































































































































.wallet-tab {
    padding: 0;
    .slide-tournament {
        max-width: 275px;
        margin-right: 15px;
        .slick-next {
            display: none;
            right: -13px;
        }
        .slick-prev {
            display: none !important;
        }
        @media (max-width: 575px) {
            width: 100%;
            max-width: 275px;
        }
    }

    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.6em, 4vw, 1.9em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 25px 0;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }

    .activity-list {
        margin-top: 20px;
    }
}
#deposit-ecosystem {
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
            background: rgba(0, 255, 255, 0.15) !important;
            border: none;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
