




























































































































































.inventory-box-item {
    max-width: 330px;
    a {
        text-decoration: none !important;
    }
    .inventory-item-image {
        img {
            height: 140px;
        }
    }
    .bede-btn {
        margin-top: 25px;
        height: 38px;
        width: 100%;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #00ffff;
            margin: 0;
        }
    }
    .hero-informations {
        span {
            font-weight: 500;
            font-size: 16px;
            color: #f8f8f8;
            padding-left: 5px;
        }
        img {
            height: 16px;
            width: auto;
        }
    }
    button {
        outline: unset !important;
    }
    .isSellingHero {
        margin: 25px 0 0;
        color: #fff;
        font-family: 'HHBI';
    }
}
