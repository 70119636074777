





















.auth-page {
    padding: calc(5vh + 20px) 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    .more-info {
        .text-more {
            color: #fff;
            font-weight: 500;
            font-size: 16px;
            margin: 15px auto;
            display: flex;
            flex-direction: column;
            text-align: center;
            a {
                color: #00ffff;
                font-weight: 500;
                display: block;
                @media (min-width: 375px) {
                    margin-left: 10px;
                }
            }
            @media (min-width: 480px) {
                flex-direction: row;
                margin-left: 10px;
            }
        }
        .breaking {
            margin: 30px auto;
            background: linear-gradient(00deg, #00ffa9 0%, #00b1ff 100%);
            height: 2px;
            width: 100%;
        }
    }
}
