




























































































































































































































































































































































































































































































































































































































































































































































































































































.account-page {
    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #00b3b3;
    }
}
@keyframes sildeToTop {
    from {
        top: 90px;
    }
    to {
        top: 0;
    }
}
@keyframes sildeToBottom {
    from {
        top: 0;
    }
    to {
        top: 90px;
    }
}

.slideToTop {
    animation: sildeToTop 1.2s alternate forwards;
    .user-box {
        height: 100vh;
    }
}
.sildeToBottom {
    animation: sildeToBottom 1.2s alternate forwards;
}
