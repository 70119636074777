




































































.paginate {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: relative;
    z-index: 20;
    .paginate {
        background: none;
        font-weight: 500;
        font-size: 14px;
        color: #dcdcdc;
        border: none;
        height: 35px;
        min-width: 35px;
        box-shadow: none;
        border: none;
        border: 0.5px solid rgba(53, 63, 72, 0);
        margin: 0 4px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:focus {
            outline: none;
            border: 0;
        }
        &.next {
            background: none;
            color: #fff;
        }
        &.prev {
            background: none;
            color: #fff;
        }
        &.active {
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            color: #00ffff;
            position: relative;
            background: linear-gradient(to bottom, #18d4e2, #a506f9);
            &::before {
                content: '';
                z-index: -1;
                position: absolute;
                top: 1px;
                right: 1px;
                bottom: 1px;
                left: 1px;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
            }
        }
    }
}
