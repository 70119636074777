.toast-container {
    .toast-success,
    .toast-error,
    .toast-warning {
        background: #ffffff !important;
        opacity: 1 !important;
        color: #222736 !important;
        font-weight: 500 !important;
        font-size: 1.2em !important;
        box-shadow: 0 0 15px -4px #000 !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        padding: 10px 15px 10px 10px !important;
    }
    .toast-success {
        border-left: 10px solid #51a351 !important;
    }
    .toast-error {
        border-left: 10px solid #bd362f !important;
    }
    .toast-warning {
        border-left: 10px solid #f89406 !important;
    }
}

