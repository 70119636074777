

















































































































































































































































































.hero-list-promotion {
    .box-picker {
        background-image: url(~@/assets/images/frame/frame-ligting-blue.png);
        background-size: 100% 100%;
        min-height: 75px;
        align-items: center;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
            align-items: center;
            padding: 5px 15px;
            height: 100%;
            min-height: 50px;
            display: flex;
            justify-content: center;
            min-width: 80px;
            font-family: 'HHBI';
            letter-spacing: 1px;
            font-size: 1.5em;
            font-weight: 900;
            color: #fff;
            line-height: 0;
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;
            cursor: pointer;
            &.active,
            &:hover {
                color: #00ffff;
                background-color: rgba(0, 33, 51, 0.6);
            }
        }
        .btn-toogle {
            width: 35px;
            height: 35px;
        }
    }
    .box-name {
        position: relative;
        margin: auto;
        width: 100%;
        min-height: 130px;
        text-align: center;
        justify-content: center;
        display: flex;
        .after {
            position: unset !important;
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            text-align: center;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 991px) {
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .VueCarousel-navigation-next {
        right: 3% !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        border: 0 !important;
        outline: none !important;
    }
    .VueCarousel-navigation-prev {
        left: 3% !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        border: 0 !important;
        outline: none !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
        // flex-basis: 100%!important;
        // width: 100%!important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    @media (max-width: 575px) {
        .VueCarousel-navigation-next {
            right: 5% !important;
        }
        .VueCarousel-navigation-prev {
            left: 5% !important;
        }
    }
    .hero {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 0 0 100%;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }

                .hero-action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .btn-choose-hero {
                        background: url('~@/assets/images/frame/hexagon-green.png');
                        background-size: 100% 100%;
                        cursor: pointer;
                        width: 150px;
                        outline: none;
                        height: 50px;
                        display: flex;
                        position: relative;
                        padding: 0;
                        outline: none;
                        font-family: 'HHBI';
                        font-size: 17px;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        outline: none !important;
                        box-shadow: none;
                        border: 0;
                        &.remove {
                            clip-path: polygon(
                                20% 0,
                                80% 0,
                                100% 50%,
                                80% 100%,
                                20% 100%,
                                0 50%
                            );
                            background: red;
                            width: 120px;
                            height: 45px;
                        }
                    }
                }
            }
        }
    }
}
