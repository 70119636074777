































































































































































































































































































































































































@import url('https://fonts.cdnfonts.com/css/hemi-head');
.upgrade {
    font-family: 'Poppins';
    .upgrade-main-title {
        font-weight: 600;
        font-size: 20px;
        color: #f8f8f8;
        padding-left: 36px;
    }
    .upgrade-header {
        border: 1px solid rgba(0, 255, 255, 0.3);
        border-radius: 4px;
        padding: 47px 70px;
        .upgrage-header-content {
            padding-left: 50px;
            .upgrade-title {
                font-weight: 600;
                font-size: 24px;
                color: #00ffff;
                padding-left: 33px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 40%;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    background: #00ffff;
                    border-radius: 50%;
                }
            }
            .upgrade-coin {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #f8f8f8;
                padding-left: 36px;
            }
            .upgrade-header-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: rgba(0, 255, 255, 0.2);
                height: 36px;
                border-radius: 54px;
                outline: unset !important;
                span {
                    padding-left: 16px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #00ffff;
                }
            }
            .upgrade-header-btn2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: rgba(85, 85, 85, 0.2);
                height: 36px;
                border-radius: 54px;
                outline: unset !important;
                span {
                    padding-left: 16px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #dcdcdc;
                }
            }
        }
    }
    .new-upgrade-list {
        .list-item-upgrade {
            @media (max-width: 1200px) and (min-width: 992px) {
                width: 25% !important;
            }
            @media (max-width: 992px) and (min-width: 768px) {
                width: 33.33333% !important;
            }
            @media (max-width: 768px) and (min-width: 576px) {
                width: 50% !important;
            }
            @media (max-width: 576px) {
                width: 100% !important;
            }
            max-width: 200px;
        }
        .new__upgrade__list--item {
            width: 20%;
            height: 250px;
            .hero-picking {
                padding: 10px;
                height: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(53, 70, 72, 0) 0%,
                    rgba(0, 240, 255, 0.3) 100%
                );
                button {
                    width: 100%;
                    height: 100%;
                    outline: unset;
                    box-shadow: none;
                    background: none;
                    border: none;
                    border-radius: 0;
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            .hero-picked {
                position: relative;
                height: 100%;
                padding: 10px;
                background: linear-gradient(
                    180deg,
                    rgba(53, 70, 72, 0) 0%,
                    rgba(0, 240, 255, 0.3) 100%
                );
                .remove-hero {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    width: 32px;
                    height: 32px;
                    button {
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .hero-info-avatar {
                    img {
                        width: 77%;
                        max-width: 130px;
                        min-height: 155px;
                        object-fit: scale-down;
                    }
                }
                .hero-info-name {
                    font-family: 'Hemi Head', sans-serif;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 18px;
                    color: #00ffff;
                    span {
                        color: #f8f8f8;
                        font-size: 22px;
                    }
                }
                .hero-info-id {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    color: #00ffff;
                }
                .hero-info-stat {
                    position: absolute;
                    width: 30px;
                    top: 35px;
                    right: 6%;
                    img {
                        width: 100%;
                        height: auto;
                        margin: 5px 0;
                    }
                    .hero-level {
                        width: 100%;
                        height: 30px;
                        margin: 5px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #00ffff;
                        color: #00ffff;
                        font-weight: 600;
                    }
                    .hero-summon {
                        border-color: #13ff60;
                        color: #13ff60;
                    }
                }
            }
        }
    }
    .upgrade-bottom {
        .upgrade-bottom-box {
            padding: 30px 90px;
            position: relative;
            background: linear-gradient(
                90deg,
                rgba(53, 63, 72, 0.2) 0%,
                rgba(53, 63, 72, 0) 100%
            );
            @media (max-width: 576px) {
                padding: 20px 30px;
            }
            img.left-arrow {
                position: absolute;
                top: 25%;
                left: 0;
            }
            .upgrade-item {
                img {
                    max-width: 70px;
                    height: auto;
                }
            }
            .upgrade-item-content {
                h4 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #f8f8f8;
                    span {
                        color: #00ffff;
                    }
                }
            }
            button.btn-upgrade {
                outline: unset;
                border: 0.5px solid #00ffff;
                box-shadow: none;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #00ffff;
                height: 56px;
                width: 100%;
                max-width: 270px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                }
            }
        }
    }
}
.box-balance-upgrade {
    margin-top: 100px;
    @media (max-width: 575px) {
        margin-top: 30px;
    }
}
.upgrade-title {
    &-text {
        font-family: hemiheadRg-BoldItalic;
        font-size: 42px;
        color: #00ffff;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
}
.frame-choose-hero {
    background-color: rgba(0, 68, 73, 0.8);
    border: 3px solid #004449;
    padding: 15px 2px;
}

.has-hero {
    .btn-choose-hero {
        display: none;
    }

    &:hover {
        .btn-choose-hero {
            display: flex;
        }
    }
}

.list-upgrade {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.hero {
    cursor: pointer;
    position: relative;
    background: linear-gradient(45deg, #022534, #02404e);
    border: 2px solid #00505c;
    width: 100%;
    min-width: 170px;
    max-width: 175px;
    padding: 15px 0;
    margin: 5px;
    flex: 1;
    min-height: 190px;
}

.btn-choose-hero {
    background: url('~@/assets/images/frame/hexagon-green.png');
    background-size: 100% 100%;
    cursor: pointer;
    width: 150px;
    outline: none;
    height: 50px;
    display: flex;
    position: relative;
    padding: 0;
    outline: none;
    font-family: 'HHBI';
    font-size: 17px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    &.remove {
        clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0 50%);
        background: red;
        width: 120px;
        height: 45px;
    }
}

.frame-rarity {
    position: relative;

    &-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-text {
        color: #00ffff;
        font-family: hemiheadRg-BoldItalic;
        font-size: 19px;
    }
}

.upgrade-button {
    background: url('~@/assets/images/frame/frame-upgrade-green2.png');
    background-size: 100% 100%;
    width: 220px;
    height: 110px;
    color: #00ffff;
    font-family: 'HHBI';
    font-size: 20px;
    outline: none !important;
    box-shadow: none;
    &:hover {
        transform: scale(1.01);
    }
    span {
        font-size: 17px;
    }
}
.frame-upgrade-bottom {
    & .content {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
