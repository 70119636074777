




























































































































































































































































































































































































































































































































































































































































































































































































































































.land-market {
    .content-market {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 1399px) {
            max-width: calc(100% - 10px);
        }
        .left-filter {
            flex: 0 0 375px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            height: auto;
            padding-bottom: 25px;
            margin-right: 25px;
            .title {
                background: #00ffff;
                height: 60px;
                color: #00172a;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                font-weight: 600;
                text-transform: uppercase;
            }
            .search-id {
                padding: 0 15px;
                margin-top: 25px;
                position: relative;
                input.form-control {
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1px;
                    &::-webkit-input-placeholder {
                        color: #fff;
                        // opacity: 0.6;
                        text-align: left;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-style: italic;
                    }
                    height: 40px;
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    border-radius: 0;
                    padding-right: 50px;
                }
                button {
                    background: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    border: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 25px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .collapse-section {
                padding: 0 15px;
                margin-top: 15px;
                .button-collapse-search {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    .text {
                        height: 40px;
                        width: calc(100% - 40px);
                        border: 1px solid #00ffff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #fff;
                        padding: 0 0.75rem;
                        font-style: italic;
                    }
                    .icons {
                        width: 40px;
                        height: 40px;
                        background: #00ffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .price-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-price,
                .right-price {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .class-filter {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            .level-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-level,
                .right-level {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .rarity-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                button {
                    color: #fff;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    &.active {
                        filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
                        transform: scale(1.2);
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .skin-filter {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        width: 40px;
                        height: 40px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 100%;
                            height: auto;
                            max-height: 40px;
                            object-fit: scale-down;
                        }
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            .stat-filter {
                .range-slider {
                    width: 300px;
                    margin: auto;
                    text-align: center;
                    position: relative;
                    height: 6em;
                }

                .range-slider input[type='range'] {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                input[type='number'] {
                    border: 1px solid #ddd;
                    text-align: center;
                    font-size: 1.6em;
                    -moz-appearance: textfield;
                }

                input[type='number']::-webkit-outer-spin-button,
                input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                input[type='number']:invalid,
                input[type='number']:out-of-range {
                    border: 2px solid #ff6347;
                }

                input[type='range'] {
                    -webkit-appearance: none;
                    width: 100%;
                }

                input[type='range']:focus {
                    outline: none;
                }

                input[type='range']:focus::-webkit-slider-runnable-track {
                    background: #2497e3;
                }

                input[type='range']:focus::-ms-fill-lower {
                    background: #2497e3;
                }

                input[type='range']:focus::-ms-fill-upper {
                    background: #2497e3;
                }

                input[type='range']::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 5px;
                    cursor: pointer;
                    animate: 0.2s;
                    background: #2497e3;
                    border-radius: 1px;
                    box-shadow: none;
                    border: 0;
                }

                input[type='range']::-webkit-slider-thumb {
                    z-index: 2;
                    position: relative;
                    box-shadow: 0px 0px 0px #000;
                    border: 1px solid #2497e3;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    background: #a1d0ff;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -7px;
                }
            }
            @media (max-width: 1205px) {
                .title {
                    position: relative;
                }
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 375px;
                height: 100%;
                bottom: 0;
                z-index: 1037;
            }
        }
        .right-hero {
            flex: 1 1 auto;
            .sort-box {
                display: flex;
                justify-content: flex-end;
                margin: 0 0 15px auto;
                padding-right: 5px;
                position: relative;
                max-width: 475px;
                .btn-box-buy-now {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    min-width: 120px;
                    max-width: 150px;
                    width: max-content;
                    height: 40px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 5px 2px #00b1ff;
                    border: 0;
                    @media (max-width: 576px) {
                        font-size: 15px;
                    }
                }
                select {
                    max-width: 275px;
                    height: 40px;
                    background: linear-gradient(
                        to right,
                        #00172a 0%,
                        #00000b 100%
                    );
                    color: #fff;
                    border-radius: 0;
                    border: 1px solid #00ffff;
                    option {
                        background: #00000b;
                    }
                }
                img {
                    height: 15px;
                    width: auto;
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
    }
    .filter-active {
        position: fixed;
        left: 0;
        top: 175px;
        z-index: 1030;
        background: #00ffff;
        border-radius: 0;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        width: 150px;
        .icons {
            i,
            svg {
                font-size: 16px;
            }
            margin: 0 10px;
        }
        .name {
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }
    }
    @media (max-width: 991px) {
        .filter-active {
            top: 90px;
        }
    }
    .close {
        background: #00ffff;
        width: 35px;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        height: 35px;
        justify-content: center;
        align-items: center;
        line-height: 0;
        color: #000;
        opacity: 1;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        box-shadow: 5px 5px 5px black;
        display: none;
        @media (max-width: 1205px) {
            display: flex;
        }
    }
}
.land-list {
    .land-item {
        max-width: 275px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 575px) {
            max-width: 100%;
        }
        .item {
            width: 100%;
            max-width: 250px;
            margin-bottom: 30px;
            @media (max-width: 575px) {
                max-width: 175px;
                margin-bottom: 30px;
            }
            .id-land {
                color: #00ffff;
                font-size: 1.2em;
                font-weight: 800;
                margin-bottom: 0px;
                font-family: 'HHBI';
                line-height: 1.2em;
            }
            .land-img {
                border: 1px solid #00ff00;
                margin-bottom: 10px;
                box-shadow: 0px 0px 7px #00ff00;
                img {
                    width: 100%;
                    height: auto;
                    max-height: 160px;
                    object-fit: cover;
                }
            }
            .land-name {
                width: 100%;
                background-color: #00ff00;
                font-size: 20px;
                padding: 5px;
                line-height: 100%;
                color: #000000;
                text-decoration: none;
                font-family: 'HHBI';
                @media (max-width: 575px) {
                    font-size: 18px;
                }
                .land-name-content {
                    display: flex;
                    width: max-content;
                    height: max-content;
                }
            }
            .land-reward {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                color: #00ff00;
                text-shadow: 0px 0px 5px #00ff00;
                font-size: 16px;
                line-height: 100%;
                @media (max-width: 575px) {
                    font-size: 12px;
                }
                span {
                    font-size: 17px;
                    color: #ffffff;
                    @media (max-width: 575px) {
                        font-size: 15px;
                    }
                }
            }
            .land-process {
                width: 100%;
                text-align: center;
                margin-bottom: 5px;
                .loading-progress {
                    height: 30px;
                    width: 100%;
                    position: relative;
                    .progress-bg {
                        background: #33004d;
                        border: 1px solid #00ff00;
                        box-sizing: border-box;
                        height: 30px;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        .active-bg {
                            content: '';
                            background: #00ff0085;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            bottom: 0;
                            margin: auto;
                            width: 10%;
                            height: 30px;
                        }
                    }
                    .progress-active {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        right: 0px;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #f4f4f4;
                        font-size: 12px;
                        @media (max-width: 991px) {
                            font-size: 12px;
                        }
                        @media (max-width: 575px) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .land-btn {
                display: flex;
                justify-content: space-between;
                button {
                    background: none;
                    border: none;
                    font-size: 13px;
                    padding: 10px 20px;
                    font-style: italic;
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-family: 'HHBI';
                    @media (max-width: 575px) {
                        padding: 5px 10px;
                    }
                    &.btn-sell {
                        background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &.btn-price {
                        background: url('~@/assets/images/mockup/account/bgBtn-blue.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                        color: #000;
                        font-weight: 800;
                    }
                    &:nth-child(2) {
                        background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
        }
        .land-price {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .left {
                flex: 0 0 25%;
                color: #ffff;
                font-weight: 600;
            }
            .right {
                flex: 0 0 75%;
                padding-left: 10px;

                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .hbg {
                    font-size: 1.15em;
                    font-weight: 900;
                    line-height: 120%;
                    font-family: 'Helvetica';
                    color: #00ffff;
                }
                .usd {
                    font-size: 0.8em;
                    font-weight: 900;
                    margin-left: 10px;
                    font-family: 'Helvetica';
                    color: #fff;
                }
            }
        }
        .action {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                margin: 5px;
                &.buy {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.cancel {
                    background: linear-gradient(
                        90deg,
                        #762525 0%,
                        #f92323 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #fff;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid;
                    &:hover {
                        color: #fff !important;
                        border: 2px solid #fff !important;
                    }
                }
            }
        }
    }
}
#modal-received {
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 600px;
        }
    }

    .modal-content {
        background-image: url(~@/assets/images/mockup/market/bg-modal-market-congrat.png);
        background-size: 110% 110%;
        background-position: center center;
    }

    .modal-body {
        background: none;
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;

        .hero-name {
            font-size: 1.5em;
            text-shadow: 0px 0px 20px #00ffff;

            span {
                font-size: 2em;
                color: white;
            }
        }

        .box-img-modifer {
            width: 250px;
            margin: 0 auto;
            max-height: 250px;
            overflow: hidden;
        }

        .x1 {
            width: 75px;
            height: 50px;
            margin: 15px auto 30px;
            border: 1px solid #00ffff;

            font-size: 43px;
            text-shadow: 0px 0px 10px #00ffff;
            line-height: 50px;
            text-align: center;
        }

        .btn-confirm {
            background-color: #00ffff;
        }
    }
}
.text-normal {
    font-family: 'helvetica-neue-regular';
    color: white;
    font-size: 28px;
    text-align: center;
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
