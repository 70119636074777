



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.inventory-box-item {
    button.bede-btn {
        max-width: 136px;
        height: 38px;
        border-radius: 105px;
        outline: none !important;
        &::before {
            border-radius: 105px;
        }
    }
    .land-name-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #f8f8f8;
        padding-left: 25px;
    }
    .idBox-selling {
        font-weight: 500;
        color: #00ffff;
        padding-bottom: 4px;
        margin-right: -10px;
    }
}
