






































































































$box-color: linear-gradient(0deg, #02ffff -60%, #001326 100%);
.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
.shop-item {
    .section {
        padding: 20px 0;
        .page-name {
            position: relative;
            .before {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1px;
                font-family: 'HHBI';
                line-height: 120%;
                text-transform: uppercase;
                -webkit-text-stroke-color: #4effff;
                opacity: 0.6;
                font-size: clamp(70px, 5.5vw, 120px);
            }

            .after {
                font-family: 'HHBI';
                font-size: clamp(40px, 3vw, 65px);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
            @media (max-width: 575px) {
                .before {
                    font-size: clamp(55px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
            @media (max-width: 424px) {
                .before {
                    font-size: clamp(45px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    font-size: clamp(25px, 3vw, 65px);
                }
            }
            @media (max-width: 374px) {
                .before {
                    font-size: clamp(40px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    bottom: -10px;
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
        }
    }
    .section-1 {
        text-align: center;
        padding: 25px 15px 30px;
        img {
            width: 100%;
            max-width: 740px;
            height: auto;
        }
        @media (max-width: 480px) {
            widows: 100%;
            max-width: unset;
            padding: 15px 0px 15px;
        }
    }
    .section-2 {
        padding: 30px 15px;
        .sub-text {
            color: #4effff;
            font-size: clamp(1em, 4vw, 1.5em);
            max-width: 991px;
            line-height: 120%;
            margin: auto;
            text-align: center;
            font-family: 'HHBI';
        }
        @media (max-width: 480px) {
            padding: 15px 15px;
            .sub-text {
                font-size: 1.1em;
            }
        }
    }
    .section-3 {
        margin-top: -40px;
        @media (max-width: 1300px) {
            width: 100%;
            max-width: 100%;
        }
    }

    .title-items {
        color: #fff;
        font-family: 'Helvetica';
        font-size: clamp(1.6em, 4vw, 1.9em);
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 25px 36px 0;
        font-weight: 900;
        padding: 0 0 10px 10px;
        &::after {
            content: '';
            bottom: 0;
            left: 14px;
            width: 100%;
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
        @media (max-width: 991px) {
            margin: 25px auto 0;
        }
    }
    .shopInGame-header {
        min-height: 175px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 100px 0;
        &-left,
        &-right {
            flex: 1 1 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            max-width: 140px;
        }
        &-left {
            &:before {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-right: 10px;
            }
            &:after {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
        }
        &-right {
            &::before {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
            &::after {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-left: 10px;
            }
        }
        &-center {
            flex: 1 1 calc(100% - 280px);
            margin: 0 15px;
            max-width: 650px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 1 200px;
                max-width: 200px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &-text {
                max-width: 400px;
                &-title {
                    font-size: 32px;
                    color: #fcfcfc;
                    line-height: 36px;
                    font-weight: 700;
                }
                &-content {
                    margin-top: 20px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 29px;
                    color: #888888;
                }
            }
        }
    }
}
