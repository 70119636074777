









































































































.box-hero-inventory {
    .hero-receiver-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .box-name {
            position: relative;
            margin: auto;
            .after {
                font-size: clamp(60px, 4vw, 80px);
                font-family: 'HHBI';
                text-align: center;
                text-transform: uppercase;
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
            @media (max-width: 991px) {
                .after {
                    font-size: clamp(40px, 3vw, 75px);
                }
            }
            @media (max-width: 575px) {
                .after {
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
            @media (max-width: 424px) {
                .after {
                    font-size: clamp(25px, 3vw, 65px);
                }
            }
            @media (max-width: 374px) {
                .after {
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
        }
        .hero {
            flex: 1;
            min-width: 200px;
            max-width: 225px;
            width: 100%;
            margin: 0 15px 5px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .left-hero {
                flex: 1 1 auto;
                .top-hero {
                    .id-hero {
                        color: #00ffff;
                        font-size: 1.2em;
                        font-weight: 800;
                        margin-bottom: 0px;
                        font-family: 'HHBI';
                        line-height: 1.2em;
                    }
                    .hero-img {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;

                        .avatar-hero {
                            flex: 1 1 auto;
                            border: 1px solid #00ffff;
                            max-width: calc(100% - 50px);
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .right-hero {
                            flex: 0 0 50px;
                            max-width: 50px;
                            .stat-hero {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                .hero-level {
                                    width: 35px;
                                    height: 35px;
                                    margin-bottom: 10px;
                                    background: #000b1a;
                                    color: #00ffff;
                                    border: 1px solid #00ffff;
                                    font-size: larger;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    font-weight: 900;
                                    letter-spacing: 1px;
                                }
                                .hero-summon {
                                    width: 35px;
                                    height: 35px;
                                    margin-bottom: 10px;
                                    background: #000b1a;
                                    color: #00ff60;
                                    border: 1px solid #00ff60;
                                    font-size: larger;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    font-weight: 900;
                                    letter-spacing: 1px;
                                    font-size: 12px;
                                }
                                img {
                                    width: 35px;
                                    height: auto;
                                    max-height: 35px;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
                .bottom-hero {
                    .hero-name {
                        font-size: 1.1em;
                        text-align: center;
                        margin: 10px auto;
                        font-family: 'HHBI';
                        text-transform: uppercase;
                        color: #00ffff;
                        line-height: 130%;
                        text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                        span {
                            font-size: 1.5em;
                            color: #fff;
                        }
                    }
                    .hero-exp {
                        position: relative;
                        width: 100%;
                        border: 1px solid #00ffff;
                        height: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 5px auto 15px;
                        background: rgba(0, 255, 255, 0.2);
                        .text {
                            position: relative;
                            z-index: 10;
                            font-weight: 500;
                            font-size: 12px;
                            font-style: italic;
                            span {
                                font-weight: 700;
                                font-family: 'Helvetica';
                                font-size: 15px;
                            }
                        }
                        .current {
                            background: linear-gradient(
                                90deg,
                                #00ffa9 0%,
                                #00b1ff 100%
                            );
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 0;
                        }
                    }
                    .hero-action {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        button {
                            width: auto;
                            padding: 5px;
                            min-width: 100px;
                            height: 40px;
                            border-radius: 5px;
                            border: 0;
                            color: #ffff;
                            &.sell {
                                background: {
                                    image: url('~@/assets/images/mockup/account/sell.png');
                                    repeat: no-repeat;
                                    size: 100% 100%;
                                }
                            }
                            &.transfer {
                                background: {
                                    image: url('~@/assets/images/mockup/account/transfer.png');
                                    repeat: no-repeat;
                                    size: 100% 100%;
                                }
                            }
                            &.price {
                                background: {
                                    image: url('~@/assets/images/mockup/account/price.png');
                                    repeat: no-repeat;
                                    size: 100% 100%;
                                }
                            }
                            &.cancel {
                                background: {
                                    image: url('~@/assets/images/mockup/account/cancel.png');
                                    repeat: no-repeat;
                                    size: 100% 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
