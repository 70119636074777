

























































































































































































































































































































































.hero__burn {
    border: 1px solid #555555;
    border-radius: 4px;
    height: auto;
    width: 265px;
    max-height: 384px;
    padding: 20px 25px;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        &-title {
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #00ffff;
        }
        &-clear {
            flex: 0 0 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 33px;
            button {
                padding: 0;
                background: none !important;
                box-shadow: none !important;
                outline: none !important;
                border: 0 !important;
                height: 33px;
                width: 33px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &__body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &-hero {
            position: relative;
            width: 128px;
            height: 136px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 10;
            canvas {
                position: absolute;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                max-width: 128px;
                max-height: 128px;
                object-fit: scale-down;
            }
        }
        &-stat {
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f4857;
            border-radius: 8px;
            padding: 12px 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex: 0 0 33px;
            .hero-level {
                width: 100%;
                background: #000b1a;
                color: #00ffff;
                border: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 11px;

                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 10px;
                line-height: 20px;

                color: #00ffff;
            }

            img {
                width: 100%;
                height: auto;
                max-height: 25px;
                margin-bottom: 11px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__footer {
        margin: 30px 0 25px;
        &__box {
            display: flex;
            justify-content: space-around;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            &-title {
                flex: 1 1 auto;
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                /* or 200% */

                display: flex;
                align-items: center;
                text-align: right;
                justify-content: flex-start;
                letter-spacing: 0.01em;

                color: #00ffff;
            }
            &-value {
                flex: 1 1 auto;
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                /* or 200% */

                display: flex;
                align-items: center;
                text-align: right;
                justify-content: flex-end;
                letter-spacing: 0.01em;

                /* GrayScale/Line */

                color: #dcdcdc;
            }
        }
    }
    &__burn {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 15px;
        &-button {
            &-content {
                position: relative;
                z-index: 10;
                font-family: 'hemiheadRg-BoldItalic';
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                /* or 144% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.75px;
                // color: #FE3F61;
                justify-content: center;
            }
            border: 1px solid #fe3f61;
            background: #00000b;
            box-shadow: unset;
            position: relative;
            width: 160px;
            height: 38px;
            border-radius: 0;
            color: #fe3f61;
            &:after,
            &::before {
                z-index: 0;
                content: '';
                width: 5px;
                height: 5px;
                background: #fe3f61;
            }

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
            }
            &::before {
                position: absolute;
                bottom: 1px;
                right: 1px;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: #000;
                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                    transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                }
            }
        }
    }
}
