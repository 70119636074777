



























































































































































































































.market-public {
    margin-top: 70px;
    a {
        text-decoration: none;
    }

    .item-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        background: rgba(53, 63, 72, 0.4);
        padding: 5px 15px;

        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        &-name {
            flex: 1 1 auto;
            max-width: 275px;
            min-width: 275px;
            @media (max-width: 767px) {
                min-width: 175px;
                max-width: 180px;
            }
        }
        &-quantity {
            flex: 0 0 100px;
            text-align: center;
            @media (max-width: 575px) {
                display: none;
            }
        }
        &-price {
            text-align: center;
            flex: 1 1 min(200px, 500px);
        }
        &-newly {
            text-align: center;
            flex: 0 0 110px;
            @media (max-width: 480px) {
                display: none;
            }
        }
    }
    .market-sidebar {
        min-height: 400px;
        width: 100%;
        background: rgba(53, 63, 72, 0.2);
        padding: 45px 20px;
        &\:title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #dcdcdc;
            padding-left: 15px;
            margin-bottom: 15px;
        }
        &-game {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            background: rgba(53, 63, 72, 0.3);
            border-radius: 4px;
            margin-bottom: 5px;
            &\:avatar {
                width: 50px;
                height: 50px;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                border: 0.5px solid #dcdcdc;
                border-radius: 100px;
                padding: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &\:name {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: #f0f0f0;
                flex: 1 1 auto;
                margin-left: 50px;
            }
        }
        &-advance {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;

            margin-bottom: 50px;
            outline: none !important;
            box-shadow: none !important;
            background: transparent !important;
            border: 0;
            border-bottom: 0.5px solid #dcdcdc;
            border-radius: 0;
            &\:text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #888888;
            }
            &\:icon {
                transform: rotate(-90deg);
                height: 18px;
                width: 20px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }
        &-search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;

            margin-bottom: 50px;
            outline: none !important;
            box-shadow: none !important;
            background: transparent !important;
            border: 0;
            border-bottom: 0.5px solid #dcdcdc;
            border-radius: 0;
            &\:input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #888888;
                background: transparent;
                outline: none !important;
                box-shadow: none !important;
                background: transparent !important;
                border: 0;
                border-radius: 0;
                &:not(:placeholder-shown),
                &:focus {
                    color: #fcfcfc;
                }
            }
            &\:icon {
                transform: rotate(-90deg);
                height: 18px;
                width: 20px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }
        .market-sidebar-title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #f8f8f8;
            span {
                color: #00ffff;
            }
        }
        @media (max-width: 991px) {
            display: none;
        }
    }
}
