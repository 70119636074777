




















































































































































































































































































































































































































































































































































































































































































$top-bar-height: 90px;
$top-bar-height-mb: 60px;
$side-bar-width: 320px;
$top-bar-color: linear-gradient(180deg, #00172a 0%, #00000b 100%);
$top-text-color: #ffffff;
$top-text-active: '';
.desktop,
.mobile {
    display: none;
}
@media (min-width: 992px) {
    .desktop {
        display: block;
    }
}
@media (max-width: 991px) {
    .mobile {
        display: block;
    }
}
#top-bar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: $top-bar-height;
    background: linear-gradient(180deg, #00172ad1 0%, #00000b94 100%);

    width: 100%;
    box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.1);
    z-index: 1030;
    backdrop-filter: blur(19px);
    @media (max-width: 991px) {
        height: $top-bar-height-mb;
    }
    @media (max-width: 575px) {
        position: fixed;
        z-index: 1041;
        bottom: 0;
        top: auto;
    }
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        height: 45px;
        text-decoration: none;
        cursor: pointer;
        margin: auto 0 auto 15px;
        background: cyan;
        clip-path: polygon(
            25% 0%,
            75% 0%,
            100% 50%,
            75% 100%,
            25% 100%,
            0% 50%
        );

        .icons {
            width: 35px;
            height: 35px;
            margin: auto 5px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .text {
            color: black;
            font-weight: 900;
            font-size: 20px;
            text-transform: capitalize;
        }
        @media screen and (max-width: 767px) {
            display: none !important;
        }
    }
}
.desktop {
    &.header-container {
        padding: 0 2vw;
        @media (max-width: 1399px) {
            max-width: 100%;
        }
        #menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height;
            font-family: 'HHBI';
            .nav-logo {
                width: fit-content;
                max-width: 250px;
                min-width: 120px;
                height: 100%;
                padding: 5px;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            .nav-menu {
                flex-grow: 1;
                flex: auto;
                height: 100%;
                display: flex;
                justify-content: center;
                padding: 0 0vw;
                overflow: hidden;
                .menu-item {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    flex: 1 1 auto;
                    max-width: 210px;
                    text-decoration: none;
                    cursor: pointer;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        min-width: 35px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                    &.active {
                        // clip-path: polygon(25% 0%, 100% 0%, 70% 100%, 0 100%);
                        background: linear-gradient(
                            0deg,
                            #02ffff -60%,
                            #001326 100%
                        );
                        &.dashboard {
                            // min-width: 250px;
                        }
                        .icons {
                            margin-left: -5px;
                        }
                    }
                }
            }
            .nav-user {
                width: fit-content;
                max-width: 550px;
                min-width: 120px;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
                .balances {
                    width: 250px;
                    background: url('~@/assets/images/button/balance.png');
                    background-size: 100% 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        color: #fff;
                        font-size: 17px;
                    }
                    .buy-btn {
                        flex: 0 0 70px;
                        background: url('~@/assets/images/button/buy.png');
                        background-size: 100% 100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 20px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            @media (max-width: 1500px) {
                .nav-user {
                    .account {
                        .icons {
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                        .text {
                            font-size: 18px;
                        }
                    }
                    .balances {
                        width: fit-content;

                        .amount {
                            padding: 0 5px;
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1280px) {
                .nav-menu {
                    .menu-item {
                        flex-direction: column;
                        min-width: 85px;
                        .icons {
                            margin: 0;
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                        .text {
                            font-size: 17px;
                        }
                    }
                }
                .nav-user {
                    .account {
                        flex-direction: column;
                        min-width: 85px;
                        .icons {
                            margin: 0;
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                        .text {
                            font-size: 17px;
                        }
                    }
                    .balances {
                        width: fit-content;

                        .amount {
                            padding: 0 5px;
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1200px) {
                .nav-logo {
                    display: flex;
                    align-items: center;
                    min-width: unset;
                    max-width: unset;
                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .nav-menu {
                    padding: 0 20px;
                    .menu-item {
                        max-width: 175px;
                        .text {
                            font-size: 16px;
                        }
                    }
                }
                .nav-user {
                    width: auto;
                    min-width: unset;
                    .balances {
                        width: auto;
                        .amount {
                            font-size: 15px;
                            padding: 0 7px;
                        }
                        .buy-btn {
                        }
                    }
                    .account {
                        .text {
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1560px) and (min-width: 992px) {
                .nav-menu {
                    padding: 0 20px 0 5px;
                    .menu-item {
                        // max-width: 175px;
                        flex: 1 1 auto;
                        .icons {
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                    }
                }
            }
            @media (max-width: 1200px) and (min-width: 992px) {
                .nav-menu {
                    .menu-item {
                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.mobile {
    &.header-container {
        @media (max-width: 1399px) {
            max-width: 100%;
            padding: 0;
        }
        #nav-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height-mb;
            font-family: 'HHBI';
            .nav-logo {
                display: flex;
                align-items: center;
                min-width: unset;
                max-width: unset;
                img {
                    width: 90px;
                    height: auto;
                }
            }
            .login {
                display: flex !important;
                clip-path: unset;
            }
            .menu-item {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: unset;
                flex: 1 1 auto;
                text-decoration: none;
                flex-direction: column;
                cursor: pointer;
                height: 100%;
                .icons {
                    width: 25px;
                    height: 25px;
                    margin: 0 auto 5px !important;
                    min-width: 25px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 11px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
                &.active {
                    // clip-path: polygon(25% 0%, 100% 0%, 70% 100%, 0 100%);
                    background: linear-gradient(
                        0deg,
                        #02ffff -60%,
                        #001326 100%
                    );
                    &.dashboard {
                        // min-width: 250px;
                    }
                    .icons {
                        margin-left: -5px;
                    }
                }
            }
            .nav-user {
                width: auto;
                min-width: unset;
                max-width: 550px;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                &.topbar-nv {
                    @media (max-width: 380px) {
                        display: none !important;
                    }
                    @media screen and (max-width: 767px) {
                        .account {
                            display: none !important;
                        }
                    }
                }
                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 15px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
                .balances {
                    width: auto;
                    background: url('~@/assets/images/button/balance.png');
                    background-size: 100% 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        color: #fff;
                        font-size: 15px;
                        padding: 0 7px;
                    }
                    .buy-btn {
                        flex: 0 0 70px;
                        background: url('~@/assets/images/button/buy.png');
                        background-size: 100% 100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 15px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .toggle-menu-mobile {
            button {
                background: #06284e;
                height: 45px;
                width: 45px;
                border: 0;
                padding: 0;
                img {
                    height: 30px;
                    width: auto;
                }
            }
        }
    }
    .sidebar-menu {
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: $side-bar-width;
        background: #00000b;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2300;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 10px;
        height: 100%;
        min-height: calc(100vh - 60px);
        @media (max-width: 575px) {
            top: auto;
            bottom: 60px;
            width: 100%;
            height: calc(100vh - 60px);
        }
        .nav-menu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .menu-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 50px;
                text-decoration: none;
                padding: 0 15px;
                margin: 5px auto;
                cursor: pointer;
                .icons {
                    width: 30px;
                    height: 30px;
                    margin: auto 10px;
                    min-width: 30px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 17px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
                &.active {
                    background: url('~@/assets/images/button/active-menu.jpg');
                }
            }
        }
        .break-point {
            background: #00ffffa4;
            height: 3px;
            width: 100%;
            margin: 20px 0;
            &.break-point-2 {
                height: 1px;
            }
        }
        .break-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
                color: #00ffff;
                font-size: 19px !important;
                flex: 0 0 max-content;
            }
            &::before,
            &::after {
                height: 1px;
                display: flex;
                flex: 1 1 auto;
                margin-left: 5px;
                background: #00ffff;
                content: '';
            }
            &::before {
                margin-right: 5px;
            }
        }
        .nav-user {
            width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: column;
            .balances,
            .account {
                width: 100%;
                margin: 10px auto !important;
                &.isLeft {
                    margin: 10px 0 10px 25px !important;
                    width: max-content;
                }
            }
            .account {
                justify-content: flex-start !important;
                padding: 0 15px;
                .icons {
                    margin: 0 10px !important;
                    padding: 3px;
                    img {
                        height: auto !important;
                    }
                }
            }
        }
        @media (max-width: 425px) {
            width: 100%;
        }
        .login-2 {
            margin: 15px auto 0 !important;
            width: 100%;
            max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            margin: auto 0 auto 15px;
            background: cyan;
            clip-path: polygon(
                25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%
            );

            .icons {
                width: 35px;
                height: 35px;
                margin: auto 5px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                color: black;
                font-weight: 900;
                font-size: 20px;
                text-transform: capitalize;
            }
            @media (min-width: 768px) {
                display: none;
            }
        }
        .list-sidebar-item {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            width: 100%;
            margin: 10px auto;

            .sidebar-item {
                flex: 1 1 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px 2px;
                max-width: 90px;
                margin: 5px;
                min-height: 60px;
                justify-content: space-between;
                &.active {
                    border: 1px solid #00ffff;
                    border-radius: 3px;
                }
                .icons {
                    width: 35px;
                    height: auto;
                    max-height: 35px;
                    img {
                        width: 100%;
                        object-fit: scale-down;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 11px;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
        @media (max-width: 575px) {
            position: fixed;
            bottom: 60px;
            top: 0;
            height: auto;
        }
    }
}
.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
}
.more-platform {
    @media (max-width: 576px) {
        &.pc {
            display: none;
        }
    }
    button {
        background-color: transparent;
        border: 1px solid #00ffff;
        padding: 0;
        width: 45px;
        height: 45px;
        margin: 0 15px;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.detail-more {
    position: fixed;
    top: 90px;
    left: 10px;
    width: 100%;
    max-width: 768px;
    min-height: 190px;
    z-index: 1050;
    background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
    border: 1px solid #00ffff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item-more {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        width: calc((100% / 3) - 10px);
        margin: 5px;
        height: 75px;
        border-radius: 5px;
        border: 1px solid #00ffff57;
        &:hover {
            background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
        }
        .icon {
            flex: 0 0 50px;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            padding: 5px;

            img {
                width: 100%;
                height: auto;
                object-fit: scale-down;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 5px;
            text-decoration: none !important;
            .title {
                color: #fff;
                font-family: 'HHBI';
                font-size: 16px;
                font-weight: 600;
            }
            .description {
                text-decoration: none !important;
                font-size: 12px;
                color: #fff;
                line-height: 120%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
    @media (max-width: 991px) {
        top: 65px;
        max-width: calc(100% - 20px);
    }
    @media (max-width: 575px) {
        .item-more {
            width: calc((100% / 2) - 10px);
        }
    }
    @media (max-width: 425px) {
        .item-more {
            width: 100%;
        }
    }
}
