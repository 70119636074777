






































































































































































































































#info-promo-box {
    .text-confirm {
        font-size: 1.2em;
        margin: 0px;
        p {
            margin-bottom: 5px;
        }
    }
}
.page-promotion {
    &-header {
        min-height: 175px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left,
        &-right {
            flex: 1 1 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            max-width: 140px;
        }
        &-left {
            &:before {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-right: 10px;
            }
            &:after {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
        }
        &-right {
            &::before {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
            &::after {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-left: 10px;
            }
        }
        &-center {
            flex: 1 1 calc(100% - 280px);
            margin: 0 15px;
            max-width: 650px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 1 200px;
                max-width: 200px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &-text {
                max-width: 400px;
                &-title {
                    font-size: 32px;
                    color: #fcfcfc;
                    line-height: 36px;
                    font-weight: 700;
                }
                &-content {
                    margin-top: 20px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 29px;
                    color: #888888;
                }
            }
        }
        margin: 100px 0;
    }
    .promotion-user {
        margin: 80px auto 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            max-width: 600px;
            @media (max-width: 575px) {
                flex-wrap: wrap;
            }
            &-item {
                height: 55px;
                flex: 1 1 auto;
                max-width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                backdrop-filter: blur(2px);
                text-transform: uppercase;
                color: #f0f0f0;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                width: 100%;
                position: relative;
                cursor: pointer;
                font-family: 'HHBI';
                &:after {
                    width: 3px;
                    height: 33%;
                    top: 0;
                    right: 0.5px;
                    bottom: 0;
                    margin: auto;
                    content: '';
                    position: absolute;
                    z-index: 2;
                    background: #00ffff;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &:hover,
                &.active {
                    background: #00ffff15;
                    color: #00ffff;
                }
            }
        }
        &-nav-2 {
            max-width: 800px ;
            &-item {
                flex: 1 1 auto;
                max-width: unset;
            }
        }
    }
    .page-name {
        position: relative;
        display: flex;
        justify-content: center;
        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            font-family: 'HHBI';

            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #4effff;
            opacity: 0.6;
            font-size: clamp(40px, 5.5vw, 70px);
        }
        .after {
            font-family: 'HHBI';
            font-size: clamp(20px, 3vw, 35px);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 575px) {
            .before {
                font-size: clamp(55px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .before {
                font-size: clamp(45px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .before {
                font-size: clamp(40px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                bottom: -10px;
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .sub-text {
        color: #4effff;
        font-size: clamp(1em, 4vw, 1.5em);
        max-width: 991px;
        line-height: 120%;
        margin: auto;
        text-align: center;
        text-transform: capitalize;
        font-family: 'HHBI';
    }
    @media (max-width: 480px) {
        padding: 15px 15px;
        .sub-text {
            font-size: 1.1em;
        }
    }
    .title-items {
        color: #fff;
        font-family: 'Helvetica';
        font-size: 25px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 15px 0 15px;
        font-weight: 900;
        padding: 0 0 5px 10px;
        &::after {
            content: '';
            bottom: 0;
            left: 14px;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
        @media (max-width: 991px) {
            margin: 35px auto 15px;
        }
    }
    .section-content-1 {
        padding: 20px 0;
        @media (min-width: 768px) {
            &:nth-child(even) {
                .row-full {
                    .col-md-7:first-child {
                        order: 2;
                        .title {
                            &:before {
                                right: auto;
                                left: 0;
                            }
                            &:after {
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                    .project-item {
                        margin: 20px auto 20px 0;
                    }
                }
            }
            &:nth-child(odd) {
                .row-full {
                    .project-item {
                        margin: 20px 0 20px auto;
                    }
                }
            }
        }
        .row-full {
            overflow: hidden;
            align-items: center;
            padding: 20px 0;
            .title {
                font-family: 'Helvetica', sans-serif;
                color: #fff;
                font-size: 27px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 22px;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    height: 4px;
                    width: calc(100% - 10px);
                    background: #00fff2;
                }
                // &:after {
                // }
                &:before {
                    right: 0;
                    left: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    border: 3px solid #00fff2;
                    bottom: 0;
                }
            }
            .description {
                font-weight: 400;
                font-size: 18px;
                color: #fff;
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px auto;
                width: 100%;
                max-width: 375px;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                }
                .project-img {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .content-project {
                            opacity: 1;
                            transition: all 1s;
                        }
                    }
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                        max-height: 220px;
                        object-fit: scale-down;
                    }
                }
                .project-line {
                    width: 100%;
                    height: 8px;
                    background-color: #00fff2;
                    border-radius: 10px;
                }
            }
        }
    }
    .box-head {
        .section-hd {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0px auto 25px;
            flex-wrap: wrap;
            max-width: 991px;
            &-item {
                margin: 10px;
                button {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 200px;
                    min-width: 150px;
                    font-size: 18px;
                    font-family: 'HHBI';
                    color: #00ffff;
                    background: #00172a;
                    border: 1px solid #00ffff;
                    border-radius: 7px;
                    padding: 10px;
                    text-transform: uppercase;
                    &.active,
                    &:hover {
                        background: #00ffff !important;
                        color: #00172a !important;
                    }
                }
            }
            .item-end {
                flex: 1 1 auto;
                justify-items: center;
                display: flex;
                max-width: 150px;
                min-width: 150px;
                align-items: center;
                text-align: center;
                margin: 10px;
                .modal-help {
                    margin: auto;
                }
            }
        }
    }

    .no-promotion {
        font-family: 'HHBI';
        font-size: clamp(20px, 3vw, 35px);
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
    }
}
