




































































































































































































#listCurrentGame {
    overflow-x: auto;
    table.tablelist-game {
        color: #fff;
        font-size: 14px;
        min-width: 1000px;
        thead {
            th {
                font-size: 16px;
            }
        }
        .table-ID {
            margin: 0;
            padding-top: 12px;
        }
        .isNFT {
            color: #00ffff;
        }
        .isNotNFT {
            color: #888;
            opacity: 0.9;
        }
        .table-game-logo {
            width: 48px;
            height: 48px;
            padding: 8px;
            background: rgba(0, 255, 255, 0.15);
            backdrop-filter: blur(2px);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .table-game-name {
            margin: 0;
            padding-left: 10px;
            font-size: 18px;
            text-decoration: none;
            color: #fff;
            font-family: 'HHBI';
            &:hover {
                color: #00ffff;
            }
        }
        .device-img {
            height: 24px;
            width: auto;
            margin: 8px 5px 0;
        }

        .game-status {
            margin-top: 9px;
            p {
                padding: 5px 20px;
                border-radius: 20px;
            }
        }

        .box-rating {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            .box-rating-process {
                height: 1rem;
                width: 100%;
                background: rgba(0, 255, 255, 0.2);
                border-radius: 20px;
                .box-rating-process-value {
                    background: #00ffff;
                    border-radius: 20px;
                    height: 1rem;
                }
            }
            p {
                padding-left: 12px;
                width: 32px;
            }
        }

        .background1 {
            background: rgba(53, 63, 72, 0.4);
        }
        .background2 {
            background: rgba(53, 63, 72, 0.2);
        }
        .background-header {
            background: rgba(0, 255, 255, 0.15);
        }
        .statusLive {
            background: rgba(0, 255, 255, 0.15);
            color: #00ffff;
        }
        .statusUpdate {
            background: rgba(102, 102, 102, 0.2);
            color: #999;
        }
        .statusBeta {
            background: rgba(255, 165, 0, 0.2);
            color: orange;
        }
    }
}
