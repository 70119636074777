


























































































































































































































































































.box-register {
    min-height: 400px;
    width: 100%;
    max-width: 405px;
    background: {
        image: url(~@/assets/images/mockup/auth/box-2.png);
        size: 100% 100%;
    }
    padding: 15px;
    .form-group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.75rem auto 1.75rem !important;
        text-align: left;
        a {
            color: #00ffff;
            font-size: 17px;
            font-weight: 500;
        }
        label {
            color: #00ffa9;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
        }
        input {
            color: #fff;
            font-weight: 600;
            letter-spacing: 1px;
            width: 100%;
            &::-webkit-input-placeholder {
                color: #fff;
                opacity: 0.4;
                text-align: center;
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 1px;
            }
            height: 45px;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #18274a 0%, #001326 100%);
            border-radius: 0;
        }
        button {
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            color: #002338;
            width: 150px;
            height: 40px;
            font-weight: 700;
            font-size: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
    .text-more {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        margin: 15px auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        a {
            color: #00ffff;
            font-weight: 500;
            display: block;
            @media (min-width: 375px) {
                margin-left: 10px;
            }
        }
        @media (min-width: 480px) {
            flex-direction: row;
            margin-left: 10px;
        }
    }
    .breaking {
        margin: 30px auto;
        background: linear-gradient(00deg, #00ffa9 0%, #00b1ff 100%);
        height: 2px;
        width: 100%;
    }
}
