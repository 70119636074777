





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.close-round {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'HHBI';
    font-size: 2.3em;
    color: #fff;
    text-shadow: 2px 0 0 rgba(0, 252, 245, 0.8), 2px 0 0 rgba(0, 252, 245, 0.8),
        2px 2px 0 rgba(0, 252, 245, 0.8), 2px -2px 0 rgba(0, 252, 245, 0.8);
    letter-spacing: 2px;
    line-height: 100%;
    text-align: center;
}
.pre-sale {
    &__heading {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 0 0 15px;

        &::before {
            content: '';
            width: 40px;
            flex: 0 0 40px;
            height: 14px;
            background: #00ffff;
            margin-right: 15px;
            margin-top: 7px;
        }
        .text {
            flex: 1 1 auto;
            margin-right: 10px;
            font-family: 'HHBI';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #f0f0f0;
            &.borderStyle {
                padding-bottom: 25px;
                border-bottom: 1px solid #fff;
            }
        }
    }
    margin-top: -20px;
    .section {
        padding: 10px 0;
        overflow: hidden;
        position: relative;
        .section-title {
            font-family: 'HHBI';
            font-size: 0.67em;
            color: #fff;
            text-shadow: 2px 0 0 rgba(0, 252, 245, 0.8),
                2px 0 0 rgba(0, 252, 245, 0.8), 2px 2px 0 rgba(0, 252, 245, 0.8),
                2px -2px 0 rgba(0, 252, 245, 0.8);
            letter-spacing: 2px;
            line-height: 100%;
        }
        .section-title-sg {
            color: #00fff8;
            text-shadow: 0px 0 5px rgba(0, 252, 245, 0.7);
            font-size: 1.2em;
            font-family: 'HHBI';
            margin-top: 10px;
            span {
                color: #fff;
                word-break: break-all;
            }
        }
        .section-title-sg2 {
            font-size: 1.4em;
            color: #ff1900;
            text-shadow: 0px 0 5px rgba(252, 0, 0, 0.7);
            font-family: 'HHBI';
            margin-top: 10px;
            span {
                color: #fff;
                word-break: break-word;
            }
        }
        .slick-arrow {
            z-index: 30;
            width: max-content;
            height: max-content;

            &.slick-next {
                right: 1%;
                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/next.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 46px;
                    width: 32px;
                    display: block;
                }
            }
            &.slick-prev {
                left: 1%;
                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/prev.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 46px;
                    width: 32px;
                    display: block;
                }
            }
            @media (min-width: 1366px) {
                &.slick-next {
                    right: -5%;
                }
                &.slick-prev {
                    left: -5%;
                }
            }
            @media (max-width: 425px) {
                &.slick-next,
                &.slick-prev {
                    &:before {
                        height: 36px;
                        width: 25px;
                    }
                }
                &.slick-next {
                    right: 1%;
                }
                &.slick-prev {
                    left: 1%;
                }
            }
        }
        @media (max-width: 767px) {
            .section-title {
                text-shadow: 2px 0 0 rgba(0, 252, 245, 0.7),
                    2px 0 0 rgba(0, 252, 245, 1), 2px 1px 0 rgba(0, 252, 245, 1),
                    1px -1px 0 rgba(0, 252, 245, 1);
            }
        }
        .container {
            position: relative;
            z-index: 10;
        }
    }
    .section-1 {
        background: url('~@/assets/images/airdrop/bg-sec1.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            z-index: 0;
            width: 100%;
            height: 1px;
            box-shadow: 0 0vw 4vw 6vw #001326;
            background: #001326;
        }
        .container-fluid {
            position: relative;
            z-index: 10;
        }
        .section-title {
            font-size: 3.5em;
            letter-spacing: 2px;
            text-shadow: 2px 0 0 rgba(0, 252, 245, 0.5),
                2px 0 0 rgba(0, 252, 245, 0.5), 2px 2px 0 rgba(0, 252, 245, 0.5),
                2px -2px 0 rgba(0, 252, 245, 0.5),
                2px 2px rgba(0, 252, 245, 0.5),
                2px -2px 2px rgba(0, 252, 245, 0.5),
                2px -2px 2px rgba(0, 252, 245, 0.5),
                2px 2px 2px rgba(0, 252, 245, 0.5);
            text-transform: uppercase;
        }
        .section-title-sg {
            font-size: 1.4em;
        }
        .line {
            width: 80%;
            height: 2px;
            background-color: #00fff8;
            margin: 30px 0;
            box-shadow: 1px 1px 3px rgba(0, 252, 245, 1);
        }
        .img-mk-home {
            width: 100%;
            height: auto;
            transform: scale(1);
            margin-top: -100px;
            margin-left: -80px;
            animation: imgHero3 3s linear infinite;
        }
        .carousel-item-home {
            min-height: calc(100vh - 90px);
            display: flex !important;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
        .count-down {
            max-width: 500px;
            margin: 20px 0;
            position: relative;
            z-index: 10;
            .text-bottom,
            .text-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .right,
                .left {
                    flex: 1 1 auto;
                    display: flex;
                }
                .right {
                    justify-content: flex-end;
                }
                .left {
                    justify-content: flex-start;
                }
            }
            .progress-body {
                height: 25px;
                max-width: 500px;
                position: relative;
                margin-top: 10px;
                .bg-progress {
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: auto;
                    bottom: 0;
                    z-index: 3;
                    .progress-active {
                        border: 1px solid #00ffff;
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        border-radius: 15px;
                        width: calc(40% - 4px);
                        height: calc(100% - 4px);
                        position: absolute;
                        top: 0;
                        left: 2px;
                        right: auto;
                        bottom: 0;
                        margin: auto 0;
                        z-index: 4;
                    }
                }

                .percent-text {
                    color: #00000b;
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0px;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    &.low {
                        color: #fff;
                    }
                }
            }
        }
        .cs-mk {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media (max-width: 1799px) {
            .img-mk-home {
                // transform: scale(1.5);
                margin-left: -100px;
            }
        }
        @media (max-width: 1699px) {
            .img-mk-home {
                // transform: scale(1.4);
            }
        }
        @media (max-width: 1499px) {
            .img-mk-home {
            }
        }
        @media (max-width: 1299px) {
            .img-mk-home {
                margin-top: -100px;
                margin-left: -90px;
            }
        }
        @media (max-width: 1199px) {
            .img-mk-home {
                margin-top: -50px;
                margin-left: -50px;
                // transform: scale(1.3);
            }
            .section-title {
                font-size: 3em;
            }
            .section-title-sg {
                font-size: 1em;
            }
            .section-title-sg2 {
                font-size: 1em;
            }
            .line {
                width: 150px;
                margin: 25px 0px;
            }
        }
        @media (max-width: 991px) {
            padding: 50px 0;
            overflow: auto;
            .section-title,
            .section-title-sg,
            .section-title-sg2,
            img {
                width: 100%;
            }
            .section-title,
            .section-title-sg,
            .section-title-sg2 {
                position: relative;
                z-index: 10;
            }
            .section-title {
                text-align: center;
                font-size: 2.5em;
                text-shadow: 2px 0 0 rgba(0, 252, 245, 0.7),
                    2px 0 0 rgba(0, 252, 245, 1), 2px 1px 0 rgba(0, 252, 245, 1),
                    1px -1px 0 rgba(0, 252, 245, 1);
            }
            .section-title-sg {
                text-align: left;
                font-size: 1.2em;
            }
            .section-title-sg2 {
                text-align: left;
                font-size: 1.2em;
            }
            .line {
                margin: 30px auto;
            }
            .cs-mk {
                position: absolute;
                top: 0;
                max-width: 400px;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                align-items: center;
                z-index: 0;
                .img-mk-home {
                    transform: scale(1);
                    margin-top: 30px;
                    margin-left: 0px;
                }
            }
            .cs-tablet {
                max-width: 90%;
            }
        }
        @media (max-width: 575px) {
            overflow: auto;
            height: auto;
            padding: 30px 0;
            .section-title {
                font-size: 2em;
            }
            .section-title-sg {
                font-size: 0.9em;
            }
            .section-title-sg2 {
                font-size: 0.9em;
            }
            .cs-mk {
                // display: none;
                position: unset;
            }
            .cs-tablet {
                width: 100%;
                max-width: 100%;
                padding: 5px;
            }
            .right,
            .left {
                flex: 1 1 auto;
                width: fit-content;
            }
            .right {
                display: flex;
                justify-content: flex-end;
            }
        }
        @media (max-width: 374px) {
            .section-title-sg {
                font-size: 0.8em;
            }
            .section-title-sg2 {
                font-size: 0.8em;
            }
        }
        @media (min-width: 1366px) {
            .slick-arrow {
                &.slick-next {
                    right: 5%;
                }
                &.slick-prev {
                    left: 5%;
                }
            }
        }
    }
    .section-2 {
        // background: url('~@/assets/images/airdrop/bg-1.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        background-attachment: fixed;
        overflow: initial;
        padding: 100px 0px;
        min-height: 70vh;
        box-shadow: 0px 25px 20px #001326 inset, 0px -0 0 #001326 inset;
        height: 100%;
        .section-title {
            font-size: 2.2em;
            letter-spacing: 2px;
            text-shadow: 2px 0 0 rgba(0, 252, 245, 0.7),
                2px 0 0 rgba(0, 252, 245, 1), 2px 1px 0 rgba(0, 252, 245, 1),
                1px -1px 0 rgba(0, 252, 245, 1);
            text-transform: uppercase;
            text-align: center;
            @media (max-width: 991px) {
                font-size: 2.5em;
            }
            @media (max-width: 480px) {
                font-size: 2em;
            }
        }
        .container {
            position: relative;
            z-index: 10;
            .box-preSale {
                max-width: 900px;
                width: 100%;
                min-height: 400px;
                padding: 20px;
                margin: 30px auto 50px;
                .header-preSale {
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    border: 1px solid #00ffff;
                    width: 70%;
                    min-width: fit-content;
                    max-width: 768px;
                    padding: 0 40px;
                    transform: skewX(-20deg);
                    height: 60px;
                    position: relative;
                    z-index: 20;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto -30px;
                    .section-title {
                        transform: skewX(20deg);
                    }
                }
                .body-preSale {
                    position: relative;
                    z-index: 19;
                    margin-top: 0px;
                    padding: calc(5% + 20px) 7%;
                    background: {
                        image: url('~@/assets/images/airdrop/s11.png');
                        size: 100% 100%;
                    }
                    .form-group {
                        margin: 15px auto;
                        max-width: 580px;
                        width: 100%;
                        .label {
                            color: #fff;
                            font-size: 1.2em;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 5px;
                            font-weight: 600;
                            .label-main {
                                font-size: 1.4em;
                                font-family: 'HHBI';
                                line-height: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    padding-right: 10px;
                                    width: 40px;
                                }
                            }
                            .price {
                                font-family: 'HHBI';
                            }
                            @media (max-width: 374px) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }
                        input {
                            flex: 1 1 auto;
                            margin: 0 15px 0 0;
                            height: 45px;
                            background: linear-gradient(
                                180deg,
                                #00172a 0%,
                                #00000b 100%
                            );
                            border: 1px solid #00ffff;
                            color: #fff;
                            font-weight: 600;
                            width: 100%;
                            padding: 0 15px;
                        }
                        .buy-HBG {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 40px auto 10px;
                            background: linear-gradient(
                                90deg,
                                #00ffa9 0%,
                                #00b1ff 100%
                            );
                            border-radius: 7px;
                            width: 70%;
                            max-width: 375px;

                            padding: 2px;
                            button {
                                color: #000;
                                border-radius: 5px;
                                font-weight: 600;
                                border: 1.5px solid #fff;
                                padding: 0;
                                height: 100%;
                                height: 35px;
                                width: 100%;
                                font-family: 'HHBI';
                                font-size: 1.6em;
                                line-height: 0;
                                background: transparent;
                                box-shadow: 0px 0px 5px #00ffff;
                                text-transform: uppercase;
                                &:hover,
                                &:focus-visible,
                                &:visited {
                                    background: #00172a6b;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    .btn-change {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 10px auto;
                        background: transparent;
                        border: 0;
                        box-shadow: unset;
                        border-radius: 7px;
                        width: 50px;
                        height: 50px;
                        padding: 0;
                        border-radius: 50%;
                        // background: linear-gradient(
                        //     90deg,
                        //     #00ffa9 0%,
                        //     #00b1ff 100%
                        // );
                        img {
                            width: 100%;
                        }
                    }
                    .option {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        button {
                            background: linear-gradient(
                                180deg,
                                #00172a 0%,
                                #00000b 100%
                            );
                            padding: 2px 5px !important;
                            margin: 5px;
                            height: 35px;
                            color: #fff;
                            font-family: 'HHBI';
                            width: fit-content;
                            min-width: 90px;
                            font-size: 1.1em;
                            line-height: 0;
                            &:hover {
                                background: linear-gradient(
                                    90deg,
                                    #00ffaa 0%,
                                    #00b3ff 100%
                                );
                                color: #00000b;
                            }
                        }
                    }
                }
            }
            .box-info-presale {
                // background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 140px;
                height: 100%;
                width: 100%;
                max-width: 575px;
                padding: 5% 10%;
                // border-radius: 10px;
                @media (max-width: 480px) {
                    min-height: 100px;
                }
                .icons {
                    flex: 0 0 70px;
                    width: 70px;
                    height: 100%;
                    img {
                        width: 50px;
                        height: auto;
                    }
                }
                .text {
                    flex: 1 1 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    height: 100%;
                    .text-title {
                        color: #4effff;
                        font-size: 1.4em;
                        line-height: 120%;
                        font-family: 'HHBI';
                        font-weight: 600;
                        margin-bottom: 15px;
                        text-transform: capitalize;
                    }
                    .text-content {
                        color: #ffffff;
                        font-size: 1.6em;
                        line-height: 120%;
                        font-family: 'HHBI';
                    }
                    &:not(.button-claim) {
                        padding-bottom: 50px;
                    }
                }
                button.claim {
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    padding: 2px 5px !important;
                    margin: 10px auto 5px;
                    height: 38px;
                    color: #fff;
                    font-family: 'HHBI';
                    width: 150px;
                    font-size: 1.1em;
                    line-height: 0;
                    &:hover {
                        background: linear-gradient(
                            90deg,
                            #00ffaa 0%,
                            #00b3ff 100%
                        );
                        color: #00000b;
                    }
                }
            }
            &.box-unlock {
                .section-title {
                    font-size: 2.7em;
                }
                margin-top: 10vh;
                .box-table {
                    background: url('~@/assets/images/mockup/token-metric/table.png');
                    background-size: 100% 100%;
                    width: 100%;
                    height: auto;
                    padding: 3%;
                    margin: 0 auto;
                    &.mb {
                        display: none;
                        background: #021527b3;
                        border: 2px solid #00ffff;
                        border-radius: 15px;
                        background-size: 100% 100%;
                        padding: 0;
                        .box-content {
                            .item {
                                display: flex;
                                justify-content: center;
                                min-height: 60px;
                                align-items: center;
                                padding: 15px 25px;
                                flex-direction: column;
                                .item-content {
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    border-top: 1px solid #fff;
                                    min-height: 60px;
                                    align-items: center;
                                    &:nth-child(1) {
                                        border-top: 0;
                                        .right {
                                            .section-title {
                                                color: #00fff8;
                                            }
                                        }
                                    }
                                    .left,
                                    .right {
                                        width: 50%;
                                    }
                                    .section-title-sg,
                                    .section-title {
                                        font-size: 18px;
                                        margin: 0px;
                                        text-shadow: 0px 0 5px
                                            rgba(0, 252, 245, 0.7);
                                        text-transform: none;
                                        text-align: right;
                                    }
                                    .section-title-sg {
                                        color: #00fff8;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                    .box-header {
                        display: flex;
                        min-height: 75px;
                        background: rgba(0, 0, 0, 0.5);
                        padding: 0 25px;
                        align-items: center;
                        div {
                            color: #00fff8;
                            text-shadow: 0px 0 5px rgba(0, 252, 245, 0.7);
                            font-size: 1.2em;
                            font-family: 'HHBI';
                            text-transform: uppercase;
                            text-align: left;
                        }
                        .name,
                        .value {
                            flex: 0 0 30%;
                            text-align: center;
                            // max-width: 300px;
                        }
                        .price,
                        .time,
                        .status {
                            flex: 0 0 20%;
                            text-align: center;
                            // max-width: 200px;
                        }
                        .quantity {
                            flex: 1 1 auto;
                        }
                    }
                    .box-content {
                        display: flex;
                        flex-direction: column;
                        .item {
                            display: flex;
                            min-height: 63px;
                            align-items: center;
                            padding: 0 25px;
                            &:nth-child(2n + 2) {
                                background: rgba(0, 0, 0, 0.25);
                            }
                            div {
                                color: #ffffff;
                                text-shadow: 0px 0 5px rgba(0, 252, 245, 0.7);
                                font-size: 1.2em;
                                font-family: 'HHBI';
                                text-align: left;
                            }
                            .name {
                                color: #00fff8;
                            }
                            .name,
                            .value {
                                text-align: center;
                                flex: 0 0 30%;
                            }
                            .price,
                            .time,
                            .status {
                                text-align: center;
                                flex: 0 0 20%;
                                .btn-status {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background: linear-gradient(
                                        90deg,
                                        #00ffa9 0%,
                                        #00b1ff 100%
                                    );
                                    border-radius: 7px;
                                    width: fit-content;
                                    height: 40px;
                                    padding: 2px 5px;
                                    text-transform: uppercase;
                                    color: #00172a;
                                }
                            }
                            .quantity {
                                flex: 1 1 auto;
                            }
                            .status {
                                justify-content: center;
                                align-items: center;
                                display: flex;
                                text-transform: uppercase;
                                .section-title {
                                    text-transform: uppercase;
                                }
                                @media (max-width: 991px) {
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }
                    @media (max-width: 991px) {
                        &.pc {
                            display: none;
                        }
                        &.mb {
                            display: block;
                        }
                    }
                }
            }
            @media (max-width: 575px) {
                .box-preSale {
                    padding: 0;
                    .body-preSale {
                        background-size: auto;
                        background-position: center center;
                        border: 2px solid #00ffff;
                        padding: calc(5% + 20px) 7% 0;
                        border-radius: 5px;
                    }
                }
                .box-info-presale,
                .box-preSale {
                    margin: 15px auto;
                }
            }
        }
    }
    .box-mission {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #00ffff;
        margin-bottom: 15px;
        padding: 10px 10px;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 475px;
        height: auto;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 6px;
            height: 6px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            right: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            left: 1px;
        }
        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;
            img {
                width: 50px;
                height: auto;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .text-title {
                min-height: 40px;
                color: #4effff;
                font-size: 1.4em;
                line-height: 120%;
                font-family: 'HHBI';
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .text-content {
                height: calc(100% - 50px);
                color: #ffffff;
                font-size: 1.6em;
                line-height: 120%;
                font-family: 'HHBI';
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &.box-mission-2 {
            max-width: unset;
            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .text-title {
                    margin-bottom: 0;
                }
                margin-bottom: 15px;
            }
            .pending {
                width: 100%;
                &-content {
                    height: 40px;
                    padding: 0 0.75rem;
                    vertical-align: middle;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    font-family: 'Helvetica';
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 5px;
                    &:nth-of-type(even) {
                        box-shadow: inset 0px 0px 2005px rgba(22, 22, 22, 0.5);
                    }
                    &:nth-of-type(odd) {
                        box-shadow: inset 0px 0px 2005px rgba(18, 37, 37, 0.2);
                    }
                    &-left {
                        flex: 0 0 calc(100% - 300px);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        .form-control {
                            background: transparent;
                            border: 0.5px solid #e4e0e0;
                            border-radius: calc(40px / 1.25);
                            height: 40px;
                            max-width: 375px;
                            color: #fff;
                            font-size: 17px;
                            margin-right: 5px;
                            &:is(:focus, .active) {
                                border-color: #00ffff;
                            }
                        }
                        button {
                            border-radius: calc(40px / 1.25);
                            height: 40px;
                            padding: 0 15px !important;
                            max-width: unset;
                            outline: unset !important;
                        }
                    }
                    &-right {
                        flex: 0 0 250px;
                        border-left: 1px solid #00ffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #02ffff;
                        @media (min-width: 992px) {
                            justify-content: flex-start;
                            text-align: left;
                            padding-left: 5%;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                    @media (max-width: 575px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                }
            }
        }
        &.box-mission-3 {
            max-width: 500px;
            flex-direction: column;
            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .text-title {
                    margin-bottom: 0;
                }
                margin-bottom: 15px;
            }
        }
    }
}
@keyframes imgHero3 {
    25% {
        transform: translateY(-20px) scale(1);
    }
    50% {
        transform: translateY(0px) scale(1);
    }
    75% {
        transform: translateY(20px) scale(1);
    }
}
#claimHBG {
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
.box-token {
    width: 100%;
    min-height: 150px;
    border: 1px solid #00ffff;
    margin-bottom: 15px;
    padding: 10px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &:after,
    &::before {
        z-index: 0;
        content: '';
        width: 6px;
        height: 6px;
        background: #00ffff;
    }

    &:after {
        position: absolute;
        top: 1px;
        right: 1px;
    }
    &::before {
        position: absolute;
        bottom: 1px;
        left: 1px;
    }
    &.main-box {
        min-height: 240px;
        padding: 15px 25px;
        @media (max-width: 413px) {
            padding: 15px 10px;
        }
    }
    &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        &.border-b {
            border-bottom: 1px solid #fff;
            padding: 0 0 15px;
        }
        &-image {
            margin-right: 20px;
            img {
                width: 29px;
                height: 29px;
            }
        }
        &-title {
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.75px;
            color: #4effff;
        }
    }
    &-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 15px 0;
        flex-wrap: wrap;
        &-image {
            position: relative;
            margin-right: 15px;
            flex: 0 0 65px;
            img {
                width: 43px;
                height: 43px;
                margin-right: 15px;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 5px;
                height: 5px;
                background: #00ffff;
                border-radius: 50%;
            }
        }
        &-amount {
            flex: 1 1 auto;
            font-family: 'Epilogue';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #00ffff;
        }
        &-symbol {
            flex: 1 1 auto;
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.75px;
            color: #00ffff;
            text-align: center;
        }
    }
    &-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        a {
            .box-wallet-footer-button {
                max-width: unset;
            }
        }
        &-button {
            margin: 10px;
            background: #55555550;
            border-radius: 54px;
            height: 44px;
            width: 160px;
            border: none;
            box-shadow: unset;
            outline: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: calc(50% - 20px);
            &.active,
            &:hover,
            &:focus,
            &:active {
                background: rgba(0, 255, 255, 0.95);
                * {
                    color: #000 !important;
                }
            }
            &-text {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #dcdcdc;
            }
            &-image {
                margin-right: 12px;
                img {
                    width: 17px;
                    height: auto;
                    max-height: 17px;
                    object-fit: scale-down;
                }
            }
        }
    }
}
.unlock__history {
    &__box__table {
        margin-top: 60px;
        .table-vac {
            table {
                th,
                tr,
                td {
                    border: 0;
                    min-width: 100px;
                    &:first-child {
                        min-width: unset;
                        padding: 0;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
                th {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #f0f0f0;
                    padding: 24px;
                    position: relative;
                    text-align: center !important;
                    &::after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 2px;
                        height: 16px;
                        content: '';
                        margin: auto;
                        background: #00ffff;
                    }
                    &:last-child {
                        &::after {
                            background: transparent;
                        }
                    }
                }
                tbody {
                    tr,
                    td {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        /* or 150% */
                        vertical-align: middle;
                        letter-spacing: 0.01em;

                        /* GrayScale/Input BG */
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: 25px;
                        color: #f0f0f0;
                    }
                    tr:nth-of-type(odd) {
                        background-color: #49505757;
                        border-radius: 1px;
                    }
                    tr {
                        &:hover {
                            background-color: rgba(0, 255, 255, 0.05);
                            border-radius: 1px;
                            color: #f0f0f0;
                        }
                    }
                }
            }
            .btn-open-detail {
                padding: 0;
                box-shadow: none !important;
                outline: none !important;
                border: 0 !important;
                height: 25px;
                width: 25px;
                background: #00172a;
                border-radius: 50%;
                margin: auto 5px !important;
                img {
                    width: 15px;
                    height: 15px;
                }
                &.open {
                    width: 25px;
                    height: 25px;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
            .b-table-details {
                background: #001326c4 !important;
            }
            .detail__table__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10px;
                text-transform: capitalize;
                &-left {
                    margin-right: 15px;
                    padding-right: 15px;
                }
                &-right {
                }
            }
        }
    }
}
