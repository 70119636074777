




















































































































































































































































































































































































































































































































































































.mysteryBoxShop {
    font-family: 'Poppins';
    flex: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
        max-width: 100%;
    }
    button {
        outline: unset;
    }
    .inventory-box-item {
        .inventory-item-image {
            width: 200px;
            height: auto;
            margin-top: 30px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .land-name-content {
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .mysteryBoxShop-information {
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f8f8f8;
                padding: 8px 1rem;
            }
            .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #00ffff;
            }
        }
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        max-width: 135px;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
    }
}
