






































.btn-cls{
  position: absolute;
  bottom: 0;
  right: 0;
  height: max-content;
  button{
    height: 30px;
    width: max-content;
    margin: 0;
    min-width: unset!important;
  }
}
.login-qr-container {
    .qrLogin {
        border: 1px solid #00ffff;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        color: #fff;
        font-weight: 500;
        height: 50px;
        width: 100%;
        font-size: 18px;
        max-width: 375px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        max-width: 375px;
        margin: auto;
        &:hover {
            color: #00ffff;
        }
    }
}
