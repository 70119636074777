









































































































#detailMarketItem {
    font-family: 'Poppins';
    font-style: normal;
    .detailBox-image {
        background: linear-gradient(
            180deg,
            rgba(53, 63, 72, 0) 0%,
            rgba(53, 63, 72, 0.2) 100%
        );
        border-radius: 16px;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 80%;
        }
    }
    .detailBox-content {
        margin-top: 60px;
        .box-title-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
            border-radius: 100px;
            padding: 5px;
            img {
                width: 100%;
            }
        }
        .title-tag {
            font-weight: 500;
            font-size: 16px;
            color: #f0f0f0;
            margin: 0;
            padding-left: 30px;
        }
        .detailBox-content-title {
            font-weight: 600;
            font-size: 32px;
            color: #fcfcfc;
            padding: 18px 0 30px;
        }
        .detailBox-content-decs {
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #f8f8f8;
        }
        .detailBox-btn {
            width: 100%;
            max-width: 270px;
            margin-top: 40px;
            button {
                border-radius: 4px;
                width: 100%;
                height: 54px;
                font-weight: 500;
                font-size: 18px;
                color: #00ffff !important;
                outline: unset !important;
                &::before {
                    border-radius: 4px;
                }
            }
        }
    }
    .detailBox-main-content {
        p {
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #dcdcdc;
        }
        ul {
            li {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
                color: #dcdcdc;
            }
        }
    }
    .chartItem {
        background: linear-gradient(rgba(35, 60, 87, 0), rgba(35, 60, 87, 0.3));
        border-top: 1.5px solid rgb(35, 60, 87);
        border-left: 1.5px solid rgb(35, 60, 87);
        border-bottom: 3.5px solid rgb(35, 60, 87);
        .chartItem-title {
            margin: 0;
            padding: 15px 25px 10px;
            font-size: 13px;
            color: #ccc;
        }
        .chartItem-box {
            min-height: 450px;
        }
    }
    .select-chartItem {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 25px 20px 0;
        button {
            padding: 0;
            outline: unset;
            box-shadow: none;
            border: none;
            font-size: 13px;
            color: #ccc;
            margin: 0 8px;
            opacity: 0.4;
            font-weight: 500;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
        button.isActive {
            opacity: 1;
        }
    }
}
