




































































































































































































































.hero-list-transform {
    .box-picker {
        border-top: 1px solid #00ffff;
        border-bottom: 1px solid #00ffff;
        width: 100%;
        min-height: 50px;
        margin-bottom: 55px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        &-item {
            cursor: pointer;
            font-family: 'HHBI';
            background: none !important;
            box-shadow: unset !important;
            border: 0 !important;
            outline: unset !important;

            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            /* or 186% */

            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            letter-spacing: 0.75px;

            /* cyan */
            color: #fff;
            &.active,
            &:hover {
                color: #00ffff;
            }
        }
        .btn-toogle {
            width: 35px;
            height: 35px;
        }
    }
    .box-name {
        position: relative;
        margin: auto;
        width: 100%;
        min-height: 130px;
        text-align: center;
        justify-content: center;
        display: flex;
        .after {
            position: unset !important;
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            text-align: center;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 991px) {
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .VueCarousel-navigation-next {
        right: 3% !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        border: 0 !important;
        outline: none !important;
    }
    .VueCarousel-navigation-prev {
        left: 3% !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        border: 0 !important;
        outline: none !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
        // flex-basis: 100%!important;
        // width: 100%!important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    @media (max-width: 575px) {
        .VueCarousel-navigation-next {
            right: 5% !important;
        }
        .VueCarousel-navigation-prev {
            left: 5% !important;
        }
    }
    .hero {
        position: relative;
        max-width: 236px;
        width: 100%;
        margin: auto;
        &-avatar {
            height: 175px;
            width: 100%;

            img {
                width: 100%;
                height: auto;
                max-height: 175px;
                object-fit: scale-down;
            }
        }
        &-backdrop {
            clip-path: polygon(40% 20%, 100% 0%, 100% 100%, 0 100%, 0 35%);
            background: linear-gradient(
                12.84deg,
                #00ffff 4.96%,
                rgba(0, 255, 255, 0) 102.53%
            );
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 312px;
            content: ' ';
            opacity: 0.1;
            z-index: 0;
        }
        &-information {
            height: 215px;
            width: 100%;

            position: relative;
            z-index: 1;
            padding: 30px 20px;
            &-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid #00ffff;
                padding-bottom: 15px;
                &-level {
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 20px;
                    margin-right: 15px;
                    /* cyan */

                    color: #00ffff;
                }
                &-name {
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 20px;

                    color: #f8f8f8;
                }
            }
            &-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 0.75px;
                margin: 10px 0;
                /* GrayScale/Background */
                color: #f8f8f8;
            }
            .pick-button {
                &.remove {
                    border: 1px solid #fe3f61;
                    color: #fe3f61;
                    &:after,
                    &::before {
                        background: #fe3f61;
                    }
                }
                &-content {
                    position: relative;
                    z-index: 10;
                    font-family: 'hemiheadRg-BoldItalic';
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    /* or 144% */

                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.75px;
                    // color: #FE3F61;
                    justify-content: center;
                    img {
                        filter: invert(35%) sepia(131%) saturate(624%)
                            hue-rotate(147deg) brightness(100%) contrast(106%);
                    }
                }
                border: 1px solid #00ffff;
                background: transparent;
                box-shadow: unset;
                position: relative;
                width: 200px;
                height: 50px;
                border-radius: 0;
                color: #00ffff;
                &:after,
                &::before {
                    z-index: 0;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                }

                &:after {
                    position: absolute;
                    top: 1px;
                    left: 1px;
                }
                &::before {
                    position: absolute;
                    bottom: 1px;
                    right: 1px;
                    -webkit-transform: scaleY(0.5);
                    transform: scaleY(0.5);
                    -webkit-transform-origin: 50% 100%;
                    transform-origin: 50% 100%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.5s;
                    transition-duration: 0.5s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: #000;

                    &::before {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        -webkit-transform: scaleY(1);
                        transform: scaleY(1);
                        -webkit-transition-timing-function: cubic-bezier(
                            0.52,
                            1.64,
                            0.37,
                            0.66
                        );
                        transition-timing-function: cubic-bezier(
                            0.52,
                            1.64,
                            0.37,
                            0.66
                        );
                    }

                    img {
                        filter: invert(0%) sepia(0%) saturate(0%)
                            hue-rotate(15deg) brightness(22%) contrast(102%);
                    }
                }
            }
        }
    }
}
.hero-snake {
    position: relative;
    max-width: 236px;
    width: 100%;
    margin: auto;

    &-avatar {
        height: 105px;
        width: 100%;

        img {
            width: 100%;
            height: auto;
            max-height: 175px;
            object-fit: scale-down;
        }
    }
    &-backdrop {
        clip-path: polygon(40% 20%, 100% 0%, 100% 100%, 0 100%, 0 35%);
        background: linear-gradient(
            12.84deg,
            #00ffff 4.96%,
            rgba(0, 255, 255, 0) 102.53%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 312px;
        content: ' ';
        opacity: 0.1;
        z-index: 0;
        border-radius: 10px;
    }
    &-information {
        height: 215px;
        width: 100%;

        position: relative;
        z-index: 1;
        padding: 30px 20px;
        &-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #00ffff;
            padding-bottom: 15px;
            &-level {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                margin-right: 15px;
                /* cyan */

                color: #00ffff;
            }
            &-name {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 24px;
                line-height: 20px;

                color: #f8f8f8;
            }
        }
        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.75px;
            margin: 10px 0;
            /* GrayScale/Background */
            color: #f8f8f8;
        }
        .pick-button {
            &.remove {
                border: 1px solid #fe3f61;
                color: #fe3f61;
                &:after,
                &::before {
                    background: #fe3f61;
                }
            }
            &-content {
                position: relative;
                z-index: 10;
                font-family: 'hemiheadRg-BoldItalic';
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                /* or 144% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.75px;
                // color: #FE3F61;
                justify-content: center;
                img {
                    filter: invert(35%) sepia(131%) saturate(624%)
                        hue-rotate(147deg) brightness(100%) contrast(106%);
                }
            }
            border: 1px solid #00ffff;
            background: transparent;
            box-shadow: unset;
            position: relative;
            width: 200px;
            height: 50px;
            border-radius: 0;
            color: #00ffff;
            &:after,
            &::before {
                z-index: 0;
                content: '';
                width: 5px;
                height: 5px;
                background: #00ffff;
            }

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
            }
            &::before {
                position: absolute;
                bottom: 1px;
                right: 1px;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: #000;

                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                    transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                }

                img {
                    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(15deg)
                        brightness(22%) contrast(102%);
                }
            }
        }
    }
}
