









































































































































































































































.market-community {
    padding: 100px 0;
    font-family: 'Poppins';
    font-style: normal;
    .market-header {
        min-height: 175px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left,
        &-right {
            flex: 1 1 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            max-width: 140px;
        }
        &-left {
            &:before {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-right: 10px;
            }
            &:after {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
        }
        &-right {
            &::before {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
            &::after {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-left: 10px;
            }
        }
        &-center {
            flex: 1 1 calc(100% - 280px);
            margin: 0 15px;
            max-width: 650px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 1 200px;
                max-width: 200px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &-text {
                max-width: 400px;
                &-title {
                    font-size: 32px;
                    color: #fcfcfc;
                    line-height: 36px;
                    font-weight: 700;
                }
                &-content {
                    margin-top: 20px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 29px;
                    color: #888888;
                }
            }
        }
    }
    .market-user {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            max-width: 600px;
            @media (max-width: 575px) {
                flex-wrap: wrap;
            }
            &-item {
                height: 55px;
                flex: 1 1 250px;
                max-width: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                backdrop-filter: blur(2px);
                color: #f0f0f0;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                width: 100%;
                position: relative;
                cursor: pointer;
                &:after {
                    width: 3px;
                    height: 33%;
                    top: 0;
                    right: 0.5px;
                    bottom: 0;
                    margin: auto;
                    content: '';
                    position: absolute;
                    z-index: 2;
                    background: #00ffff;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &:hover,
                &.active {
                    background: #00ffff15;
                    color: #00ffff;
                }
            }
        }
        &-nav-2 {
            max-width: unset;
            &-item {
                flex: 1 1 auto;
                max-width: unset;
            }
        }
    }


    @media (max-width: 575px) {
        .market-header {
            flex-direction: column;
            &-left,
            &-right {
                width: 100%;
                max-width: unset;
                flex: unset;
            }
            &-left {
                justify-content: flex-start;
                &:after {
                    width: 50%;
                }
            }
            &-right {
                justify-content: flex-end;
                &::before {
                    width: 50%;
                }
            }
        }
    }
    @media (max-width: 767px) {
        &.container {
            max-width: calc(100% - 30px);
        }
    }
}
