



































































































































































































































































































































































































































































































































































































































































































































































































































































#listPackage {
    .agency-box-container {
        width: 100%;
        max-width: 270px;
        margin: auto;
        // display: flex;
        // flex-wrap: wrap;
        .box-img {
            position: relative;
            width: 100%;
            max-width: 280px;
            display: flex;
            align-items: flex-end;
            img {
                width: 100%;
                z-index: 10;
                position: relative;
                filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                max-height: 200px;
                object-fit: contain;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
        }

        .box-content {
            background: {
                image: url('~@/assets/images/mockup/shop/content.png');
                size: 100% 100%;
                position: bottom center;
                repeat: no-repeat;
            }
            // background: none;
            margin-top: 5px;
            width: 100%;
            min-height: auto;
            max-width: 280px;
            padding: 15px;
            // padding: 0;
            .name {
                font-size: 2em;
                text-align: left;
                margin-bottom: 15px;
                font-family: 'HHBI';
                text-transform: uppercase;
                &.diamond {
                    color: #ff00ff;
                    text-shadow: 1px 0px 9px #ff00ff;
                }
                &.gold {
                    color: #f3ff00;
                    text-shadow: 1px 0px 9px #f3ff00;
                }
                &.silver {
                    color: #98d7e0;
                    text-shadow: 1px 0px 9px #98d7e0;
                }
                &.platinum {
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                    @media (max-width: 374px) {
                        font-size: 1.7em;
                    }
                }
            }
            .remaining-box {
                color: #fff;
                font-family: 'HHBI';
                font-size: 1.2em;
                text-align: center;
                span {
                    color: red;
                }
            }
            .buy-section {
            }
            .agency-box-price {
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                color: white;
                .left {
                    // flex: 0 0 25%;
                    // color: #002338;
                    font-weight: 600;
                    margin-right: 10px;
                }
                .right {
                    // flex: 0 0 75%;
                    display: inline-block;
                    padding-left: 10px;
                    color: #00ffff;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .hbg {
                        font-size: 1.4em;
                        font-weight: 900;
                        line-height: 120%;
                        font-family: 'HHBI';
                    }
                    .usd {
                        font-size: 1em;
                        font-weight: 900;
                        margin-left: 10px;
                        font-family: 'HHBI';
                    }
                }
            }

            .detail {
                color: #fff;
                padding: 15px 0;
                text-align: center;
            }
        }
        .btn-modal-info-agency-box {
            background: #00ffff;
            width: 30px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            padding: 0;

            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 10;

            color: black;
            font-weight: bold;
            font-family: 'HHBI';
        }
        .btn-buy-agency-box {
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border: 1px solid #00ffff;
            padding: 5px 30px;
            color: black;
            font-weight: bold;
            &:disabled {
                background: #001326;
                color: #fff;
            }
        }
        @media (max-width: 991px) {
            margin: 25px auto 0;
        }
    }
}
#agencyReceive___BV_modal_outer_ {
    z-index: unset !important;
}
#agencyReceive {
    .box-i,
    .box {
        max-width: 250px;
        width: 100%;
        padding: 5px 0 15px;
        margin: 0 auto 5px;
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 210px;
                object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            flex-direction: column;
            color: #fff;
            font-size: 18px;
            div {
                font-size: 18px;
                font-weight: 500;
            }
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
    }
}
.agency-tab {
    .title-agency-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #f8f8f8;
        margin: 0;
        padding-left: 36px;
        @media (max-width: 480px) {
            padding-left: 5px;
        }
    }
    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        max-width: 600px;
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
        }
    }
    .table-responsive {
        table {
            th,
            tr,
            td {
                border: 0;
                min-width: 100px;
                &:first-child {
                    min-width: unset;
                    padding: 0;
                    text-align: center;
                    vertical-align: middle;
                }
            }
            th {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.01em;
                color: #00ffff;
                padding: 24px;
                position: relative;
                text-align: center !important;
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 2px;
                    height: 16px;
                    content: '';
                    margin: auto;
                    background: #00ffff;
                }
                &:first-child,
                &:last-child {
                    &::after {
                        background: transparent;
                    }
                }
            }
            tbody {
                tr,
                td {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    /* or 150% */
                    vertical-align: middle;
                    letter-spacing: 0.01em;

                    /* GrayScale/Input BG */
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 25px;
                    color: #f0f0f0;
                }
                tr:nth-of-type(odd) {
                    background-color: rgba(0, 255, 255, 0.15);
                    border-radius: 1px;
                    margin: 10px;
                }
                tr {
                    &:hover {
                        // background-color: rgba(0, 255, 255, 0.05);
                        border-radius: 1px;
                        color: #f0f0f0;
                    }
                }
            }
        }
    }
    .table-statistical {
        border: 1px solid #00ffff;
        // max-width: 600px;
        margin: 35px auto;
        .table-item {
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Helvetica';
            justify-content: space-between;

            &:nth-of-type(even) {
                box-shadow: inset 0px 0px 2005px rgba(0, 255, 255, 0.2);
            }
            .left {
                flex: 0 0 calc(100% - 300px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .right {
                flex: 0 0 250px;
                border-left: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: 992px) {
                    justify-content: flex-start;
                    text-align: left;
                    padding-left: 5%;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }
    .box-statistical {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #00ffff;
        margin-bottom: 15px;
        padding: 10px 10px;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 475px;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 6px;
            height: 6px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            right: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            left: 1px;
        }
        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;
            img {
                width: 50px;
                height: auto;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .text-title {
                min-height: 40px;
                color: #4effff;
                font-size: 1.4em;
                line-height: 120%;
                font-family: 'HHBI';
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .text-content {
                height: calc(100% - 50px);
                color: #ffffff;
                font-size: 1.6em;
                line-height: 120%;
                font-family: 'HHBI';
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &.box-statistical-2 {
            max-width: unset;
            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .text-title {
                    margin-bottom: 0;
                }
                margin-bottom: 15px;
            }
            .pending {
                width: 100%;
                &-content {
                    height: 40px;
                    padding: 0 0.75rem;
                    vertical-align: middle;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    font-family: 'Helvetica';
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 5px;
                    &:nth-of-type(even) {
                        box-shadow: inset 0px 0px 2005px rgba(22, 22, 22, 0.5);
                    }
                    &:nth-of-type(odd) {
                        box-shadow: inset 0px 0px 2005px rgba(18, 37, 37, 0.2);
                    }
                    &-left {
                        flex: 0 0 calc(100% - 300px);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    &-right {
                        flex: 0 0 250px;
                        border-left: 1px solid #00ffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #02ffff;
                        @media (min-width: 992px) {
                            justify-content: flex-start;
                            text-align: left;
                            padding-left: 5%;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                    @media (max-width: 575px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                }
            }
        }
        &.box-statistical-3 {
            max-width: 500px;
            flex-direction: column;
            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .text-title {
                    margin-bottom: 0;
                }
                margin-bottom: 15px;
            }
        }
    }
    .agency-ib {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .left-icon {
            flex: 0 0 40%;
            max-height: 100%;
            padding: 0 5px;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                max-width: 160px;
                height: auto;
                max-height: 100%;
                object-fit: scale-down;
            }
        }
        .right-info {
            flex: 0 0 60%;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .name-agency,
            .ib-agency {
                font-size: 16px;
                span {
                    color: #00ffff;
                    font-family: 'HHBI';
                    font-size: 17px;
                }
            }
        }
    }
    .body-level {
        width: 100%;
        min-height: calc(100% - 55px);

        &-title {
            font-size: 16px;
            color: #00ffff;
            font-family: 'HHBI';
        }
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .arrow-level {
                flex: 1 1 auto;
                position: relative;
                height: 25px;
                img {
                    width: 50px;
                    max-height: 25px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    animation: toLeftArrow 4s infinite ease-in;
                }
            }
        }
        &-avatar {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            border-radius: 50%;
            border: 1px solid #00ffff;
            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
        &-body {
            width: 100%;
        }
        &-progress {
            margin: 10px 0;
            &-body {
                height: 25px;
                max-width: 500px;
                position: relative;
                margin-top: 5px;
                &-bar {
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: auto;
                    bottom: 0;
                    z-index: 3;
                    overflow: hidden;
                    &-active {
                        background: linear-gradient(
                            90deg,
                            #00ffa96b 0%,
                            #00b1ff 100%
                        );
                        border-radius: 15px;
                        width: calc(40% - 4px);
                        height: calc(100% - 4px);
                        position: absolute;
                        top: 0;
                        left: 2px;
                        right: auto;
                        bottom: 0;
                        margin: auto 0;
                        z-index: 4;
                        max-width: 100%;
                    }
                    &-text {
                        color: #fff;
                        position: relative;
                        z-index: 5;
                        font-size: 15px;
                        text-align: center;
                        font-family: 'HHBI';
                    }
                }
            }
        }
    }
    .agency-button {
        &-content {
            position: relative;
            z-index: 10;
            font-family: 'HHBI';
            font-weight: 400;
            font-size: 15px;
            line-height: 26px;
            /* or 144% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.75px;
            justify-content: center;
            img {
                filter: invert(35%) sepia(131%) saturate(624%)
                    hue-rotate(147deg) brightness(100%) contrast(106%);
            }
        }
        border: 1px solid #00ffff;
        background: transparent;
        box-shadow: unset;
        position: relative;
        width: 170px;
        height: 40px;
        border-radius: 0;
        color: #00ffff;
        margin: auto;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 5px;
            height: 5px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            left: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            right: 1px;
            -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: #000 !important;

            &::before {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transition-timing-function: cubic-bezier(
                    0.52,
                    1.64,
                    0.37,
                    0.66
                );
                transition-timing-function: cubic-bezier(
                    0.52,
                    1.64,
                    0.37,
                    0.66
                );
            }
        }
    }
    @keyframes toLeftArrow {
        0% {
            left: 0;
            opacity: 1;
        }
        100% {
            left: calc(100% - 50px);
            opacity: 0.2;
        }
    }
}
