





















































































































































































































































































































































.support-tab {
    .label-form {
        background: transparent !important;
    }
    p {
        color: #dcdcdc;
    }
    .filter-history {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px;
        position: relative;
        select {
            height: 40px;
            background: transparent;
            color: #dcdcdc;
            border: 0.5px solid #dcdcdc;
            border-radius: 5px;
            position: relative;
            z-index: 5;
            option {
                background: #00000b;
            }
        }
        img {
            height: 8px;
            width: auto;
            position: absolute;
            right: 15px;
            bottom: 0;
            top: 0;
            margin: auto;
        }
    }
    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(105% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
    .table-responsive {
        .customer-header {
            background-color: transparent;
            color: #f0f0f0;
        }
        .table th,
        .table td {
            // border: 1px solid #00ffff;
            border: none;
        }
        table {
            thead {
                tr {
                    th {
                        // border-right-color: #000 !important;
                        position: relative;
                        padding: 1rem 0;
                        &:before {
                            content: '';
                            position: absolute;
                            height: 30%;
                            width: 2px;
                            background-color: #00ffff;
                            border-radius: 1000px;
                            left: 100%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                        &:last-child {
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
            tbody {
                border-top: 1px solid #00ffff;
                tr {
                    td {
                        height: 40px;
                        padding: 1.75rem 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;
                    }
                    &:nth-of-type(even) {
                        background: rgba(0, 255, 255, 0.15);
                        td {
                        }
                    }
                }
            }
        }
    }
    .create-form,
    .detail-form {
        border: 0.5px solid #888888;
        border-radius: 4px;

        .create-form-title {
            display: flex;
            align-items: center;
            text-align: center;
            color: #f0f0f0;
            font-weight: bold;
            padding: 10px 0;
            .imgWrapper {
                padding: 2px 15px;
                border-right: 1px solid #00ffff;
                flex: 0;
                margin-right: 15px;
            }
        }
        .create-form-content {
            font-family: 'helvetica';
            textarea {
                height: 100px;
                overflow: auto;
                background: transparent;
                color: #dcdcdc;
                border: 0.5px solid #dcdcdc;
                border-radius: 5px;
            }
            .phuongBotan {
                margin: 0px auto;
                margin-top: 20px;
                display: block;
            }
        }
    }

    .btn-detail-of-form {
        background: #00ffff;
        border-radius: 0;
        border: none;

        color: #000;
        font-weight: bold;
    }

    .detail-form {
        color: white;
        min-width: 300px;
        padding: 0px 15px;
        .detail-form-title {
            display: flex;

            padding-top: 30px;
            padding-bottom: 40px;
            border-bottom: 1px solid #dcdcdc;
            .imgWrapper {
                padding-right: 15px;
                border-right: 1px solid #00ffff;
                flex: 0;
                margin-right: 15px;
            }
        }
        .ticketID {
            position: absolute;
            top: 110%;
            left: 0;
            width: max-content;
            font-size: 13px;
            color: #00ffff;
            text-align: center;
        }
        .detail-form-content {
            padding: 10px 0px;
            max-height: 300px;
            overflow: auto;
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                // background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                width: 10px;
                background-color: #f5f5f53d;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 1px;
                background-color: rgb(104, 100, 100);
            }
            .detail-form-content-item {
                padding-top: 5px;
                padding-bottom: 10px;
                border-radius: 5px;
                border: 1px solid #00ffff;
                margin-top: 15px;
                font-size: 14px;
                text-align: justify;
                &.admin {
                    border-color: red;
                }
                .text-tidy {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #aaa;
                }
            }
        }

        .detail-form-input {
            position: relative;
            margin-top: 40px;
            margin-bottom: 20px;
            textarea {
                height: 100px;
                overflow: auto;
                background: transparent;
                color: #dcdcdc;
                border: 0.5px solid #00ffff;
                border-radius: 4px;
                resize: none;
            }
            .botan {
                position: absolute;
                border: none;
                outline: none;
                right: 30px;
                bottom: 30px;
                transform: translate(-50%, 50%);
                border-radius: 50%;
                width: 33px;
                aspect-ratio: 1;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #1a1f31;
            }
        }
    }
    .phuongBotan {
        align-items: center;
        text-align: center;
        letter-spacing: 0.75px;
        background: none;
        // font-family: 'Hemi Head';
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        border: 1px solid #00ffff;
        height: auto;
        width: 100%;
        position: relative;
        transition: 0.5s;
        padding: 10px 0px;
        width: 100%;
        max-width: 170px;
        color: #00ffff;
        font-size: 16px;
        outline: none;
        &:hover,
        &:focus {
            color: #333;
            background: rgba(0, 255, 255, 1);
            &.cancel {
                color: #333;
                background: rgba(220, 220, 220, 1);
            }
        }
        .top-left-square-point,
        .bottom-right-square-point {
            position: absolute;
            width: 4px;
            height: 4px;
            background: #00ffff;
        }
        .top-left-square-point {
            top: 0;
            left: 0;
        }
        .bottom-right-square-point {
            bottom: 0;
            right: 0;
        }
        &.cancel {
            border-color: rgba(220, 220, 220, 1);
            color: rgba(220, 220, 220, 1);
            .top-left-square-point,
            .bottom-right-square-point {
                background: rgba(220, 220, 220, 1);
            }
        }
    }
}
