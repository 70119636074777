@keyframes loop {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

// reset css

button {
    background: none;
    border: none;
    outline: none;
    box-shadow: unset;
}

.bede-btn {
    // max-width: 221px;
    width: 100%;
    // height: 50px;
    border-radius: 0;
    border: none;
    outline: unset;
    position: relative;
    cursor: pointer;
    background: linear-gradient(to right, #18d4e2, #a506f9);

    &:hover {
        background-size: 200%;
        animation: loop 1s infinite linear;
    }

    &::before {
        content: '';
        position: absolute;
        inset: 1px;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            #353f48;
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(14px, 1.5vw, 20px);
        line-height: 150%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #00ffff;
        z-index: 10;
    }
}

.read-form {
    background: rgba(0, 255, 255, 0.2) !important;
}

.form-control {
    background: rgba(0, 255, 255, 0.2) !important;
}

// global css in inventory
.inventory-box-search {
    padding: 20px 50px;

    @media (max-width: 991px) {
        padding: 0px 40px 10px;
    }

    @media (max-width: 480px) {
        padding: 0px 30px 10px;
    }

    background-image: url('~@/assets/images/background/clone-bg2.png');
    backdrop-filter: blur(2px);
    background-size: cover;
    background-repeat: no-repeat;

    button {
        outline: unset !important;
    }

    label {
        width: 100%;
        padding: 18px 20px;
        border-bottom: 0.5px solid #dcdcdc;
        margin: 0;

        input {
            cursor: pointer;
            background: none;
            border: none;
            box-shadow: none;
            outline: none;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #dcdcdc;
        }

        select {
            cursor: pointer;
            width: 100%;
            background: none;
            border: none;
            outline: none;
            box-shadow: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #dcdcdc;

            option {
                margin-top: 20px;
                color: #333;
            }
        }
    }

    .bede-btn {
        max-width: 190px;
        height: 50px;

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #00ffff;
        }
    }
}

.z-1000 {
    z-index: 1000 !important;
}

.position-fixed {
    position: fixed !important;
}

.empty-item-inventory {
    p {
        text-align: center;
        margin-top: 50px;
        font-family: 'HHBI';
        text-align: center;
        text-transform: uppercase;
        color: #4effff;
        position: relative;
        font-size: 32px;
    }
}

.inventory-box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 35px auto;
    width: 100%;
    max-width: 330px;

    hr {
        background: #00ffff;
        margin: 22px 0;
    }

    .inventory-item-image {
        position: absolute;
        top: 0;
        max-width: 165px;
        z-index: 20;

        // z-index: 5;
        img {
            height: 123px;
            width: auto;
        }
    }

    .inventory-box-item-content {
        padding: 41px 37px;
        background-image: url('~@/assets/images/background/inventory-item-bg.png');
        width: 100%;
        background-size: cover;
        margin-top: 80px;
        padding-top: 130px;
        position: relative;

        // z-index: 1;
        .inventory-item-line {
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .box-item-rate {
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #00ffff;
        }

        .box-item-name {
            font-weight: 600;
            font-size: 24px;
            line-height: 20px;
            color: #f8f8f8;
            padding-left: 17.03px;
        }

        .inventory-image-modal {
            width: auto;
            height: 27px;
        }

        .inventory-content-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            color: #f8f8f8;
        }
    }
}

option {
    outline: unset;
}

// global css main color
.main-text-color {
    color: #00ffff;
}

.bg-input {
    background: rgba(0, 255, 255, 0.15) !important;
    backdrop-filter: blur(2px) !important;
}

.active-children {
    color: #00ffff !important;
}

.main-bg {
    background: rgba(0, 255, 255, 0.2) !important;
}
