













































































































.land-item {
    font-family: 'Poppins';
    flex: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
        max-width: 100%;
    }
    .inventory-box-item {
        .inventory-item-image {
            width: 150px;
            height: 150px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 1px solid #00ffff;
            }
        }
        .land-name-content {
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            padding-left: 25px;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .land-item-information {
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f8f8f8;
                padding: 8px 1rem;
            }
            .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #00ffff;
            }
        }
    }
}
