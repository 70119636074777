






















































































































.btn-confirm-p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    text-decoration: none;
    font-size: 19px;
    font-family: 'HHBI';
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    background: #00fff2;
    width: 100%;
    max-width: 120px;
    height: 40px;
    box-shadow: 0px 0px 10px 5px rgba($color: #000000, $alpha: 0.5);
}
.box-promotion {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    min-height: 160px;
    flex-wrap: wrap;
    position: relative;
    border: 1px solid #00ffff;
    &:after,
    &::before {
        z-index: 0;
        content: '';
        width: 6px;
        height: 6px;
        background: #00ffff;
    }

    &:after {
        position: absolute;
        top: 1px;
        right: 1px;
    }
    &::before {
        position: absolute;
        bottom: 1px;
        left: 1px;
    }
    .avatar {
        width: 90px;
        height: 90px;
        flex: 0 0 90px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        overflow: hidden;
        img {
            object-fit: scale-down;
            height: 100%;
            width: auto;
        }
    }
    .content-promotion {
        flex: 1 1 calc(100% - 90px);
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 140px;
        .title-promotion {
            font-family: 'HHBI';
            font-size: 19px;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .des-promotion {
            font-size: 16;
            color: #fff;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
            &.join {
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
        .date-promotion {
            font-size: 14px;
            color: #fff;
            margin-bottom: 10px;
            overflow: hidden;
        }
        .action-promotion {
            // a {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     color: #000000;
            //     text-decoration: none;
            //     font-size: 19px;
            //     font-family: 'HHBI';
            //     font-weight: bold;
            //     text-transform: uppercase;
            //     border-radius: 5px;
            //     background: #00fff2;
            //     width: 100%;
            //     max-width: 120px;
            //     height: 30px;
            //     box-shadow: 0px 0px 10px 5px rgba($color: #000000, $alpha: 0.5);
            // }
            .sell-item-button {
                width: 100px;
                border-radius: 15px;
                height: 30px;
                .sell-item-button-content {
                    border-radius: 15px;
                    font-size: 14px;
                }
            }
        }
    }
    .list-hero-locked {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        img {
            width: 35px;
            height: 35px;
            margin: 5px;
            border-radius: 50%;
            border: 1px solid #00fff2;
            cursor: pointer;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
